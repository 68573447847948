import styled from 'styled-components'
import {
  space,
  color,
  layout,
  typography,
  border,
  flexbox,
  position,
} from 'styled-system'

export const TextContainer = styled.div`
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 40vh;
  max-width: 998px;
  padding: 20px;
  z-index: 1;
  font-family: sans-serif;
  position: relative;
`

export const FadeInText = styled.div`
  z-index: 1;
  transition: opacity .25s ease-out;
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  ${position}

  .blade {
    ${({ bladePaddingTop }) =>
      bladePaddingTop && `padding-top: ${bladePaddingTop};`}
    ${({ bladePaddingBottom }) =>
      bladePaddingBottom && `padding-bottom: ${bladePaddingBottom};`}
  }
`

export const FadeOutText = styled.div`
  z-index: 1;
  transition: opacity 250ms ease-out, transform 600ms ease-out, visibility 250ms ease-out;
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  ${position}

`

export const H3 = styled.h3`
  ${color}
  ${layout}
  ${typography}
  ${space}
  position: relative;
  z-index: 1;
`

export const StyledVideo = styled.video`
  width: 100%;
  transition: opacity .4s ease-out;
  ${color}
  ${layout}
  ${typography}
  ${space}
  ${position}
`

export const Container = styled.div`
  ${color}
  ${layout}
  ${typography}
  ${space}
  ${position}
`
