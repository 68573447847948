import React from 'react'
import PropTypes from 'prop-types'
import Video from '@common/components/Video'

export const VideoGatsbyCDAContainer = ({
  theme,
  cloudinaryVideoSrc,
  thumbnail,
  title,
  captions,
  loop,
  portrait,
  aspectRatio
}) => {
  const src = cloudinaryVideoSrc?.[0]?.original_secure_url
  const videoThumbnail = thumbnail?.fluid?.src

  return (
    <Video
      theme={theme}
      src={src}
      thumbnail={videoThumbnail}
      captions={captions?.file?.url}
      title={title}
      loop={loop}
      aspectRatio={aspectRatio}
      portrait={portrait}
    />
  )
}

VideoGatsbyCDAContainer.propTypes = {
  cloudinaryVideoSrc: PropTypes.arrayOf(PropTypes.object),
  thumbnail: PropTypes.shape({
    id: PropTypes.string,
    fluid: PropTypes.object,
    file: PropTypes.object,
  }),
  title: PropTypes.string,
  loop: PropTypes.bool,
}

export default VideoGatsbyCDAContainer
