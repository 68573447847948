import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer } from './styles'
import BackgroundWrapper from './components/BackgroundWrapper'
import { useMobile, useLocale } from '@common/hooks'

const BladeDefault = ({
  id,
  children,
  paddingTop,
  paddingBottom,
  fullWidth,
  hideOnLocale,
  ...rest
}) => {
  const [isMobile] = useMobile()
  const minPadding = isMobile ? 50 : 150
  const { locale } = useLocale()
  if (hideOnLocale?.includes(locale)) return null
  return (
    <BackgroundWrapper
      fullWidth={fullWidth}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...rest}
    >
      <StyledContainer
        id={id}
        className="blade"
        tabIndex="-1"
        display="flex"
        flexDirection="column"
        alignItems="center"
        margin="0px auto"
        pt={!fullWidth && `calc(${paddingTop || 0}% + ${minPadding}px)`}
        pb={!fullWidth && `calc(${paddingBottom || 0}% + ${minPadding}px)`}
        width="100%"
        px={!fullWidth && 3}
        maxWidth={
          fullWidth && isMobile
            ? '100%'
            : [
                'maxWidth.xsmall',
                'maxWidth.small',
                'maxWidth.medium',
                'maxWidth.large',
                'maxWidth.xlarge',
                'maxWidth.xxlarge',
              ]
        }
      >
        {children}
      </StyledContainer>
    </BackgroundWrapper>
  )
}

BladeDefault.propTypes = {
  id: PropTypes.string,
  height: PropTypes.number,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.object,
  backgroundGradient: PropTypes.string,
}

export default BladeDefault
