import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledOpaqueBGContainer,
  StyledOpaqueImageContainer,
  StyledTextContainer,
  StyledContentContainer,
  StyledContainer,
} from '../styles'
import Image from '../../../Image'
import { TextBlock } from '../../../TextBlock'
import OpaqueHero from './OpaqueHero'
import { useMobile } from '@common/hooks'

const renderBasedOnType = ({
  __typename,
  id,
  title,
  opaqueBackgroundColor,
  ...rest
}) => {
  switch (__typename) {
    case 'ContentfulTextBlock':
      return (
        <StyledTextContainer key={id} display="flex" alignItems="center" p={2}>
          <TextBlock id={id} title={title} {...rest} />
        </StyledTextContainer>
      )
    case 'ContentfulImage':
      return (
        <StyledOpaqueImageContainer width="100%" px={5} py={3} key={id}>
          <Image
            imgStyle={{ objectFit: 'scale-down' }}
            style={{ objectFit: 'scale-down' }}
            alt={title}
            {...rest}
            width="100%"
            height="100%"
          />
        </StyledOpaqueImageContainer>
      )
  }
}

const ColumnOpaque = ({
  heroImage,
  heroImageLayout,
  contents,
  verticalAlignment = 'center',
  opaqueBackgroundColor = 'white',
  margins = '0px',
  minHeight = '0vh',
}) => {
  const [isMobile] = useMobile()
  const opaqueTextColor = opaqueBackgroundColor === 'white' ? 'black' : 'white'

  return (
    <StyledOpaqueBGContainer
      display="flex"
      position="relative"
      flexDirection="column"
      justifyContent={verticalAlignment}
      alignItems="center"
      height="100%"
      minHeight={minHeight}
      bg={
        opaqueBackgroundColor === 'white'
          ? 'bg.secondary'
          : opaqueBackgroundColor === 'grey'
          ? 'bg.tertiary'
          : 'bg.primary'
      }
      px={!isMobile && '48px'}
    >
      <StyledContainer width="100%">
        {heroImage && (
          <OpaqueHero heroImage={heroImage} layout={heroImageLayout} />
        )}
        <StyledContentContainer
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
          p={margins}
          mt={heroImageLayout === 'overlap' && '100px'}
        >
          {contents?.map(content =>
            renderBasedOnType({
              opaqueTextColor,
              opaqueBackgroundColor,
              ...content,
            })
          )}
        </StyledContentContainer>
      </StyledContainer>
    </StyledOpaqueBGContainer>
  )
}

ColumnOpaque.propTypes = {
  heroImageLayout: PropTypes.string,
  verticalAlignment: PropTypes.string,
  heroImage: PropTypes.shape({
    id: PropTypes.string,
    scale: PropTypes.string,
    source: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
      fluid: PropTypes.object,
      localFile: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.object,
        }),
      }),
    }),
  }),
  content: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.shape({
        body: PropTypes.string,
      }),
      id: PropTypes.string,
      themes: PropTypes.string,
      title: PropTypes.string,
    })
  ),
}

export default ColumnOpaque
