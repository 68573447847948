import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
} from '@common/hooks/usePreviewQuery'
import { useLocale } from '@common/hooks'
import { CarouselXbox as Carousel } from '@common/components/CarouselXbox'
import { getLinkedEntry } from '@common/utils'

const CarouselApolloCPAContainer = ({ id }) => {
  const { locale } = useLocale()
  const {
    loading: loadingGalleryItems,
    error: errorGalleryItems,
    data: dataGalleryItems,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'gallerySet',
    locale,
  })

  const [galleryItems, setGalleryItems] = useState([])

  const getMappedAsset = useCallback((asset, includes) => {
    let mappedAsset = getLinkedEntry(includes.Asset, asset?.sys?.id)
    mappedAsset = { id: mappedAsset?.sys?.id, ...mappedAsset?.fields }

    return mappedAsset
  }, [])

  const getMappedGalleryAsset = useCallback(
    (galleryAsset, includes) => {
      let mappedGalleryAsset = getLinkedEntry(
        includes.Entry,
        galleryAsset?.sys?.id
      )

      mappedGalleryAsset = {
        id: mappedGalleryAsset?.sys?.id,
        ...mappedGalleryAsset?.fields,
        source: getMappedAsset(mappedGalleryAsset?.fields?.source, includes),
      }
      return mappedGalleryAsset
    },
    [getMappedAsset]
  )

  const getMappedGalleryItems = useCallback(
    (galleryItems, includes) => {
      let mappedGalleryItems = getLinkedEntry(
        includes.Entry,
        galleryItems?.sys?.id
      )

      mappedGalleryItems = {
        id: mappedGalleryItems?.sys?.id,
        ...mappedGalleryItems?.fields,
        galleryAsset: getMappedGalleryAsset(
          mappedGalleryItems?.fields?.galleryAsset,
          includes
        ),
      }
      return mappedGalleryItems
    },
    [getMappedGalleryAsset]
  )

  const getMappedGallery = useCallback(
    (gallery, includes) => {
      let mappedGallery = getLinkedEntry(includes.Entry, gallery?.sys?.id)
      mappedGallery = {
        id: mappedGallery?.sys?.id,
        ...mappedGallery?.fields,
        galleryItems: mappedGallery?.fields?.galleryItems?.map(galleryItem =>
          getMappedGalleryItems(galleryItem, includes)
        ),
      }

      return mappedGallery
    },
    [getMappedGalleryItems]
  )

  useEffect(() => {
    if (!loadingGalleryItems && !errorGalleryItems && dataGalleryItems) {
      const {
        entries: { includes, items },
      } = dataGalleryItems

      let filteredGalleryItems = getLinkedEntry(items, id)
      filteredGalleryItems = {
        id: filteredGalleryItems?.sys?.id,
        ...filteredGalleryItems?.fields,
        galleries: filteredGalleryItems?.fields?.galleries?.map(gallery =>
          getMappedGallery(gallery, includes)
        ),
      }
      setGalleryItems(filteredGalleryItems)
    }
  }, [
    dataGalleryItems,
    errorGalleryItems,
    getMappedGallery,
    id,
    loadingGalleryItems,
  ])

  return <Carousel gallerySet={galleryItems?.galleries}></Carousel>
}

CarouselApolloCPAContainer.propTypes = {
  id: PropTypes.string,
}

export default CarouselApolloCPAContainer
