import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
} from 'styled-system'

export const Container = styled.div`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const TitleContainer = styled(Container)`
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
`

export const StyledIcon = styled.img`
  transition: all 0.25s ease;
  ${space}
  ${color}
  ${position}
  ${layout}
  ${({ rotate }) => rotate && `transform: rotate(${rotate});`}
`

export const Cell = styled.div`
${space}
${position}
${color}
${typography}
${border}
${layout}
${flexbox}
`

export const Eyebrow = styled.p`
${space}
${color}
${typography}
${border}
${layout}
`

export const H3 = styled.h3`
${space}
${color}
${typography}
${border}
${layout}
`

export const H4 = styled.h4`
${space}
${color}
${typography}
${border}
${layout}
`
