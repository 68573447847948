import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  StyledNavContainer,
  StyledList,
  StyledListItem,
  StyledSkipToContent,
} from '../styles'
import NavigationLogo from '../components/NavigationLogo'
import NavigationItem from '../components/NavigationItem'
import NavbarWrapper from '../components/NavbarWrapper'
import SubNavigationItem from '../components/SubNavigationItem'
import { BladeIntersectionContext } from '../../../context'
import { Button } from '../../ButtonAccessible'

const NavigationDesktopSimple = ({
  navItems,
  logo,
  alt,
  homeSlug,
  backgroundColor,
}) => {
  const [activeSubnav, setActiveSubnav] = useState()
  const { currentIntersection } = useContext(BladeIntersectionContext) || {}

  return (
    <StyledNavContainer
      position="fixed"
      top="0"
      height="fit-content"
      zIndex="1000"
      width="100%"
    >
      <NavbarWrapper backgroundColor={backgroundColor}>
        <StyledSkipToContent display="flex" position="absolute">
          <Button tabIndex="-1" link={{ anchorTagId: 'main' }}>
            Skip to Content
          </Button>
        </StyledSkipToContent>
        <NavigationLogo logo={logo} alt={alt} homeSlug={homeSlug} />
        {navItems && (
          <StyledList
            display="flex"
            role="menubar"
            aria-label="Main Navigation"
          >
            {navItems?.map(({ id, navigationLink, ...rest }) => (
              <StyledListItem key={id} role="menuitem">
                <NavigationItem
                  {...rest}
                  setActiveSubnav={setActiveSubnav}
                  activeAnchor={currentIntersection?.target}
                  navigationLink={navigationLink}
                />
              </StyledListItem>
            ))}
          </StyledList>
        )}
      </NavbarWrapper>

      {activeSubnav?.subnav?.length && (
        <NavbarWrapper backgroundColor={backgroundColor} opacity=".9">
          <StyledList
            display="flex"
            role="menu"
            ml={3}
            id="submenu"
            aria-label={`Subnavigation for ${activeSubnav?.parent?.displayText}`}
          >
            {activeSubnav?.subnav?.map(({ id, ...rest }) => (
              <StyledListItem key={id} role="menuitem">
                <SubNavigationItem
                  {...rest}
                  activeAnchor={currentIntersection?.target}
                />
              </StyledListItem>
            ))}
          </StyledList>
        </NavbarWrapper>
      )}
    </StyledNavContainer>
  )
}

NavigationDesktopSimple.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.object,
  alt: PropTypes.string,
  homeSlug: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default NavigationDesktopSimple
