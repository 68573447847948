import React from 'react'
import { StyledField, Container } from '../style'

const InputField = ({
  placeholder,
  values,
  handleChange,
  handleBlur,
  id,
  inputType,
  touched,
  errors,
}) => {
  return (
    <Container width={[1]}>
      <label htmlFor={id}>
        <StyledField
          aria-label={inputType}
          type={inputType}
          name={id}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[id]}
          width={[1]}
          fontSize={[8]}
          aria-invalid={errors[id] && touched[id] ? true : null}
          aria-describedby={errors[id] && touched[id] ? `${id}-error` : null}
        />
      </label>
    </Container>
  )
}

export default InputField
