import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { StatsBlock } from '@common/components/StatsBlock'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
} from '@common/hooks/usePreviewQuery'
import { useLocale } from '@common/hooks'
import { getLinkedEntry } from '@common/utils'

const StatsBlockApolloCPAContainer = ({ id }) => {
  const { locale } = useLocale()
  const {
    loading: loadingStatsItems,
    error: errorStatsItems,
    data: dataStatsItems,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'stats',
    locale,
  })

  const [statsItems, setStatsItems] = useState([])

  const getMappedStats = useCallback((includes, id) => {
    let mappedStats = getLinkedEntry(includes.Entry, id)
    mappedStats = {
      id: mappedStats?.sys?.id,
      ...mappedStats?.fields,
      //  to create same shape as page query
      description: { description: mappedStats?.fields?.description },
    }
    return mappedStats
  }, [])

  useEffect(() => {
    if (!loadingStatsItems && !errorStatsItems && dataStatsItems) {
      const {
        entries: { includes, items },
      } = dataStatsItems

      let filteredStatsItems = getLinkedEntry(items, id)
      filteredStatsItems = {
        id: filteredStatsItems?.sys?.id,
        ...filteredStatsItems?.fields,
        stats: filteredStatsItems?.fields?.stats?.map(stat =>
          getMappedStats(includes, stat?.sys?.id)
        ),
      }

      setStatsItems(filteredStatsItems)
    }
  }, [dataStatsItems, errorStatsItems, getMappedStats, id, loadingStatsItems])

  return <StatsBlock stats={statsItems?.stats} />
}

StatsBlockApolloCPAContainer.propTypes = {
  id: PropTypes.string,
}

export default StatsBlockApolloCPAContainer
