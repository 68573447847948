import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TableRow from './TableRow'
import { Container, StyledIcon, TitleContainer, H4 } from './style'
import dropdownArrow from '../../assets/dropdown-arrow-lg.svg'

const Table = ({ tableEntries, title }) => {
  const tableKeys = tableEntries && Object.keys(tableEntries)
  const [isOpen, setOpen] = useState(true)
  const handleClick = () => {
    setOpen(!isOpen)
  }

  return (
    <Container display="flex" flexWrap="wrap" fontFamily="headings">
      <TitleContainer
        width={[1]}
        display="flex"
        justifyContent="space-between"
        borderY="1px solid #707070"
        onClick={handleClick}
        aria-expanded={isOpen}
        cursor="pointer"
      >
        <H4 fontSize={[6]}>{title}</H4>
        <StyledIcon
          aria-hidden="true"
          src={dropdownArrow}
          rotate={isOpen ? '180deg' : '0deg'}
        />
      </TitleContainer>
      {tableKeys?.map((t, i) => (
        <TableRow
          key={`${t} + ${i}`}
          isOpen={isOpen}
          tableData={tableEntries?.[t]}
          title={t}
        />
      ))}
    </Container>
  )
}

Table.propTypes = {
  title: PropTypes.string,
  tableEntries: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
}

export default Table
