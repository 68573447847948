import React from 'react'
import { StyledButton } from '../style'
import { A } from '../../Button'

const FormButton = ({ displayText, isSubmitting, slug, type }) => {
  return type === 'Submission' ? (
    <StyledButton
      type="submit"
      bg={`black`}
      width="fit-content"
      color={`white`}
      fontSize="12px"
      fontFamily="body"
      borderRadius="4px"
      py="4px"
      ml="10px"
      role="button"
    >
      {isSubmitting ? 'Loading...' : displayText}
    </StyledButton>
  ) : (
    <A color="black" fontSize="12px" ml="5px" to={slug} role="link">
      {displayText}
    </A>
  )
}

export default FormButton
