import React from 'react'
import {
  FooterGatsbyCDAContainer,
  FooterApolloCPAContainer,
} from '../../containers/FooterContainer'

const Footer = ({ preview, ...rest }) => {
  const FooterContainer = preview
    ? FooterApolloCPAContainer
    : FooterGatsbyCDAContainer

  return <FooterContainer type="simple" {...rest} />
}

export default Footer
