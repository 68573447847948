import React from 'react'
import ProgressStepper from '@common/components/ProgressStepper'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocale } from '@common/hooks'

const ProgressStepperGatsbyCDAContainer = props => {
  const { stepItems } = useStaticQuery(graphql`
    query StepperQuery {
      stepItems: allContentfulNavigation(
        filter: { placement: { eq: "Global Header" } }
      ) {
        nodes {
          ...navigationFields
        }
      }
    }
  `)

  const { getLocalizedFields } = useLocale()
  const localizedStepItems = getLocalizedFields(stepItems?.nodes)

  const stepperItems = localizedStepItems?.navigationItems
  const homeSlug = '/'

  return <ProgressStepper steps={stepperItems} homeSlug={homeSlug} {...props} />
}

export default ProgressStepperGatsbyCDAContainer
