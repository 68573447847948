import React from 'react'
import PropTypes from 'prop-types'
import { StyledWidthContainer, StyledVideoContainer } from './styles'

import Video from '../../Video'

import ColumnTransparent from './components/ColumnTransparent'
import ColumnOpaque from './components/ColumnOpaque'
import ColumnNumbered from './components/ColumnNumbered'
import ColumnHybrid from './components/ColumnHybrid'
import { useMobile } from '@common/hooks'

const renderBasedOnLayout = ({
  mainContent,
  heroImage,
  layout,
  verticalAlignment,
  ...rest
}) => {
  switch (layout) {
    case 'Transparent Background':
      return (
        <ColumnTransparent
          heroImage={heroImage}
          contents={mainContent}
          verticalAlignment={verticalAlignment}
          {...rest}
        />
      )
    case 'Opaque Background':
      return (
        <ColumnOpaque
          heroImage={heroImage}
          contents={mainContent}
          verticalAlignment={verticalAlignment}
          margins="20px"
          minHeight="20vh"
          {...rest}
        />
      )
    case 'Hybrid Background':
      return (
        <ColumnHybrid
          heroImage={heroImage}
          contents={mainContent}
          verticalAlignment={verticalAlignment}
          margins="20px"
          minHeight="20vh"
          {...rest}
        />
      )
    case 'Numbered List':
      return <ColumnNumbered contents={mainContent} {...rest} />
    case 'Divider':
      return (
        <ColumnOpaque heroImage={heroImage} contents={mainContent} {...rest} />
      )
    case 'Video':
      return (
        <StyledVideoContainer
          display="flex"
          width="100%"
          height="100%"
          alignItems={verticalAlignment}
        >
          <Video {...mainContent?.[0]} />
        </StyledVideoContainer>
      )
  }
}

const Column = ({
  width = 100,
  heroImageLayout,
  gutter,
  mainContent,
  layout,
  ...rest
}) => {
  const [isMobile] = useMobile()
  return (
    <StyledWidthContainer
      isMobile={isMobile}
      mx={!isMobile && gutter}
      width={isMobile ? '100%' : `${width}%`}
      mt={heroImageLayout === 'overlap' && '100px'}
    >
      {renderBasedOnLayout({ heroImageLayout, mainContent, layout, ...rest })}
    </StyledWidthContainer>
  )
}

Column.propTypes = {
  layout: PropTypes.string,
  width: PropTypes.number,
}

export default Column
