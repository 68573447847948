import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
} from 'styled-system'

export const StyledContainer = styled.div`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  
  .line-container {
    display: inline;
    position: relative;
    height: 100px;
    width: 10px;
    .line {
      display: inline;
      border-left: 2px solid white;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
`

export const StyledDiv = styled.div`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`
export const StyledTitle = styled.h3`
  
  box-sizing: border-box;
  margin: 0;
  padding: 0;
 
  .break-word {
    display: inline;
    word-break: break-all;
  } 

  .hyphenate {
    display: inline;
    hyphens: auto;
    -moz-hyphens: auto;
  } 
  .optional{
    display:inline;
    color : #ffffff80;
  }

  
  
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  
`

export const StyledP = styled.p`
  box-sizing: border-box;
  margin: 0;
  a {
    text-decoration: none;
    color: #6FF44A;
    font-weight: bold;

  }

  strong {
    color: white;
    font-weight: bold;
  }
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledCTAContainer = styled(StyledContainer)``

export const StyledTitleEyebrow = styled(StyledContainer)``
