import styled from 'styled-components'
import {
  layout,
  position,
  space,
  color,
  border,
  typography,
  flexbox,
} from 'styled-system'
import Img from 'gatsby-image'

export const Container = styled.div`
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 75%;

  z-index: 1000;
  
  &:focus {
    outline: 0;
  }
  
  > img {
    display: block;
    max-height: 60vh;
  }
`

export const StyledButton = styled.button`
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  cursor: pointer;
  white-space: nowrap;
  width: fit-content;
`
export const ModalCloseButton = styled(StyledButton)`
  position: absolute;
  z-index: 10;
  width: 35px;
  right: 0;
`

export const ModalContainer = styled.div`
  ${layout}
  ${position}
  cursor: pointer;
`

export const ModalOverlay = styled.div`
  ${layout}
  ${position}
  display: ${({ modalVisible }) => (modalVisible ? 'flex' : 'none')};
  background-color: rgba(0, 0, 0, 0.5);
`

export const ModalBodyContent = styled.div`
  ${layout}
  ${position}
  ${border}
  ${color}
  ${space}
  animation-duration: 1s;
`
export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0.5;
`

export const ModalDismissButton = styled.button`
  ${layout}
  ${space}
  ${color}
  ${border}
  ${position}
  ${typography}
  ${flexbox}
  cursor: pointer;
  color: #ffffff;
  width: 32px;
  height: 32px;
  text-shadow: 0 1px 0 #fff;
`

export const StyledPlayButton = styled.img`
  z-index: 3;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  opacity: 0.6;
  ${layout}
  ${position}
  ${color}
  ${space}
`

export const StyledImageContainer = styled.div`
  box-sizing: border-box;
  ${layout}
  ${space}
  ${color}
  ${typography}
  ${position}
`

export const StyledVideoThumbnailContainer = styled(StyledImageContainer)`
  cursor: pointer;

  &: hover ${StyledPlayButton} {
    opacity: .95;
  }
`

export const StyledImage = styled(Img)`
  box-sizing: border-box;
  ${layout}
  ${border}
  ${space}
  ${color}
  ${typography}
  ${position}
`
