import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Container, StyledImage, VideoContainer } from './style'
import { useVideoPlayer, useLocale, useMobile } from '../../hooks'
import { langObj } from '../../utils'
import './../../../../node_modules/video.js/dist/video-js.css'
import { useIntersectionContext } from '@common/hooks/useIntersection'
import BackgroundButton from './BackgroundButton'

export const Video = ({
  title,
  src,
  thumbnail,
  captions,
  theme,
  modal,
  loop,
  autoplay = false,
  renderImageAfterSinglePlay = false,
  children,
  portrait = false,
  aspectRatio = '16:9',
  gradient,
  minHeight,
  videoFit,
  showBackgroundBtn,
  alt,
  ...rest
}) => {
  const { currentIntersection } = useIntersectionContext()
  const { locale } = useLocale()
  const [isMobile] = useMobile()
  const imageRef = useRef()
  const bgBtnRef = useRef()
  const childRef = useRef()
  const [pauseOverride, setPauseOverride] = useState(false)
  if (loop) showBackgroundBtn = true

  const blade = childRef?.current?.querySelector('.blade')
  const childRefPaddingTop =
    blade && window.getComputedStyle(blade).getPropertyValue('padding-top')
  const bgBtnPaddingTop =
    childRefPaddingTop && `calc(${childRefPaddingTop} - 50px)`

  const { videoRef, player } = useVideoPlayer({
    src,
    thumbnail,
    modal,
    loop,
    autoplay,
    aspectRatio,
  })

  useEffect(() => {
    const shouldLoop =
      currentIntersection?.target?.querySelector('video') ===
        videoRef?.current ||
      currentIntersection?.target?.parentNode?.parentNode?.querySelector(
        'video'
      ) === videoRef?.current

    if (!pauseOverride && shouldLoop && player && (autoplay || loop)) {
      player
        .play()
        .then(() => {})
        .catch(() => {})
    } else if (player && (autoplay || loop)) {
      player.pause()
    }
  }, [currentIntersection, autoplay, player, videoRef, loop, pauseOverride])

  useEffect(() => {
    if (renderImageAfterSinglePlay) {
      videoRef.current.addEventListener(
        'ended',
        () => {
          if (videoRef.current) {
            videoRef.current.style.opacity = 0
          }
          if (imageRef.current) {
            imageRef.current.style.visibility = 'visible'
            imageRef.current.style.opacity = 1
            imageRef.current.style.zIndex = 0
          }
          if (bgBtnRef.current) {
            bgBtnRef.current.style.opacity = 0
            bgBtnRef.current.style.visibility = 'hidden'
          }
        },
        false
      )
    }
  }, [renderImageAfterSinglePlay, videoRef])

  return (
    <VideoContainer
      className="video-container"
      theme={`${theme}.bg.primary`}
      width={[1]}
      position="relative"
      height="100%"
      minHeight={`${minHeight}px`}
      videoFit={videoFit}
      gradient={gradient}
      {...rest}
    >
      <div data-vjs-player>
        <video
          ref={videoRef}
          className={
            portrait
              ? 'video-js vjs-big-play-centered portrait'
              : 'video-js vjs-big-play-centered landscape'
          }
          aspectRatio={aspectRatio}
          webkit-playsinline={src ? 1 : 0}
          playsInline={src ? 1 : 0}
          aria-label={title}
        >
          {captions && (
            <track
              src={`https:${captions}`}
              srcLang={locale}
              label={langObj[locale] || 'English'}
            />
          )}
        </video>
      </div>
      <Container
        ref={imageRef}
        width="100%"
        height="100%"
        position="absolute"
        top="0"
        opacity="0"
        zIndex="-10"
      >
        <StyledImage src={thumbnail} alt={alt} width="100%" height="100%" />
      </Container>
      {children && (
        <Container
          position="absolute"
          top="0"
          width="100%"
          height="100%"
          ref={childRef}
        >
          {children}
        </Container>
      )}
      {showBackgroundBtn && (
        <Container
          position="absolute"
          top={
            videoFit === 'Contain' && isMobile
              ? aspectRatio === '9:16'
                ? '25%'
                : bgBtnPaddingTop || '5%'
              : null
          }
          bottom={
            videoFit === 'Contain' && isMobile
              ? null
              : aspectRatio === '9:16'
              ? '25%'
              : '5%'
          }
          left="0px"
          right="0px"
          width="100%"
          px={3}
          margin="0 auto"
          maxWidth={[
            'maxWidth.xsmall',
            'maxWidth.small',
            'maxWidth.medium',
            'maxWidth.large',
            'maxWidth.xlarge',
            'maxWidth.xxlarge',
          ]}
          ref={bgBtnRef}
          display="flex"
          justifyContent={
            isMobile && videoFit === 'Contain' ? 'flex-start' : 'flex-end'
          }
        >
          <BackgroundButton
            player={player}
            setPauseOverride={setPauseOverride}
          />
        </Container>
      )}
    </VideoContainer>
  )
}

Video.propTypes = {
  src: PropTypes.string,
  thumbnail: PropTypes.string,
  captions: PropTypes.string,
  loop: PropTypes.bool,
}

export default Video
