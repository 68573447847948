import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
  variant,
  background,
} from 'styled-system'
import { A } from '../Button'

export const StyledContainer = styled.div`
  box-sizing: border-box;
  outline: none;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  ${background}
`

export const StyledSection = styled.section`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledSvg = styled.img`
  ${space}
`

export const StyledList = styled.ul`
  ${space}
  ${flexbox}
  ${layout}
`

export const StyledListItem = styled.li`
  ${space}
  ${flexbox}
`

export const StyledText = styled.p`
  padding: 0;
  margin: 0;
  ${space} 
  ${typography}
  ${color}
`

export const BaseButton = styled(A)(variant({ scale: 'buttons' }))

export const StyledNavLink = styled(BaseButton)`
  cursor: pointer;
  ${space}
  ${color}
  ${typography}
`
