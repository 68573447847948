import React from 'react'
import PropTypes from 'prop-types'
import { StatsBlock } from '@common/components/StatsBlock'

const StatsBlockGatsbyCDAContainer = ({ stats }) => {
  return <StatsBlock stats={stats} />
}

StatsBlockGatsbyCDAContainer.propTypes = {
  id: PropTypes.string,
}

export default StatsBlockGatsbyCDAContainer
