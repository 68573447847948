import React, { useState, useContext, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  StyledContainer,
  StyledNavContainer,
  StyledList,
  StyledListItem,
  StyledSkipToContent,
} from '../styles'
import NavigationLogo from '../components/NavigationLogo'
import NavigationItem from '../components/NavigationItem'
import NavbarWrapper from '../components/NavbarWrapper'
import SubNavigationItem from '../components/SubNavigationItem'
import { BladeIntersectionContext } from '../../../context'
import NavigationMenuBtn from '../components/NavigationMenuBtn'
import useMeasure from '@common/hooks/useMeasure'
import { Button } from '../../ButtonAccessible'

const NavigationMobileSimple = ({
  navItems,
  logo,
  alt,
  homeSlug,
  backgroundColor,
}) => {
  const { currentIntersection } = useContext(BladeIntersectionContext) || {}
  const { ref, height } = useMeasure()
  const ref2 = useRef()

  const [activeSubnav, setActiveSubnav] = useState()
  const [overlay, setOverlay] = useState(false)

  const handleLogoKeyPress = e => {
    if (e.shiftKey && (e.keyCode === 9 || e.key === 'Tab')) {
      const lastItem = [...ref2.current.querySelectorAll('a')].pop()
      if (lastItem) {
        e.preventDefault()
        lastItem.focus()
      }
    }
  }

  const handleMenuBtnKeyPress = e => {
    if (e.key === 'Enter') {
      setOverlay(overlay => !overlay)
    }

    if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
      setOverlay(false)
    }
  }

  const handleNavItemKeyPress = (e, lastItem) => {
    if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
      setOverlay(false)
    }

    if ((e.keyCode === 9 || e.key === 'Tab') && !e.shiftKey && lastItem) {
      const logo = ref.current.querySelector('#navLogo').querySelector('a')
      if (logo) {
        e.preventDefault()
        logo.focus()
      }
    }
  }

  useEffect(() => {
    if (overlay) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [overlay])

  return (
    <>
      <StyledNavContainer
        ref={ref}
        position="fixed"
        top="0"
        height="fit-content"
        zIndex="1000"
        width="100%"
      >
        <NavbarWrapper
          backgroundColor={overlay ? 'rgb(0,0,0,1)' : backgroundColor}
        >
          <StyledSkipToContent display="flex" position="absolute">
            <Button tabIndex="-1" link={{ anchorTagId: 'main' }}>
              Skip to Content
            </Button>
          </StyledSkipToContent>
          <NavigationLogo
            logo={logo}
            alt={alt}
            homeSlug={homeSlug}
            handleKeyPress={handleLogoKeyPress}
          />
          <NavigationMenuBtn
            toggled={overlay}
            handleClick={() => setOverlay(overlay => !overlay)}
            handleKeyPress={handleMenuBtnKeyPress}
          />
        </NavbarWrapper>
      </StyledNavContainer>

      <StyledNavContainer
        ref={ref2}
        position="fixed"
        top={height}
        height="100%"
        zIndex={overlay ? 1000 : -1000}
        width="100%"
        aria-hidden={overlay ? 'false' : 'true'}
      >
        <NavbarWrapper
          backgroundColor={overlay ? 'rgb(0,0,0,1)' : backgroundColor}
          height="100vh"
          opacity={overlay ? 1 : 0}
        >
          <StyledContainer
            overflowY="scroll"
            overflowX="hidden"
            width="100%"
            height="100vh"
          >
            {navItems && (
              <StyledList
                display="flex"
                flexDirection="column"
                role="menubar"
                aria-label="Main Navigation"
                mx={3}
                width="fit-content"
                height="100%"
                mb={7}
              >
                {navItems?.map(({ id, navigationLink, ...rest }, i, arr) => (
                  <StyledListItem key={id} role="menuitem" my={[3, 3, 0]}>
                    <NavigationItem
                      {...rest}
                      setActiveSubnav={setActiveSubnav}
                      activeAnchor={currentIntersection?.target}
                      navigationLink={navigationLink}
                      tabIndex={overlay ? '0' : '-1'}
                      handleKeyPress={e =>
                        handleNavItemKeyPress(e, i === arr.length - 1)
                      }
                    />
                    {activeSubnav?.parent?.slug === navigationLink?.slug &&
                      activeSubnav?.subnav && (
                        <StyledList
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          ml={3}
                          role="menu"
                        >
                          {activeSubnav?.subnav?.map(({ id, ...rest }) => (
                            <StyledListItem
                              key={id}
                              role="menuitem"
                              onClick={() => setOverlay(false)}
                            >
                              <SubNavigationItem
                                {...rest}
                                activeAnchor={currentIntersection?.target}
                                hideActiveBorder
                                tabIndex={overlay ? '0' : '-1'}
                                handleKeyPress={handleNavItemKeyPress}
                              />
                            </StyledListItem>
                          ))}
                        </StyledList>
                      )}
                  </StyledListItem>
                ))}
              </StyledList>
            )}
          </StyledContainer>
        </NavbarWrapper>
      </StyledNavContainer>
    </>
  )
}

NavigationMobileSimple.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.object,
  alt: PropTypes.string,
  homeSlug: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default NavigationMobileSimple
