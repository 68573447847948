import React from 'react'
import PropTypes from 'prop-types'
import { StyledSvg, StyledContainer } from '../styles'
import { A } from '../../Button'

const NavigationLogo = ({
  logo,
  homeSlug = '/',
  handleKeyPress = () => {},
}) => {
  const src = logo?.file?.url
  const alt = logo?.description || logo?.title
  return (
    <StyledContainer id="navLogo" p={3}>
      <A to={homeSlug} onKeyDown={handleKeyPress}>
        <StyledSvg src={`https:${src}`} alt={alt} />
      </A>
    </StyledContainer>
  )
}

NavigationLogo.propTypes = {
  logo: PropTypes.object,
  alt: PropTypes.string,
  homeSlug: PropTypes.string,
}

export default NavigationLogo
