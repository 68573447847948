import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { StyledContainer, StyledText, StyledNavLink } from '../styles'
import NProgress from '../../NProgress/NProgress'
import { useLocale } from '@common/hooks'

const NavigationItem = ({
  displayText,
  navigationLink: { url, slug },
  subnavigationItems,
  theme = 'dark',
  setActiveSubnav = () => {},
  tabIndex,
  handleKeyPress = () => {},
}) => {
  const { localeSlug, getLocalizedSlug } = useLocale()
  const localizedSlug = getLocalizedSlug(slug)

  const currentSlug = window?.location?.pathname.slice(1)
  const currentQuery = window?.location?.search
  const currentPath = `${currentSlug}${currentQuery}`

  const isActive =
    currentPath === localizedSlug ||
    (currentPath === localeSlug && slug === 'home')

  useEffect(() => {
    if (isActive) {
      setActiveSubnav({
        parent: { slug, displayText },
        subnav: subnavigationItems,
      })
    }
  }, [displayText, isActive, setActiveSubnav, slug, subnavigationItems])

  return (
    <StyledNavLink
      opacity={isActive ? 1 : 0.6}
      variant={`${theme}.primary`}
      to={localizedSlug}
      href={url}
      p={[1, 1, 3]}
      display="block"
      aria-current={isActive ? 'page' : null}
      tabIndex={tabIndex}
      onKeyDown={handleKeyPress}
    >
      <StyledContainer
        display="flex"
        width="fit-content"
        flexDirection="column"
        justifyContent={['flex-start', 'flex-start', 'center']}
        alignItems={['flex-start', 'flex-start', 'center']}
      >
        <StyledText
          fontFamily="nav"
          fontSize={['22px', '22px', '15px']}
          fontWeight={700}
          lineHeight="20px"
          py={[2, 2, 0]}
        >
          {displayText}
        </StyledText>
        {isActive && (
          <NProgress theme={theme} id={localizedSlug} scrollToCachedPosition />
        )}
      </StyledContainer>
    </StyledNavLink>
  )
}

NavigationItem.propTypes = {
  displayText: PropTypes.string,
  navigationLink: PropTypes.shape({
    url: PropTypes.string,
    slug: PropTypes.string,
  }),
  subnavigationItems: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.string,
  setActiveSubnav: PropTypes.func,
}

export default NavigationItem
