import React, { useState, useEffect, useContext } from 'react'
import usePreviewQuery, { GET_ENTRY } from '@common/hooks/usePreviewQuery'
import { PreviewAssetsContext } from '../../../../common/context'
import { TextBlock } from '@common/components/TextBlock'
import { useLocale } from '@common/hooks'

const TextBlockApolloCPAContainer = ({
  ctaLink,
  hideOnLocale,
  hideBodyOnLocale,
  ...rest
}) => {
  const { assets } = useContext(PreviewAssetsContext)
  const { locale } = useLocale()
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRY,
    id: ctaLink,
    skipBool: !ctaLink,
    locale,
  })
  const [link, setLink] = useState(null)
  hideBodyOnLocale = !hideBodyOnLocale ? [] : hideBodyOnLocale
  hideOnLocale = !hideOnLocale ? [] : hideOnLocale
  useEffect(() => {
    if (!loading && !error && data) {
      const { entry } = data
      const type = entry?.contentType === 'mediaVideo' && 'ContentfulMediaVideo'
      const caption = assets.find(
        a => a?.id === entry?.fields?.captions?.sys?.id
      )
      const cta = { ...entry.fields, captions: caption, type }
      setLink(cta)
    }
  }, [loading, data, error])

  return (
    <TextBlock
      ctaLink={link}
      hideBodyOnLocale={hideBodyOnLocale}
      hideOnLocale={hideOnLocale}
      {...rest}
    />
  )
}

export default TextBlockApolloCPAContainer
