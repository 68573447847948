import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer } from './styles'
import { Column } from './Column'
import { StyledColumnBlockContainer } from './Column/styles'
import { useMobile } from '@common/hooks'

const ColumnBlock = ({
  columns = [],
  horizontalAlignment = 'flex-start',
  gutter = '0px',
  bottomMargin = '32px',
  maxWidth = '70%',
  mobileLayout = 'column',
}) => {
  const [isMobile] = useMobile()
  const columnLength = columns?.length
  let currentNumberedColumn = 0
  let numberedIndexes = [0]

  for (let i = 0; i < columnLength; i++) {
    if (columns[i]?.layout === 'Numbered List') {
      numberedIndexes.push(columns[i]?.mainContent?.length)
    }
  }

  let startIndexes = numberedIndexes.map((n, i) => {
    if (i === 0) {
      return i
    }
    return numberedIndexes[i - 1] + n
  })

  return (
    <StyledColumnBlockContainer
      display="flex"
      width={isMobile ? '100%' : maxWidth}
      flexDirection={isMobile ? mobileLayout : 'row'}
      justifyContent={horizontalAlignment}
      mb={bottomMargin}
    >
      {columns?.map(({ id, layout, ...rest }, i) => {
        if (layout === 'Numbered List') {
          currentNumberedColumn += 1
          return (
            <Column
              key={`${id}${i}`}
              numberedStartIndex={startIndexes[currentNumberedColumn - 1]}
              layout={layout}
              gutter={gutter}
              {...rest}
            />
          )
        }
        return (
          <Column key={`${id}${i}`} gutter={gutter} layout={layout} {...rest} />
        )
      })}
    </StyledColumnBlockContainer>
  )
}

ColumnBlock.propTypes = {
  columns: PropTypes.array,
}
export default ColumnBlock
