import React from 'react'
import PropTypes from 'prop-types'
import FooterSimple from './FooterSimple'
import { useComponentTheme } from '../../hooks'

const Footer = ({ type = 'simple', theme: themeBool, ...rest }) => {
  const { theme } = useComponentTheme(themeBool)

  if (typeof window === 'undefined') return null // isMobile === undefined for SSR

  switch (type) {
    case 'simple':
    default:
      return <FooterSimple theme={theme} {...rest} />
  }
}

Footer.propTypes = {
  type: PropTypes.string,
}

export default Footer
