// Reference to how theme object interacts with styled system
// https://styled-system.com/api
// https://styled-system.com/guides/why-powers-of-two/

export const breakpoints = ['40em', '52em', '64em', '70em', '82em', '1130px']
export const space = [0, 4, 8, 16, 32, 64, 128, 256, 512]

// Typography
export const lineHeights = [1, 1.2, 1.6, 2, 3]
export const fontSizes =
  // P,H1, H2, H3, H4, H5, H6, Button 7, 8, 9
  [24, 90, 65, 32, 32, 29, 18, 22, 20, 18, 16, 14, 12]
export const fontWeights = {
  light: 300,
  regular: 400,
  semiBold: 500,
  bold: 600,
}
const fonts = {
  body:
    "Segoe Pro, 'Segoe Pro', 'Segoe UI', Segoe UI, Helvetica,Helvetica Neue,Arial,sans-serif,sans-serif",
  headings:
    "Segoe Pro, 'Segoe Pro', 'Segoe UI', Segoe UI, Helvetica,Helvetica Neue,Arial,sans-serif,sans-serif",
  nav:
    'Segoe Pro, Helvetica Bold,Helvetica,Helvetica Neue,Arial,sans-serif,sans-serif',
  buttons:
    "Segoe Pro, 'Segoe Pro', 'Segoe UI', Segoe UI, Helvetica,Helvetica Neue,Arial,sans-serif,sans-serif",
}
const shadows = {
  text: '0px 2px 8px #0000004f',
  bg: '2px 2px 2px #000',
}

// Colors
const white = '#ffffff'
const whiteOpaque = '#ffffff99'
const black = '#000'
const paleGrey = '#EDEDF0'
const fiftyPercentWhiteGrey = '#ffffff80'
const mdGrey = 'grey'
const darkGrey = '#18171B'
const lessDarkGrey = '#201F24'
const green = '#6FF44A'
const lime = '#6FF44A'
const grey = '#b3b3b3'

// different colors different places on mocks
// #9BF03E
// #6FF44A
// #89F23E
const lightBlack = '#1A1A1A'

const colors = {
  steps: {
    active: lime,
    inactive: mdGrey,
    incomplete: black,
  },
  border: {
    primary: lightBlack,
    secondary: mdGrey,
  },
  bg: {
    primary: black,
    secondary: whiteOpaque,
    tertiary: lessDarkGrey,
  },
  text: {
    primary: white,
    secondary: grey,
    tertiary: lime,
    inverted: black,
  },
  dark: {
    // false
    bg: {
      primary: black,
      secondary: darkGrey,
    },
    text: {
      primary: white,
      secondary: fiftyPercentWhiteGrey,
    },
    border: {
      primary: mdGrey,
      secondary: green,
    },
  },
  light: {
    // true, has white background
    bg: {
      primary: white,
      secondary: paleGrey,
    },
    text: {
      primary: black,
      secondary: darkGrey,
    },
    border: {
      primary: white,
      secondary: white,
    },
  },
}

// Buttons
const buttonStyles = { transition: 'all .2s ease' }

const buttons = {
  dark: {
    primary: {
      ...buttonStyles,
      opacity: 0.5,
      color: white,
      '&:hover': {
        opacity: 1,
      },
    },
  },
  light: {
    primary: {
      ...buttonStyles,
      color: black,
      '&:hover': {
        color: white,
      },
    },
  },
}

const sizes = {
  maxWidth: {
    xsmall: '30em',
    small: breakpoints[0],
    medium: breakpoints[1],
    large: breakpoints[2],
    xlarge: breakpoints[3],
    xxlarge: breakpoints[4],
  },
}

export const zIndices = {
  modal: 9000,
  navigation: 1000,
}

const xbox = {
  colors,
  buttons,
  breakpoints,
  fontSizes,
  fontWeights,
  sizes,
  lineHeights,
  shadows,
  fonts,
  space,
}

export default xbox
