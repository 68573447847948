import React from 'react'
import PropTypes from 'prop-types'
import { ProgressStepperApolloCPAContainer } from '../ProgressStepperContainer'

const CustomComponentApolloCPAContainer = ({ component }) => {
  switch (component) {
    case 'Progress Stepper':
      return <ProgressStepperApolloCPAContainer />
  }
  return null
}

CustomComponentApolloCPAContainer.propTypes = {
  component: PropTypes.string,
}

export default CustomComponentApolloCPAContainer
