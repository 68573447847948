import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
} from 'styled-system'

export const Container = styled.div`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledStepperContainer = styled(Container)``

export const StyledStepper = styled(Container)``

export const StyledStep = styled(Container)``

export const StyledTitle = styled(Container)`
  white-space: nowrap;
`

export const StyledTimeline = styled(Container)`
  box-sizing: border-box;
`

export const StyledLine = styled(Container)`
  transform: translateX(50%);
  box-sizing: border-box;
`

export const StyledDot = styled(Container)`
  height: 9px;
  width: 9px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -60%);

  ${({ isCurrent }) => isCurrent && 'height: 12px; width: 12px;'}
`

export const StyledInnerDot = styled(Container)`
  height: 5px;
  width: 5px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -65%);

  ${({ isCurrentCompleted }) =>
    isCurrentCompleted &&
    'height: 16px; width: 16px; transform: translate(-50%, -59%);'}
`

export const StyledStepMobile = styled(Container)``
export const StyledTimelineMobile = styled(Container)``
export const StyledLineMobile = styled(Container)`
  transform: translate(0%, 54%);
`
export const StyledDotMobile = styled(Container)`
  height: 9px;
  width: 9px;
  border-radius: 50%;
  transform: translate(-38%, 0%);
  ${({ isCurrent }) =>
    isCurrent && 'height: 12px; width: 12px; transform: translate(-40%, 0%);'}
`
export const StyledInnerDotMobile = styled(Container)`
  height: 5px;
  width: 5px;
  border-radius: 50%;
  transform: translate(-30%, 0%);
  ${({ isCurrentCompleted }) =>
    isCurrentCompleted &&
    'height: 16px; width: 16px; transform: translate(-43%, 0%);'}
`
