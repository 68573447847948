import styled from 'styled-components'
import {
  space,
  color,
  layout,
  typography,
  border,
  flexbox,
  position,
} from 'styled-system'
import Image from '../Image'
import playButton from './asset/Playbutton.png'

export const StyledImage = styled(Image)`
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  ${position}
`
export const Container = styled.div`
  box-sizing: border-box;
  transition: opacity 0.6s ease;
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  ${position}

  ${({ cursor }) => `cursor: ${cursor};`}
`

export const VideoContainer = styled(Container)`
  .video-js {
    min-height: inherit;
    z-index: 0;

    position: relative !important;
    &:hover .vjs-big-play-button {
      background: none !important;
    }

    .vjs-tech {
      ${({ videoFit }) =>
        videoFit === 'Cover'
          ? 'object-fit: cover;'
          : videoFit === 'Contain'
          ? 'object-fit: contain; object-position: top;'
          : ''}
    }
  }

  .video-js.landscape {
    padding-top: 56.25%;
  }

  .vjs-tech {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    &:focus {
      outline: 0;
    }
  }

  .vjs-big-play-button {
    border-radius: 50% !important;
    border: 0;
    background: transparent;
    &:hover {
      background: none !important;
    }
    &:focus {
      background: none !important;
    }
  }

  .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    content: '';
    background-image: url(${playButton});
    background-repeat: no-repeat;
    background-size: 46px;
    background-position: 55% calc(50% - 0px);
    border: none !important;
    box-shadow: none !important;
  }
`
// background: ${({gradient}) => gradient && 'radial-gradient(circle at center, red 0, blue 100%)'};

// ${({gradient}) => {
//   display: block;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   content: ' ';
//   z-index: 2;
//   backface-visibility: hidden;
//   background: #3452ff;
//   background: linear-gradient(135deg,#3452ff 0%,#ff1053 100%);
//   opacity: 0.8;
//  }}
