import styled from 'styled-components'
import {
  layout,
  position,
  space,
  color,
  border,
  shadow,
  typography,
  flexbox,
} from 'styled-system'

export const Container = styled.div`
  ${layout}
  ${position}
  ${space}
  ${color}
  ${border}
  ${shadow}
  ${typography}
  ${flexbox}
`

export const StyledDownloadListContainer = styled(Container)`
  box-sizing: border-box;
`

export const StyledHeader = styled.h1`
  ${layout}
  ${position}
  ${space}
  ${color}
  ${border}
  ${shadow}
  ${typography}
  ${flexbox}
`

export const StyledDivider = styled(Container)``

export const StyledItemContainer = styled(Container)`
  box-sizing: border-box;
`

export const StyledDownloadItemContainer = styled(Container)`
  box-sizing: border-box;
  ::-webkit-scrollbar {
    background: transparent;
  }
`

export const StyledItem = styled(Container)``

export const StyledDownloadButton = styled(Container)`
  box-sizing: border-box;
  cursor: pointer;
`
export const StyledDownload = styled.div`
  button {
    margin-top: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 12px;
    font-weight: bold;
  }
`

export const StyledImageContainer = styled(Container)`
  box-sizing: border-box;
`
