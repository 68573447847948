import { useState, useRef, useEffect, useCallback } from 'react'

const useMeasure = (initValue = 0) => {
  const [height, setHeight] = useState(initValue)
  const ref = useRef(null)

  const updateDimensions = useCallback(() => {
    if (ref.current) {
      const newHeight = ref.current.clientHeight
      if (newHeight !== height) setHeight(newHeight)
    }
  }, [height])

  useEffect(() => {
    if (ref.current) {
      updateDimensions()
      window.addEventListener('resize', updateDimensions)
    }
    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [updateDimensions])

  return { ref, height }
}

export default useMeasure
