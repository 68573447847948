import React from 'react'
import { ColumnBlock } from '@common/components/ColumnBlock'

const ColumnBlockGatsbyCDAContainer = ({
  columns,
  horizontalAlignment,
  gutter,
  bottomMargin,
  maxWidth,
  mobileLayout,
  ...rest
}) => {
  columns = columns?.map(
    ({ heroImage, mainContent, verticalAlignment, width, ...rest }) => ({
      ...rest,
      // contentful brings down null rather than undefined
      width: width || 100,
      verticalAlignment:
        verticalAlignment === 'top'
          ? 'flex-start'
          : verticalAlignment === 'bottom'
          ? 'flex-end'
          : 'center',
      heroImage: heroImage && {
        alt: heroImage?.description || heroImage?.title,
        src:
          heroImage?.source?.localFile?.childImageSharp?.fluid ||
          heroImage?.source?.fluid ||
          heroImage?.source?.file?.url,
      },
      mainContent: mainContent?.map(
        ({
          source,
          cloudinaryVideoSrc,
          thumbnail,
          body,
          displayTitle,
          ctaLink,
          hideBodyOnLocale,
          hideOnLocale,
          title,
          captions,
          ...rest
        }) => ({
          ...rest,
          captions: captions?.file?.url,
          title: title,
          body: body?.body,
          displayTitle: displayTitle?.displayTitle,
          alt:
            source?.description ||
            thumbnail?.description ||
            title ||
            'Xbox Series X|S Image',
          src:
            cloudinaryVideoSrc?.[0]?.original_secure_url ||
            source?.localFile?.childImageSharp?.fluid ||
            source?.file?.url ||
            source?.fluid,
          thumbnail:
            thumbnail?.localFile?.childImageSharp?.fluid?.src ||
            thumbnail?.fluid?.src ||
            thumbnail?.file?.url,
          ctaLink: {
            ...ctaLink?.[0],
            url: ctaLink?.[0]?.slug
              ? `/${ctaLink?.[0]?.slug}`
              : ctaLink?.[0]?.anchorTagId
              ? `/${ctaLink?.[0]?.anchorTagId}`
              : ctaLink?.[0]?.url,
            isVideo: ctaLink?.[0]?.__typename === 'ContentfulMediaVideo',
            // to work with modal gatsby container
            type: ctaLink?.[0]?.__typename,
          },
          hideBodyOnLocale: !hideBodyOnLocale ? [] : hideBodyOnLocale,
          hideOnLocale: !hideOnLocale ? [] : hideOnLocale,
        })
      ),
    })
  )

  horizontalAlignment =
    horizontalAlignment === 'left'
      ? 'flex-start'
      : horizontalAlignment === 'right'
      ? 'flex-end'
      : horizontalAlignment === 'spaced apart'
      ? 'space-between'
      : 'center'

  gutter = `${gutter / 2}px`
  bottomMargin =
    bottomMargin || bottomMargin === 0 ? `${bottomMargin}px` : '32px'
  maxWidth = maxWidth ? `${maxWidth}%` : '70%'

  mobileLayout = mobileLayout || 'column'

  return (
    <ColumnBlock
      columns={columns}
      horizontalAlignment={horizontalAlignment}
      gutter={gutter}
      bottomMargin={bottomMargin}
      maxWidth={maxWidth}
      mobileLayout={mobileLayout}
      {...rest}
    />
  )
}

export default ColumnBlockGatsbyCDAContainer
