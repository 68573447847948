import React from 'react'
import DownloadList from '@common/components/DownloadList'

const DownloadListGatsbyCDAContainer = ({
  id,
  title,
  downloadItems,
  ...rest
}) => {
  downloadItems = downloadItems?.map(({ id, title, thumbnail, item }) => ({
    id,
    title,
    thumbnailSrc:
      thumbnail?.source?.localFile?.childImageSharp?.fluid ||
      thumbnail?.source?.fluid ||
      thumbnail?.source?.file?.url,
    alt:
      thumbnail?.source?.title ||
      thumbnail?.title ||
      title ||
      'Asset Download Thumbnail',
    itemUrl: item?.file,
  }))

  return <DownloadList downloadItems={downloadItems} {...rest} />
}

export default DownloadListGatsbyCDAContainer
