import React, { useContext } from 'react'
import CarouselSlickBtn from './CarouselSlickBtn'
import { ThemeContext } from 'styled-components'
import { useComponentTheme } from '../../../hooks'
import {
  StyledButton,
  StyledButtonWidth,
  StyledButtonContainer,
} from '../styles'

const CarouselXboxStyledButtons = ({ slider, theme: themeBool = false }) => {
  const { theme } = useComponentTheme(themeBool)
  const themePackage = useContext(ThemeContext)

  return (
    <StyledButtonWidth
      position="absolute"
      left="0"
      right="0"
      top="0"
      bottom="0"
      width="100%"
      margin="auto"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <StyledButtonContainer
        display="flex"
        flexDirection="row"
        width="100%"
        px="9px"
        margin="0 auto"
        justifyContent="space-between"
        maxWidth={`calc(${themePackage.sizes.maxWidth.xlargeNav} - 35px)`}
      >
        <StyledButton zIndex={1}>
          <CarouselSlickBtn
            onClick={() => slider.current.slickPrev()}
            direction="left"
            fill="black"
            hover={themePackage.colors[theme].btnHover}
            arrowColor="grey"
          />
        </StyledButton>
        <StyledButton zIndex={1}>
          <CarouselSlickBtn
            onClick={() => slider.current.slickNext()}
            direction="right"
            fill="black"
            hover={themePackage.colors[theme].btnHover}
            arrowColor="grey"
          />
        </StyledButton>
      </StyledButtonContainer>
    </StyledButtonWidth>
  )
}

export default CarouselXboxStyledButtons
