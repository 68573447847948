import React from 'react'
import PropTypes from 'prop-types'
import { useComponentTheme } from '../../../hooks'
import NavigationMobileSimple from './NavigationMobileSimple'

const NavigationMobile = ({ navType, theme: themeBool, ...props }) => {
  const { theme } = useComponentTheme(themeBool)

  switch (navType) {
    default:
      return <NavigationMobileSimple theme={theme} {...props} />
  }
}

NavigationMobile.propTypes = {
  type: PropTypes.string,
  theme: PropTypes.bool,
}

export default NavigationMobile
