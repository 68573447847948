import styled, { keyframes } from 'styled-components'

import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  border,
  shadow,
} from 'styled-system'

export const BaseButton = styled.button`
  text-transform: uppercase;
  box-shadow: none;
  background-image: none;
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${space}
  ${shadow}
  ${position}
  ${flexbox}
  cursor: pointer;
  & img {
    color: #6FF44A;
    padding-right: 8px;

  }

`

export const BaseA = styled.a`
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${space}
  ${shadow}
  ${position}
  ${flexbox}
`

export const FakeButtonA = styled(BaseA)`
  border: 2px solid white;
`
