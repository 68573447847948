import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
  variant,
} from 'styled-system'
import { A } from '../Button'

export const StyledContainer = styled.div`
  box-sizing: border-box;
  transition: opacity 0.25s ease-in-out 0.05s;
  ::-webkit-scrollbar {
    display: none;
  }
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledSkipToContent = styled.div`
  box-sizing: border-box;
  transform: translateY(-200%);

  &:focus-within{
    transform: translateY(0);
  }
  
  a {
    
    padding: 0;
    opacity: 1;
  }

  a:focus {
    z-index: 2;
    transform: translateY(0);
    button {
      width: 200px;
    }
    width: 200px;
    opacity: 1;
    background-color: black;
  }

  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledNavContainer = styled.nav`
  box-sizing: border-box;
  transition: z-index 0.25s ease-in-out;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledSvg = styled.img`
  ${space}
`

export const StyledList = styled.ul`
  ${space}
  ${flexbox}
  ${layout}
`

export const StyledListItem = styled.li`
  ${space}
  ${flexbox}

  a {
    text-decoration: none;
  }
`

export const StyledText = styled.p`
  padding: 0;
  margin: 0;
  ${space} 
  ${typography}
  ${color}
`

export const BaseButton = styled(A)(variant({ scale: 'buttons' }))

export const StyledNavLink = styled(BaseButton)`
  cursor: pointer;
  ${space}
  ${color}
  ${typography}
`

export const StyledMenuBtnContainer = styled.div`
  cursor: pointer;
  display: inline-block;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  overflow: visible;
  ${flexbox}
  ${space}
  ${color}
`

export const StyledBtnBox = styled.span`
  display: inline-block;
  position: relative;
  ${layout}
`

export const StyledMenuBtnLine = styled.span`
  display: block;
  margin-top: -2px;
  height: 2px;
  position: absolute;
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  ${({ toggled }) =>
    toggled &&
    `background: #fff;
  -webkit-transform: translate3d(0,-10px,0) rotate(-45deg);
  transform: translate3d(0,-10px,0) rotate(-45deg);
  transition-delay: .22s;
  transition-timing-function: cubic-bezier(.215,.61,.355,1);`}
  ${color}
  ${layout}
  ${position}
  ${({ large, width, toggled }) =>
    large && !toggled && `width: ${width.split('px')[0] * 0.8}px;`}

  &::before {
    height: 2px;
    background-color: #000;
    position: absolute;
    content: '';
    display: block;
    background: inherit;
    top: ${({ large }) => (large ? '-12px' : '-8px')};
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19),
      -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    ${({ toggled }) =>
      toggled &&
      `background: #fff;
    top: 0;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transition: top .1s .16s cubic-bezier(.33333,0,.66667,.33333),-webkit-transform .13s .25s cubic-bezier(.215,.61,.355,1);
    transition: top .1s .16s cubic-bezier(.33333,0,.66667,.33333),transform .13s .25s cubic-bezier(.215,.61,.355,1);
    transition: top .1s .16s cubic-bezier(.33333,0,.66667,.33333),transform .13s .25s cubic-bezier(.215,.61,.355,1),-webkit-transform .13s .25s cubic-bezier(.215,.61,.355,1);`}
    ${layout}
    ${position}
  }

  &::after {
    height: 2px;
    background-color: #000;
    position: absolute;
    content: '';
    display: block;
    background: inherit;
    top: ${({ large }) => (large ? '-24px' : '-16px')};
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      opacity 0.1s linear;

    ${({ toggled }) =>
      toggled &&
      `background: #fff;
    top: 0;
    opacity: 0;
    transition: top .2s cubic-bezier(.33333,0,.66667,.33333),opacity .1s .22s linear;`}
    ${layout}
    ${position}
  }
`
