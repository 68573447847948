import React from 'react'
import PropTypes from 'prop-types'
import { StyledSvg, StyledContainer } from '../styles'
import { A } from '../../Button'

const FooterLogo = ({ logo, homeSlug = '/', height, href, ...rest }) => {
  const src = logo?.file?.url
  const alt = logo?.description || logo?.title
  return (
    <StyledContainer p={[0, 3, 3]} {...rest}>
      <A to={homeSlug} href={href}>
        <StyledSvg
          src={`https:${src}`}
          alt={alt}
          width="auto"
          height={height}
        />
      </A>
    </StyledContainer>
  )
}

FooterLogo.propTypes = {
  logo: PropTypes.object,
  alt: PropTypes.string,
  homeSlug: PropTypes.string,
}

export default FooterLogo
