import React from 'react'
import PropTypes from 'prop-types'
import { ProgressStepperGatsbyCDAContainer } from '../ProgressStepperContainer'

const CustomComponentGatsbyCDAContainer = ({ component }) => {
  switch (component) {
    case 'Progress Stepper':
      return <ProgressStepperGatsbyCDAContainer />
  }
  return null
}

CustomComponentGatsbyCDAContainer.propTypes = {
  component: PropTypes.string,
}

export default CustomComponentGatsbyCDAContainer
