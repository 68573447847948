import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledMenuBtnContainer,
  StyledMenuBtnLine,
  StyledBtnBox,
} from '../styles'

const NavigationMenuBtn = ({
  handleClick = () => {},
  handleKeyPress = () => {},
  toggled = false,
  order,
}) => {
  return (
    <StyledMenuBtnContainer
      data-testid="menu-btn"
      onClick={handleClick}
      p={2}
      m={3}
      tabIndex="0"
      onKeyDown={handleKeyPress}
    >
      <StyledBtnBox width="26px" height="24px">
        <StyledMenuBtnLine width="26px" toggled={toggled} bg={`text.primary`} />
      </StyledBtnBox>
    </StyledMenuBtnContainer>
  )
}

NavigationMenuBtn.propTypes = {
  handleClick: PropTypes.func.isRequired,
  toggled: PropTypes.bool.isRequired,
  theme: PropTypes.string,
  large: PropTypes.bool,
  order: PropTypes.number,
}
export default NavigationMenuBtn
