import React, { useEffect, useState } from 'react'
import usePreviewQuery, { GET_ASSET } from '@common/hooks/usePreviewQuery'
import { Image } from '@common/components/Image'

const ImageApolloCPAContainer = ({ source, ...rest }) => {
  const { loading, error, data } = usePreviewQuery({
    query: GET_ASSET,
    id: source?.sys?.id,
    skipBool: !source?.sys?.id,
  })

  const [sanitizedSource, setSanitizedSource] = useState()

  useEffect(() => {
    if (!loading && !error && data) {
      const { asset } = data
      const sanitizedAsset = {
        alt: asset?.fields?.title,
        src: asset?.fields?.file?.url,
      }
      setSanitizedSource(sanitizedAsset)
    }
  }, [loading, data, error])

  return <Image {...sanitizedSource} width="100%" />
}

ImageApolloCPAContainer.propTypes = {}
export default ImageApolloCPAContainer
