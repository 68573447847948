import React from 'react'
import PropTypes from 'prop-types'
import { useLocale, useLockScroll, usePortal } from '@common/hooks'
import Portal from '@common/components/Modal/components/Portal'
import {
  StyledContainer,
  StyledText,
  StyledIcon,
  StyledList,
  StyledDropdownContainer,
  Overlay,
} from '../styles'
import dropdownArrow from '@common/assets/dropdown-arrow-white.svg'
import LocaleItem from './LocaleItem'

const DropdownMenu = ({ locales, currentLocale, closePortal }) => {
  useLockScroll()
  return (
    <div>
      <Overlay onClick={closePortal} />
      <StyledDropdownContainer
        position="absolute"
        bg="black"
        width={[3 / 4, 2 / 3, 2 / 5]}
        left="50%"
        top="50%"
        p={25}
      >
        <StyledList
          display="flex"
          flexDirection="column"
          role="menu"
          aria-label="Main Footer"
        >
          {locales?.map(({ id, name, code }) => (
            <LocaleItem
              key={id}
              currentLocale={currentLocale}
              name={name}
              code={code}
              id={id}
            />
          ))}
        </StyledList>
      </StyledDropdownContainer>
    </div>
  )
}

const LocaleDisplay = ({ name, openPortal, isOpen }) => {
  return (
    <StyledContainer position="relative" onClick={openPortal} minWidth="160px">
      <StyledContainer
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <StyledText
          px={[0, 3, 3]}
          py={2}
          color="white"
          fontSize={12}
          fontFamily="nav"
          aria-current="locale"
          cursor="pointer"
        >
          {name}
        </StyledText>
        <StyledIcon
          src={dropdownArrow}
          alt="dropdown-arrow"
          rotate={isOpen ? '180deg' : '0deg'}
        />
      </StyledContainer>
    </StyledContainer>
  )
}

const LocaleSwitcher = ({ locales }) => {
  const { locale, localeSlug } = useLocale()
  const { isOpen, openPortal, closePortal, modalRef } = usePortal({
    closeOnOutsideClick: true,
    closeOnEsc: true,
  })
  const currentLocale = locales?.find(
    ({ code }) =>
      code === locale ||
      code?.split('preview')?.[0]?.replace('/', '') === localeSlug
  )

  return (
    <>
      <LocaleDisplay
        isOpen={isOpen}
        openPortal={openPortal}
        {...currentLocale}
      />
      {isOpen && (
        <Portal ref={modalRef}>
          <DropdownMenu
            locales={locales}
            currentLocale={currentLocale}
            closePortal={closePortal}
          />
        </Portal>
      )}
    </>
  )
}

LocaleSwitcher.propTypes = {
  locales: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      code: PropTypes.string,
    })
  ),
}

export default LocaleSwitcher
