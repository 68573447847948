import React, { useState, useEffect, useCallback } from 'react'
import Footer from '@common/components/Footer'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
  GET_LOCALE_ENTRIES,
} from '@common/hooks/usePreviewQuery'
import { parseQueryString, hexToRgb } from '@common/utils'
import { useLocale } from '@common/hooks'

const FooterContainerApollo = props => {
  const { locale } = useLocale()
  const {
    loading: loadingNavItems,
    error: errorNavItems,
    data: dataNavItems,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'navigation',
    locale,
  })
  const {
    loading: loadingSiteInfo,
    error: errorSiteInfo,
    data: dataSiteInfo,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'siteInformationAndBranding',
    locale,
  })
  const {
    loading: loadingLocaleEntries,
    error: errorLocaleEntries,
    data: dataLocaleEntries,
  } = usePreviewQuery({
    query: GET_LOCALE_ENTRIES,
  })

  const DEFAULT_HOME_PREVIEW_ID = '4c4o2bt9cRrhdKVqGZlnlO'

  const [navItems, setNavItems] = useState([])
  const [siteInfo, setSiteInfo] = useState({})
  const [locales, setLocales] = useState([])

  const getLinkedEntry = (arr, targetId) => {
    return arr.find(({ sys: { id } }) => id === targetId)
  }

  const getMappedAnchorEntry = useCallback((anchor, entryIncludes) => {
    const linkedAnchor =
      anchor && getLinkedEntry(entryIncludes, anchor?.sys?.id)
    return (
      linkedAnchor && {
        id: linkedAnchor?.sys?.id,
        anchorTagId: linkedAnchor?.fields?.anchorTagId,
      }
    )
  }, [])

  const getMappedSubnavLink = useCallback((subnavLink, entryIncludes) => {
    const linkedSubnav =
      subnavLink && getLinkedEntry(entryIncludes, subnavLink?.sys?.id)
    return (
      linkedSubnav && {
        id: linkedSubnav?.sys?.id,
        ...linkedSubnav?.fields,
        slug: `preview/?t=${parseQueryString('t')}&id=${linkedSubnav?.sys?.id}`,
      }
    )
  }, [])

  const getMappedSubnavItems = useCallback(
    (subnavItems, entryIncludes) => {
      const items = subnavItems
        ?.map(({ sys: { id } }) => getLinkedEntry(entryIncludes, id))
        ?.map(({ fields, sys }) => ({ ...fields, id: sys.id }))
        ?.map(({ anchor, navigationLink, ...rest }) => ({
          anchor: getMappedAnchorEntry(anchor, entryIncludes),
          navigationLink: getMappedSubnavLink(navigationLink, entryIncludes),
          ...rest,
        }))
      return items
    },
    [getMappedAnchorEntry, getMappedSubnavLink]
  )

  const getMappedNavItems = useCallback(
    (data, entryIncludes) => {
      const navItemIds = data?.map(({ sys: { id } }) => id)

      const navItems = navItemIds
        ?.map(id => getLinkedEntry(entryIncludes, id))
        ?.map(({ fields, sys }) => ({ ...fields, id: sys.id }))

      const mappedNavItems = navItems?.map(
        ({
          navigationLink: {
            sys: { id },
          },
          subnavigationItems,
          ...rest
        }) => {
          const linkedEntry = getLinkedEntry(entryIncludes, id)?.fields
          const mappedSubnavigationItems = getMappedSubnavItems(
            subnavigationItems,
            entryIncludes
          )

          return {
            navigationLink: {
              id,
              slug: `preview/?t=${parseQueryString('t')}&id=${id}`,
              url: linkedEntry?.url,
              displayText: linkedEntry?.title,
              __typename: linkedEntry?.slug
                ? 'ContentfulPage'
                : 'ContentfulExternalLink',
            },
            subnavigationItems: mappedSubnavigationItems,
            ...rest,
          }
        }
      )

      return mappedNavItems
    },
    [getMappedSubnavItems]
  )

  const getMappedBackgroundColor = useCallback((background, entryIncludes) => {
    const linkedEntry = getLinkedEntry(entryIncludes, background?.sys?.id)

    return (
      linkedEntry &&
      hexToRgb(linkedEntry?.fields?.hex, linkedEntry?.fields?.opacity)
    )
  }, [])

  useEffect(() => {
    if (!loadingNavItems && !errorNavItems && dataNavItems) {
      const {
        entries: {
          includes: { Entry },
          items,
        },
      } = dataNavItems

      const filteredNavData = items?.filter(
        ({ fields: { placement } }) => placement === 'Footer'
      )?.[0]?.fields

      const filteredNavItems = filteredNavData?.navigationItems
      const backgroundColor = getMappedBackgroundColor(
        filteredNavData?.background,
        Entry
      )

      let mappedNavItems = getMappedNavItems(filteredNavItems, Entry)
      setNavItems({ mappedNavItems, backgroundColor })
    }
  }, [
    errorNavItems,
    loadingNavItems,
    dataNavItems,
    getMappedNavItems,
    getMappedBackgroundColor,
  ])

  useEffect(() => {
    if (!loadingSiteInfo && !errorSiteInfo && dataSiteInfo) {
      const {
        entries: {
          includes: { Asset },
          items,
        },
      } = dataSiteInfo

      let filteredSiteData = items?.[0]?.fields
      const mappedLogo = getLinkedEntry(Asset, filteredSiteData?.logo.sys.id)
      const mappedSecondaryLogo = getLinkedEntry(
        Asset,
        filteredSiteData?.secondaryLogo.sys.id
      )

      const mappedSiteInfo = {
        title: filteredSiteData?.title,
        description: filteredSiteData?.description,
        logo: { ...mappedLogo?.fields, id: mappedLogo.sys.id },
        secondaryLogo: {
          ...mappedSecondaryLogo?.fields,
          id: mappedSecondaryLogo.sys.id,
        },
        copyright: filteredSiteData?.copyright,
      }
      setSiteInfo(mappedSiteInfo)
    }
  }, [dataSiteInfo, errorSiteInfo, loadingSiteInfo])

  useEffect(() => {
    if (!loadingLocaleEntries && !errorLocaleEntries && dataLocaleEntries) {
      const {
        entries: { items },
      } = dataLocaleEntries

      const mappedLocaleEntries = items?.map(
        ({ sys: { id }, code, ...rest }) => {
          const previewSlug = `preview/?t=${parseQueryString(
            't'
          )}&id=${DEFAULT_HOME_PREVIEW_ID}`
          return {
            id,
            code: code === 'en-US' ? previewSlug : `${code}/${previewSlug}`,
            ...rest,
          }
        }
      )

      setLocales(mappedLocaleEntries)
    }
  }, [dataLocaleEntries, errorLocaleEntries, loadingLocaleEntries])

  return (
    <Footer
      navItems={navItems?.mappedNavItems}
      backgroundColor={navItems?.backgroundColor}
      logo={siteInfo?.logo}
      secondaryLogo={siteInfo?.secondaryLogo}
      alt={siteInfo?.title}
      copyright={siteInfo?.copyright}
      locales={locales}
      {...props}
    />
  )
}

export default FooterContainerApollo
