import React from 'react'
import PropTypes from 'prop-types'
import { Container, Cell, H4 } from './style'

const TableRow = ({ tableData, title, isOpen }) => {
  return (
    <Container
      width={[1, 1 / 2, 1 / 2, 1 / 5]}
      my={[3, 3, 3, 4]}
      p="0 20px 0 0"
      display={isOpen ? 'block' : 'none'}
    >
      <H4 fontSize={[10]}>{title}</H4>
      {tableData?.map((t, i) => (
        <Cell
          key={`${t} + ${i}`}
          fontSize={[11]}
          py={[2]}
          color="text.secondary"
        >
          {t}
        </Cell>
      ))}
    </Container>
  )
}

TableRow.propTypes = {
  title: PropTypes.string,
  tableData: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.bool,
}

export default TableRow
