import { css, createGlobalStyle } from 'styled-components'
import theme from '../../themes'

export const fontStack = css`
  font-family: 'Segoe UI', 'SegoeUI', 'Segoe WP', SegoeUI, SegoePro, 'Segoe Pro',
    'Helvetica Neue', Helvetica, Arial, '-apple-system', BlinkMacSystemFont,
    sans-serif;
  font-weight: 400;
  font-style: normal;
`

export const GlobalStyle = createGlobalStyle`
  html {
    ${fontStack};
    width: 100%; 
    height: 100%;
    


  }
  ::selection {
    background: white; /* WebKit/Blink Browsers */
    color: black;
  }
  ::-moz-selection {
    background: white;
    color: black;
  }

  body {
    color: ${theme.colors.dark.text.primary};
    background-color: ${theme.colors.dark.bg.primary};
    line-height: 1.2;
    margin: 0;
    ::-webkit-scrollbar {
      display: none;
    }
  }
    

  ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`
