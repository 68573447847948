import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledFooterContainer,
  StyledText,
  StyledList,
  StyledListItem,
} from './styles'
import FooterWrapper from './components/FooterWrapper'
import FooterLogo from './components/FooterLogo'
import NavigationItem from './components/NavigationItem'
import LocaleSwitcher from './components/LocaleSwitcher'
import { useLocale } from '@common/hooks'

const FooterSimple = ({
  navItems,
  backgroundColor,
  logo,
  secondaryLogo,
  alt,
  theme,
  copyright,
  homeSlug,
  locales,
}) => {
  const { locale } = useLocale()
  return (
    <StyledFooterContainer
      data-testid="footer-simple"
      role="contentinfo"
      bg={backgroundColor || `${theme}.bg.secondary`}
      padding="20px 20px 40px"
    >
      {logo && (
        <FooterWrapper padding="0px 0px 30px">
          <FooterLogo logo={logo} alt={alt} homeSlug={homeSlug} />
          {secondaryLogo && (
            <FooterLogo
              logo={secondaryLogo}
              alt={alt}
              height="23px"
              href={`https://www.microsoft.com/${locale}`}
            />
          )}
        </FooterWrapper>
      )}

      <FooterWrapper>
        <LocaleSwitcher locales={locales} />

        <StyledList display="flex" aria-label="Main Footer">
          {navItems && (
            <StyledListItem>
              <StyledList display="flex" role="menu">
                {navItems?.map(({ id, ...rest }) => (
                  <StyledListItem key={id} role="menuitem">
                    <NavigationItem {...rest} />
                  </StyledListItem>
                ))}
              </StyledList>
            </StyledListItem>
          )}
          {copyright && (
            <StyledListItem>
              <StyledText fontFamily="nav" fontSize={12} p={3} pr={[0, 3, 3]}>
                {copyright}
              </StyledText>
            </StyledListItem>
          )}
        </StyledList>
      </FooterWrapper>
    </StyledFooterContainer>
  )
}

FooterSimple.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
  backgroundColor: PropTypes.string,
  logo: PropTypes.object,
  secondaryLogo: PropTypes.object,
  alt: PropTypes.string,
  theme: PropTypes.string,
  copyright: PropTypes.string,
  homeSlug: PropTypes.string,
  locales: PropTypes.arrayOf(PropTypes.object),
}

export default FooterSimple
