import { Children, cloneElement } from 'react'
import axios from 'axios'
import cheerio from 'cheerio'
export const hasWindow = typeof window !== 'undefined' && window
export const isUnsupported =
  hasWindow &&
  navigator !== undefined &&
  (/MSIE 9/i.test(navigator.userAgent) ||
    /MSIE 10/i.test(navigator.userAgent) ||
    /Trident/i.test(navigator.userAgent))

export const isDebug = hasWindow && process.env.NODE_ENV === 'development'

export const shareSocial = (platform, location, name) => {
  switch (platform) {
    case 'facebook':
      return `https://www.facebook.com/sharer.php?u=${location}`
    case 'linkedin':
      return `https://www.linkedin.com/shareArticle?mini=true&url=${location}&title=ayzenberg : players`
    case 'twitter':
      return `https://twitter.com/share?text=Meet: ${name} &url=${location}&hashtags=anetwork, create`
    case 'reddit':
      return `https://www.reddit.com/submit?url=${location}&title=Meet: ${name}`
    case 'pinterest':
      return `http://pinterest.com/pin/create/link/?url=${location}`
    case 'email':
      return `https://www.addthis.com/tellfriend_v2.php?url=${location}`
    default:
      return `https://${platform}.com/`
  }
}

export const categorize = (items, categoryName) => {
  const categoryMap = {}
  if (items) {
    items.forEach(item => {
      const category = item[categoryName]
      if (!categoryMap[category]) {
        categoryMap[category] = []
      }
      categoryMap[category].push(item)
    })
  }
  const categories = Object.keys(categoryMap).filter(
    c => c !== 'undefined' && c !== 'None'
  )
  const undefinedList = categoryMap['undefined'] || []
  const noneList = categoryMap['None'] || []
  const uncategorizedList = [...undefinedList, ...noneList]
  return { categories, categoryMap, uncategorizedList }
}

export const formatDate = date => {
  var fdate = new Date(date)
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  return (
    months[fdate.getMonth()] +
    ' ' +
    fdate.getDate() +
    ', ' +
    fdate.getFullYear()
  )
}

export const trunc = (str, len = 100) => {
  if (typeof str !== 'string') {
    return ''
  }
  if (str.length <= len) {
    return str
  }
  return str.substr(0, len - 1) + '...'
}

export const addQueryDataToBlade = ({ bladeObj, key, value }) => {
  return (bladeObj[key] = value)
}

export const addQueryDataBasedOnLayout = ({
  bladeLayout,
  bladeObj,
  key,
  value,
}) => {
  if (bladeObj.layout && bladeObj.layout === bladeLayout) {
    addQueryDataToBlade({ bladeObj, key, value })
  }
}

export const mappedContent = (c, type) => {
  let content = []
  if (!Array.isArray(c)) return null
  for (let i = 0; i < c.length; i++) {
    const contentCheck =
      c[i] &&
      c[i].data &&
      c[i].data.target &&
      c[i].data.target.sys &&
      c[i].data.target.sys.contentType &&
      c[i].data.target.sys.contentType.sys &&
      c[i].data.target.sys.contentType.sys.id === type
    if (contentCheck) {
      const val = c[i].data && c[i].data.target && c[i].data.target.fields
      content.push(val)
    } else if (
      c[i] &&
      c[i].content &&
      Array.isArray(c[i].content) &&
      c[i].content.length > 0
    ) {
      content = content.concat(mappedContent(c[i].content, type))
    }
  }
  return content
}
export const mappedString = (c, type) => {
  if (!c) return null

  let content = []
  if (!Array.isArray(c)) return null
  for (let i = 0; i < c.length; i++) {
    const contentCheck =
      c[i] &&
      c[i].value &&
      c[i].value &&
      c[i].value.length > 0 &&
      c[i] &&
      c[i].nodeType === type

    if (contentCheck) {
      let val = c[i] && c[i].value && c[i].value && c[i].value
      content.push(val)
    } else if (
      c[i] &&
      c[i].content &&
      Array.isArray(c[i].content) &&
      c[i].content.length > 0
    ) {
      content = content.concat(mappedString(c[i].content, type))
    }
  }
  return content
}

export const parseQueryString = queryVariable => {
  const query = window.location.search.substring(1)

  const variables = query.split('&')
  for (let i = 0; i < variables.length; i++) {
    let pair = variables[i].split('=')
    if (pair[0] === queryVariable) {
      return pair[1]
    }
  }
}

// determining blade vertical alignment
export const verticalObj = {
  Middle: 'center',
  Top: 'flex-start',
  Bottom: 'flex-end',
  center: 'center',
}
export const horizontalObj = {
  Center: 'center',
  Left: 'flex-start',
  Right: 'flex-end',
  center: 'center',
}

export const filterAssetsObjFromAssetsArrById = ({ id, assetsArr }) =>
  assetsArr.filter(item => item.id === id)[0]

export const filterAssetsObjFromAssetsArrByIdAndReturnFluid = ({
  id,
  assetsArr,
}) => {
  let newItem = assetsArr.filter(item => item.id === id)[0]

  if (newItem?.file?.url) {
    newItem.fluid = {
      src: newItem.file.url,
    }
  }
  return newItem
}

export const filterAssetsObjFromAssetsArrByIdAndReturnSrcString = ({
  id,
  assetsArr,
}) => {
  let newItem = assetsArr.filter(item => item.id === id)[0]

  if (newItem?.file?.url) {
    newItem.fluid = newItem.file.url
    return newItem
  }
  if (newItem?.src) {
    return newItem.src
  }
  return newItem
}

export const parseResponse = response => {
  const $ = cheerio.load(response.data)
  const scripts = $(`html > body > script`) // Code smells #40 and #42

  let id = 0

  if (scripts.get(0).attribs.type === `application/ld+json`) {
    id = 1
  }

  const jsonData = $(`html > body > script`)
    .get(id)
    .children[0].data.replace(/window\._sharedData\s?=\s?{/, `{`)
    .replace(/;$/g, ``)
  return JSON.parse(jsonData).entry_data
}

export const parseChildren = (shortenDescription, arr) => {
  arr.forEach(c => {
    if (shortenDescription && c[1] !== '') arr = shortenDescription
  })
  return shortenDescription
}

export const filterLinkedIds = ({ dataObj, key }) => {
  if (!dataObj.fields[key]) {
    return []
  }
  return dataObj.fields[key].map(({ sys }) => ({ id: sys.id }))
}

export const filterLinkedIdsArr = ({ arr }) => {
  if (!Array.isArray(arr)) {
    return []
  }
  return arr.map(({ sys }) => ({ id: sys.id }))
}

export const filterAssets = ({ assetsArr }) => {
  if (!assetsArr) {
    return []
  }
  return assetsArr.map(({ sys, fields }) => ({ id: sys.id, ...fields }))
}
export const formatImageMediaArr = ({ assets, mediaObj, assetId }) => {
  if (!assets || !mediaObj || !assetId) {
    return []
  } else {
    let thumbnail =
      mediaObj &&
      mediaObj.thumbnail &&
      filterAssetsObjFromAssetsArrById({
        id: mediaObj.thumbnail.sys.id,
        assetsArr: assets,
      })
    let srcContentful = filterAssetsObjFromAssetsArrById({
      id: assetId,
      assetsArr: assets,
    })

    let mediaObjWithAssets = {
      id: mediaObj.id,
      title: mediaObj.title,
      src: mediaObj.src,
      srcContentful: {
        id: srcContentful?.id,
        fluid: {
          src: srcContentful?.file?.url,
        },
      },
      thumbnail: {
        id: thumbnail?.id,
        fluid: {
          src: thumbnail?.file?.url,
        },
      },
    }
    return [mediaObjWithAssets]
  }
}
export const formatVideoMediaArr = ({ assets, mediaObj, assetId }) => {
  if (!assets || !mediaObj || !assetId) {
    return []
  } else {
    let thumbnail =
      mediaObj &&
      mediaObj.thumbnail &&
      filterAssetsObjFromAssetsArrById({
        id: mediaObj.thumbnail.sys.id,
        assetsArr: assets,
      })
    let srcContentful = filterAssetsObjFromAssetsArrById({
      id: assetId,
      assetsArr: assets,
    })
    let mediaObjWithAssets = {
      title: mediaObj.title,
      src: mediaObj.src,
      srcContentful: {
        id: srcContentful && srcContentful.id,
        file: {
          url: srcContentful && srcContentful?.file?.url,
        },
      },
      thumbnail: {
        id: thumbnail && thumbnail.id,
        fluid: {
          src: thumbnail && thumbnail.file.url,
        },
      },
    }
    return [mediaObjWithAssets]
  }
}
export const formatMediaArr = ({ assets, mediaObj, assetId }) => {
  if (!assets || !mediaObj) {
    return []
  } else if (!assetId) {
    let mediaObjWithAssets
    let thumbnail =
      mediaObj &&
      mediaObj.thumbnail &&
      filterAssetsObjFromAssetsArrById({
        id: mediaObj.thumbnail.sys.id,
        assetsArr: assets,
      })
    mediaObjWithAssets = {
      ...mediaObj,
      thumbnail: {
        id: thumbnail && thumbnail.id,
        fluid: {
          src: thumbnail && thumbnail.file.url,
        },
      },
    }

    return [mediaObjWithAssets]
  } else {
    let mediaObjWithAssets
    let thumbnail =
      mediaObj &&
      mediaObj.thumbnail &&
      filterAssetsObjFromAssetsArrById({
        id: mediaObj.thumbnail.sys.id,
        assetsArr: assets,
      })
    let srcContentful = filterAssetsObjFromAssetsArrById({
      id: assetId,
      assetsArr: assets,
    })
    mediaObjWithAssets = {
      autoPlay: mediaObj.autoPlay,
      title: mediaObj.title,
      src: mediaObj.src,
      srcContentful: {
        id: srcContentful?.id,
        fluid: {
          src: srcContentful?.file?.url,
        },
        file: {
          url: srcContentful && srcContentful?.file?.url,
        },
      },
      thumbnail: {
        id: thumbnail && thumbnail.id,
        fluid: {
          src: thumbnail && thumbnail.file.url,
        },
      },
    }

    return [mediaObjWithAssets]
  }
}

export const renderThumbnail = thumb => {
  if (thumb == null) {
    return ''
  } else if (thumb['en-US']) {
    return thumb['en-US'].fields?.file['en-US'].url
  } else if (thumb?.fields) {
    return thumb.fields?.file['en-US']?.url
  } else {
    return thumb?.fluid?.src
  }
}

export const getVimeoSrc = str => {
  return str
}
export const getYoutubeEmbed = video => {
  if (video.includes('watch?v=')) {
    video = video.replace('watch?v=', 'embed/')
  }
  if (video.includes('&feature=emb_logo')) {
    video = video.replace('&feature=emb_logo', '')
  }
  return video
}

export const getVideoType = url => {
  if (url && typeof url === 'string' && url.includes('youtube')) {
    url = getYoutubeEmbed(url)
  }
  if (url && typeof url === 'string' && url.includes('vimeo')) {
    url = getVimeoSrc(url)
  }
  // going to add other external links in the future
  return url
}

export const renderImage = ({ mediaObj, locale }) => {
  removeLocale(mediaObj, locale)
  let image = {
    src: false,
    assetId: false,
    type: 'image',
    id: 'image-id',
    alt: '',
    thumbnail: false,
    autoPlay: false,
  }

  if (mediaObj == null) {
    return image
  } else if (mediaObj.source) {
    const { source, title } = mediaObj
    image.src = source?.fields?.file?.url || source?.file?.url
    image.title = title
    image.assetId = source?.sys?.id
  } else {
    return {}
  }
  return image
}

export const removeLocale = (media, locale) => {
  const us = 'en-US'
  const objArr = Object.keys(media)
  objArr.forEach(m => {
    if (m === 'fields') {
      removeLocale(media?.fields, locale)
    } else if (media[m]?.fields) {
      removeLocale(media[m]?.fields, locale)
    } else if (media[m]?.[locale] || media[m]?.[us]) {
      media[m] = media[m]?.[locale] || media[m]?.[us]
      if (media[m]?.fields) {
        removeLocale(media[m]?.fields, locale)
      }
    }
  })
  return media
}

export const renderVideo = ({ mediaObj, locale }) => {
  removeLocale(mediaObj, locale)
  const { id, thumbnail, title, captions, cloudinaryVideoSrc, srcContentful } =
    mediaObj || {}
  const mediaSrc = mediaObj?.src
  let video = {
    src: false,
    type: 'video',
    id: 'vid-id',
    alt: '',
    thumbnail: false,
    captions: null,
  }
  switch (mediaSrc) {
    case 'Cloudinary - MP4':
      const { original_secure_url, public_id } = cloudinaryVideoSrc?.[0]
      video.assetId = public_id || video.id
      video.src = original_secure_url
      video.id = id || video.id
      video.alt = title
      video.thumbnail = thumbnail?.fields?.file?.url
      video.captions = captions?.fields?.file?.url
      video.title = title
      return video
    case 'Contentful - MP4':
      video.src = srcContentful?.fields?.file?.url
      video.assetId = srcContentful?.sys?.id
      video.type = 'video-internal'
      video.alt = srcContentful?.fields?.title || mediaObj?.title
      video.id = srcContentful?.sys?.id
      video.thumbnail = thumbnail?.fields?.file?.url
      video.captions = captions?.fields?.file?.url
      return video
    default:
      return video
  }
}
export const renderBgTypeAndBg = ({ background, theme, locale }) => {
  // we only need to check if the media obj is a video,
  // otherwise we can assume it is an image
  // for reference
  const imageBgTypes = ['Contentful - JPG', 'Contentful - PNG']
  const videoBgTypes = [
    'Contentful - MP4',
    'External - YouTube',
    'External - Vimeo',
    'Cloudinary - MP4',
    'Animation',
  ]

  let video = null

  const color = {
    type: 'color',
    src: theme === 'dark' ? `${theme}.bg.secondary` : `${theme}.bg.primary`,
  }

  if (background == null) {
    // if we're getting a null arr or obj, default to color
    return color
  } else if (!Array.isArray(background)) {
    let asset = null

    video =
      background?.src &&
      (videoBgTypes.includes(background.src['en-US']) ||
        videoBgTypes.includes(background.src)) &&
      renderVideo({ mediaObj: background, locale })

    const image =
      background?.source && renderImage({ mediaObj: background, locale })

    asset = video || image || color

    return asset
  } else if (videoBgTypes.includes(background[0]?.src)) {
    // if there is a video bg
    video = renderVideo({ mediaObj: background[0] })
    return video
  } else if (imageBgTypes.includes(background[0]?.src)) {
    // if there is an image bg
    return renderImage({ mediaObj: background[0], locale })
  } else {
    // default to color
    return color
  }
}
export const renderChildrenWithPropsOnlyUseExtremelySelectively = ({
  propsObj,
  children,
}) => Children.map(children, child => cloneElement(child, { ...propsObj }))

export const handleSimplifyContentEntryToFields = ({ node, contentType }) => {
  if (!node) {
    return {}
  }
  if (node.data.uri) {
    return {
      href: node.data.uri,
    }
  }
  return node.data.target.fields
}

export const handleFindContentType = ({ node, childrenLength }) => {
  const linkContentTypesArray = ['hyperlink', 'project', 'news', 'page']

  if (!node?.data?.target) {
    return node.nodeType
  } else {
    let cT = node?.data?.target?.sys?.contentType?.sys?.id
    if (childrenLength >= 1 && linkContentTypesArray.includes(cT)) {
      return 'button'
    } else {
      return cT
    }
  }
}
export const handleDetermineModalContentType = ({ node }) => {
  if (!node) {
    return 'text'
  } else if (
    node.data?.target?.fields?.mediaReference?.['en-US']?.sys?.contentType?.sys
      ?.id
  ) {
    return node.data.target.fields.mediaReference['en-US'].sys.contentType.sys
      .id
  }
}

export const getParentPath = ({ childPath, contentType }) => {
  if (!contentType) {
    return childPath
  }
  if (contentType === 'project') {
    return `expansion-timelines/${childPath}`
  }
}

export const handleInternalRoutingSlug = ({ node }) => {
  if (!node) {
    return '/'
  }

  let nestedEntryQuery = node.data?.target?.sys?.contentType?.sys?.id
  let directEntryQuery = node.data?.target?.entry?.contentType

  if (directEntryQuery) {
    return getParentPath({
      childPath: node.data?.target.entry?.fields.slug,
      contentType: directEntryQuery,
    })
  } else if (nestedEntryQuery) {
    return getParentPath({
      childPath: node.data?.target.fields.slug['en-US'],
      contentType: nestedEntryQuery,
    })
  } else return '/'
}

export const handleSrc = ({ targetEntryFields, t }) => {
  const { assetId, type, src, thumbnail, alt, autoPlay } = renderBgTypeAndBg({
    background: targetEntryFields,
    theme: t,
  })
  return { assetId, type, src, thumbnail, alt, autoPlay }
}

export const isValid = (value, input, prod, valType) => {
  const passwords = process.env.GATSBY_LOGIN_PASS_MARKETER?.split(',')
  const accessCodes = process.env.GATSBY_LOGIN_PASS_ACCESS_CODES?.split(',')
  const users = process.env.GATSBY_LOGIN_EMAILS?.split(',')

  switch (valType) {
    case 'Invalid username':
      return users?.includes(input)
    case 'Access Code':
      return prod ? accessCodes?.includes(input) : passwords?.includes(input)
    default:
      return false
  }
}

export const validateInput = (input, validationArr, prod, valType) => {
  const errors = validationArr.filter(v => !isValid(v, input, prod, valType))
  if (errors.length > 0) {
    return `*${errors.join('\n')}`
  }
  return null
}

export const hexToRgb = (hex, opacity) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  const rgb = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
  return rgb && `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity || 1})`
}

export const getLinkedEntry = (arr, targetId) => {
  return arr.find(({ sys: { id } }) => id === targetId)
}

const getAlignmentMapping = alignment => {
  switch (alignment) {
    case 'Top':
    case 'Left':
      return 'flex-start'
    case 'Bottom':
    case 'Right':
      return 'flex-end'
    case 'Center':
    default:
      return 'center'
  }
}

export const parseAlignment = alignment => {
  const parsedAlignment = alignment?.split(' - ')
  return {
    verticalAlignment: getAlignmentMapping(parsedAlignment?.[0]),
    horizontalAlignment: getAlignmentMapping(parsedAlignment?.[1]),
  }
}
export const langObj = {
  'en-US': 'English',
  'es-ES': 'Spanish',
  'es-419': 'Spanish',
  'fr-FR': 'French',
  'fr-CA': 'French',
  'de-DE': 'German',
  'it-IT': 'Italian',
  'ja-JP': 'Japanese',
  'pt-BR': 'Portuguese',
  'ko-KR': 'Korean',
  'sv-SE': 'Swedish',
}

export const createFormValues = (
  environment,
  errorMsg,
  autoLogin,
  accessCode
) => {
  let initVals
  let formValueArr

  if (autoLogin) {
    initVals = {
      initValues: { accessCode },
      validSchema: { accessCode: [`${errorMsg}`] },
      type: { accessCode: 'password' },
      valType: { accessCode: 'Access Code' },
    }
  } else if (environment) {
    initVals = {
      initValues: { accessCode: '' },
      validSchema: { accessCode: [`${errorMsg}`] },
      type: { accessCode: 'password' },
      valType: { accessCode: 'Access Code' },
    }
  } else {
    initVals = {
      initValues: { username: '', accessCode: '' },
      validSchema: {
        username: ['Invalid username'],
        accessCode: [`${errorMsg}`],
      },
      placeholder: {
        username: 'ENTER USERNAME',
        accessCode: 'ENTER ACCESS CODE',
      },
      type: { accessCode: 'password', username: 'text' },
      valType: { accessCode: 'Access Code', username: 'Invalid username' },
    }
  }
  formValueArr = Object.keys(initVals?.initValues)
  return { initVals, formValueArr }
}

export const buildThresholdArr = ratio =>
  Array.from(new Array(1 / ratio), (val, index) => (index + 1) / (1 / ratio))
