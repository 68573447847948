import React, { useState, useEffect } from 'react'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
} from '@common/hooks/usePreviewQuery'
import { useLocale } from '@common/hooks'
import FormGatsbyCDAContainer from './FormGatsbyCDAContainer'
import { parseQueryString } from '@common/utils'

const FormBlockApolloCPAContainer = ({
  formFields = [],
  formButton = [],
  ...rest
}) => {
  const { locale } = useLocale()
  const inputIds = formFields.map(({ sys }) => sys.id)
  const buttonIds = formButton.map(({ sys }) => sys.id)
  const [form, setForm] = useState({})
  const {
    loading: loadingButtons,
    error: errorButtons,
    data: dataButtons,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    skipBool: !buttonIds,
    ids: buttonIds.join(','),
    id: 'formButton',
    locale,
  })
  const {
    loading: loadingInput,
    error: errorInput,
    data: dataInput,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    skipBool: !inputIds,
    ids: inputIds.join(','),
    id: 'inputField',
    locale,
  })

  useEffect(() => {
    if (dataInput && dataButtons) {
      let inputs = dataInput?.entries?.items.filter(({ sys }) =>
        inputIds.includes(sys.id)
      )
      inputs = inputs.map(({ fields, sys }) => ({ ...fields, id: sys.id }))
      let buttons = dataButtons?.entries?.items.filter(({ sys }) =>
        buttonIds.includes(sys.id)
      )

      buttons = buttons.map(({ fields }) => {
        const navLink = dataButtons.entries?.includes?.Entry.filter(
          ({ sys }) => sys.id === fields?.navigationLink?.sys?.id
        )
        const slug =
          navLink &&
          `preview/?t=${parseQueryString('t')}&id=${navLink[0]?.sys?.id}`
        return {
          ...fields,
          navigationLink: { slug },
        }
      })

      const formObj = {
        ...rest,
        formFields: inputs,
        formButton: buttons,
      }
      setForm(formObj)
    }
  }, [dataButtons, dataInput])

  return <FormGatsbyCDAContainer {...form} />
}

export default FormBlockApolloCPAContainer
