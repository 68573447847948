import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledHeroImageHeightContainer,
  StyledHeroImageContainer,
  StyledOverlapHeroContainer,
} from '../styles'
import Image from '@common/components/Image'

const OpaqueHero = ({ heroImage, layout }) => {
  switch (layout) {
    case 'overlap':
      return (
        <StyledOverlapHeroContainer
          width="175px"
          height="auto"
          position="absolute"
          top="0"
          left="50%"
          zIndex="2"
          bg="#18171b"
        >
          <Image
            imgStyle={{ objectFit: 'scale-down' }}
            {...heroImage}
            width="100%"
          />
        </StyledOverlapHeroContainer>
      )
    case 'extra small':
      return (
        <StyledHeroImageHeightContainer
          display="flex"
          alignItems="flex-end"
          width="100%"
        >
          <StyledHeroImageContainer
            height="auto"
            width="100%"
            minWidth="20px"
            maxWidth="60px"
          >
            <Image
              imgStyle={{ objectFit: 'contain' }}
              style={{ objectFit: 'contain' }}
              alt={heroImage?.alt}
              src={heroImage?.src}
              height="40px"
              width="40px"
            />
          </StyledHeroImageContainer>
        </StyledHeroImageHeightContainer>
      )
    default:
      return (
        <StyledHeroImageHeightContainer width="100%" height="auto">
          <StyledHeroImageContainer p={3}>
            <Image
              imgStyle={{ objectFit: 'scale-down' }}
              {...heroImage}
              width="100%"
            />
          </StyledHeroImageContainer>
        </StyledHeroImageHeightContainer>
      )
  }
}

OpaqueHero.propTypes = {}

export default OpaqueHero
