import React, { useContext, useRef } from 'react'
import { ThemeContext } from 'styled-components'
import { useComponentTheme } from '../../hooks'
import { Image } from '../Image'
import CarouselXboxStyledButtons from './components/CarouselXboxStyledButtons'
import {
  StyledText,
  StyledCarouselContainer,
  StyledCarousel,
  StyledCarouselContentContainer,
  StyledArrowContainer,
  StyledImageContainer,
  StyledTextContainer,
} from './styles'

const CarouselXboxGallery = ({
  gallery,
  theme: themeBool = false,
  autoPlay = false,
}) => {
  const carouselSettings = {
    autoplay: autoPlay,
    fade: true,
  }
  const { theme } = useComponentTheme(themeBool)
  const slider = useRef(null)
  return (
    <StyledCarouselContainer height="100vh">
      <StyledCarouselContainer
        width="100vw"
        height="100%"
        position="absolute"
        right="0"
      >
        <StyledArrowContainer position="relative">
          <CarouselXboxStyledButtons slider={slider} theme={theme} />
          <StyledCarousel ref={slider} {...carouselSettings}>
            {gallery?.map(({ name, id, galleryAsset, description }) => {
              return (
                <StyledCarouselContentContainer key={id} textAlign="center">
                  <StyledText> {name} </StyledText>
                  <StyledImageContainer
                    position="relative"
                    minHeight="200px"
                    minWidth="100%"
                  >
                    <Image
                      imgStyle={{ objectFit: 'cover' }}
                      alt={galleryAsset?.title}
                      src={
                        galleryAsset?.source?.fluid
                          ? galleryAsset?.source?.fluid
                          : galleryAsset?.source?.file?.url
                      }
                      height="900px"
                    />
                    <StyledTextContainer
                      position="absolute"
                      top="25%"
                      left="60%"
                    >
                      <StyledText> {name} </StyledText>
                    </StyledTextContainer>
                  </StyledImageContainer>
                </StyledCarouselContentContainer>
              )
            })}
          </StyledCarousel>
        </StyledArrowContainer>
      </StyledCarouselContainer>
    </StyledCarouselContainer>
  )
}

export default CarouselXboxGallery
