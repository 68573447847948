/* eslint-disable react/display-name */
import React from 'react'

export default ({ children }) => (
  <main
    id="main"
    tabIndex="-1"
    style={{ minHeight: 'calc(100vh - 256px)', outline: 'none' }}
  >
    {children}
  </main>
)
