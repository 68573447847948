import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledStepMobile,
  StyledTitle,
  StyledLineMobile,
  StyledTimelineMobile,
  StyledDotMobile,
  StyledInnerDotMobile,
} from './styles'
import { useNProgress, useLocale, useComponentTheme } from '@common/hooks'
import useWindowReady from '../../hooks/useWindowReady.js'

const StepMobile = ({
  homeSlug,
  connectNext,
  step,
  slug,
  theme: themeBool = false,
  last = false,
}) => {
  const { nProgress } = useNProgress()
  const { locale, getLocalizedSlug } = useLocale()
  const localizedSlug = getLocalizedSlug(slug)

  const completed =
    nProgress && nProgress[localizedSlug]
      ? nProgress[localizedSlug].furthestScrollPercentage >= 0.9
      : false

  const visited = nProgress && nProgress.hasOwnProperty(localizedSlug)

  const windowReady = useWindowReady()
  if (!windowReady) return null

  var isCurrent = false
  //  some browsers add a / on end when refreshed
  if (slug === homeSlug) {
    isCurrent =
      window?.location?.pathname === `/${locale}` ||
      `${window?.location?.pathname}${locale}` === `/${locale}` ||
      // for preview
      `${window?.location?.pathname}${window?.location?.search}` ===
        `/${locale}/${homeSlug}` ||
      `${window?.location?.pathname}${window?.location?.search}` ===
        `/${homeSlug}` ||
      `${window?.location?.pathname}${window?.location?.search}` ===
        `${homeSlug}`
  } else {
    isCurrent =
      `/${localizedSlug}` === window?.location?.pathname ||
      `/${localizedSlug}/` === window?.location?.pathname ||
      //  for preview
      `/${localizedSlug}` ===
        `${window?.location?.pathname}${window?.location?.search}` ||
      `/${localizedSlug}/` ===
        `${window?.location?.pathname}${window?.location?.search}`
  }

  return (
    <StyledStepMobile width="100%">
      <StyledTimelineMobile
        display="flex"
        alignItems="center"
        minHeight="100px"
        position="relative"
        mt={2}
      >
        <StyledTitle
          color={isCurrent ? `text.primary` : `text.secondary`}
          width="100%"
          textAlign="left"
          fontSize={'15px'}
          fontWeight={3}
          pl={3}
        >
          {step}
        </StyledTitle>
        <StyledLineMobile
          position="absolute"
          top={0}
          height="100%"
          borderLeft="2px solid"
          zIndex={0}
          style={{ visibility: last ? 'hidden' : 'visible' }}
          borderLeftColor={connectNext ? 'steps.active' : 'steps.inactive'}
        />
        <StyledDotMobile
          position="absolute"
          zIndex={completed ? 1 : 0}
          isCurrent={isCurrent}
          bg={completed || visited ? 'steps.active' : 'steps.inactive'}
        />
        <StyledInnerDotMobile
          position="absolute"
          completed={completed}
          isCurrentCompleted={isCurrent && completed}
          zIndex={completed ? -1 : 1}
          bg="black"
        />
      </StyledTimelineMobile>
    </StyledStepMobile>
  )
}

StepMobile.propTypes = {
  connectNext: PropTypes.bool,
  step: PropTypes.string,
  slug: PropTypes.string,
  theme: PropTypes.bool,
  last: PropTypes.bool,
}

export default StepMobile
