import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  StyledItemContainer,
  StyledImageContainer,
  StyledDownload,
} from './styles'
import Image from '../Image'
import { Button } from '../ButtonAccessible'
import { useBrowser, useMobile } from '@common/hooks'
import usePlatform from '@common/hooks/usePlatform'

const DownloadListItems = ({ alt, itemUrl, thumbnailSrc, buttonText }) => {
  const [isMobile] = useMobile()
  const { browser } = useBrowser()
  const { os } = usePlatform()
  const [downloading, setDownloading] = useState(false)

  const fileExtension = itemUrl?.contentType?.split('/')?.[1]
  const showPreview =
    itemUrl?.contentType === 'application/pdf' && browser === 'Chrome'
  const isMobileDevice = os === 'iOS' || os === 'Android'

  const handleDownload = () => {
    if (showPreview || isMobileDevice) return

    setDownloading(true)

    fetch(`https:${itemUrl?.url}`, {
      method: 'GET',
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = `${alt}.${fileExtension}`
        document.body.appendChild(a)
        a.click()
        a.remove()
        setDownloading(false)
      })
      .catch(() => {
        setDownloading(false)
      })
  }

  return (
    <StyledItemContainer
      isMobile={isMobile}
      py="20px"
      width={isMobile ? '100%' : 'calc(33% - 20px)'}
      height="100%"
    >
      <StyledImageContainer
        display="flex"
        bg="bg.secondary"
        width="100%"
        height="130px"
      >
        <Image
          style={{ objectFit: 'cover' }}
          objectFit="cover"
          alt={alt}
          src={thumbnailSrc}
          maxHeight="100%"
          width="100%"
        />
      </StyledImageContainer>

      <StyledDownload mt={3}>
        <Button
          link={(showPreview || isMobileDevice) && itemUrl}
          tabIndex={showPreview && itemUrl && -1}
          disabled={downloading}
          onClick={handleDownload}
          onKeyDown={e => {
            if (e.key === 'Enter') handleDownload()
          }}
        >
          {buttonText}
        </Button>
      </StyledDownload>
    </StyledItemContainer>
  )
}

DownloadListItems.propTypes = {
  alt: PropTypes.string,
  itemUrl: PropTypes.object,
  thumbnailSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default DownloadListItems
