import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledTransparentBGContainer,
  StyledImageContainer,
  StyledHeroImageContainer,
  StyledContentContainer,
  StyledHeroImageHeightContainer,
  StyledTextContainer,
} from '../styles'
import Image from '../../../Image'
import { TextBlock } from '../../../TextBlock'
import { useMobile } from '@common/hooks'

const renderBasedOnType = ({
  __typename,
  title,
  id,
  isMobile,
  alt,
  ...rest
}) => {
  switch (__typename) {
    case 'ContentfulTextBlock':
      return (
        <StyledTextContainer
          key={id}
          zIndex={1}
          isMobile={isMobile}
          mb={isMobile ? 4 : 4}
        >
          <TextBlock id={id} title={title} {...rest} />
        </StyledTextContainer>
      )
    case 'ContentfulImage':
      return (
        <StyledImageContainer
          my={isMobile && 5}
          key={id}
          display="flex"
          justifyContent="center"
        >
          <StyledImageContainer width="100%" height="auto">
            <Image
              imgStyle={{ objectFit: 'contain' }}
              style={{ objectFit: 'contain' }}
              width="100%"
              maxHeight="600px"
              alt={alt || title}
              {...rest}
            />
          </StyledImageContainer>
        </StyledImageContainer>
      )
  }
}

const ColumnTransparent = ({
  heroImage,
  heroImageLayout = 'large',
  verticalAlignment = 'center',
  contents,
}) => {
  const [isMobile] = useMobile()
  const heroDimensions =
    heroImageLayout === 'extra small'
      ? '40px'
      : heroImageLayout === 'small'
      ? '59px'
      : '100%'

  return (
    <StyledTransparentBGContainer
      display="flex"
      flexDirection="column"
      justifyContent={verticalAlignment}
      height="100%"
      width="100%"
      mb={isMobile && 3}
    >
      {heroImage && (
        <StyledHeroImageHeightContainer
          display="flex"
          py={heroImageLayout === 'large' && 4}
          alignItems={heroImageLayout === 'large' ? 'center' : 'flex-end'}
          justifyContent={heroImageLayout === 'large' && 'center'}
          minHeight={heroImageLayout === 'large' && '100px'}
          width="100%"
        >
          <StyledHeroImageContainer
            height="auto"
            width="100%"
            minWidth={
              heroImageLayout === 'small' || heroImageLayout === 'extra small'
                ? '20px'
                : '100px'
            }
            maxWidth={
              heroImageLayout === 'small' || heroImageLayout === 'extra small'
                ? '60px'
                : '500px'
            }
          >
            <Image
              imgStyle={{ objectFit: 'contain' }}
              style={{ objectFit: 'contain' }}
              alt={heroImage?.alt}
              src={heroImage?.src}
              height={heroDimensions}
              width={heroDimensions}
            />
          </StyledHeroImageContainer>
        </StyledHeroImageHeightContainer>
      )}
      <StyledContentContainer display="flex" flexDirection="column">
        {contents?.map(content => renderBasedOnType({ isMobile, ...content }))}
      </StyledContentContainer>
    </StyledTransparentBGContainer>
  )
}

ColumnTransparent.propTypes = {
  heroImageLayout: PropTypes.string,
  verticalAlignment: PropTypes.string,
  heroImage: PropTypes.shape({
    id: PropTypes.string,
    scale: PropTypes.string,
    source: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
      fluid: PropTypes.object,
      localFile: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.object,
        }),
      }),
    }),
  }),
  content: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.shape({
        body: PropTypes.string,
      }),
      id: PropTypes.string,
      themes: PropTypes.string,
      title: PropTypes.string,
    })
  ),
}

export default ColumnTransparent
