import React from 'react'
import { useMobile } from '../../hooks'
import NavigationDesktop from './NavigationDesktop'
import NavigationMobile from './NavigationMobile'

const Navigation = ({ mobileOnly = false, ...rest }) => {
  const [isMobile] = useMobile()

  if (isMobile === undefined) return null // isMobile === undefined for SSR
  if (!mobileOnly && isMobile === false) return <NavigationDesktop {...rest} />
  return <NavigationMobile {...rest} />
}

export default Navigation
