import React from 'react'
import { PropTypes } from 'prop-types'
import { ErrorMessage } from 'formik'
import { Container, StyledMessage } from '../style'
import InputField from './InputField'
import { useErrorFocus } from '../../../hooks'

const StatusMessage = ({ children, success, id }) => {
  return (
    <StyledMessage
      color={success ? 'green' : 'red'}
      role="status"
      fontSize={[12]}
      id={!success ? `${id}-error` : 0}
    >
      {children}
    </StyledMessage>
  )
}

const FormController = ({
  type,
  id,
  successMessage,
  touched,
  errors,
  ...rest
}) => {
  useErrorFocus()
  const inputType = type.charAt(0).toLowerCase() + type.slice(1)
  const createInputField = type => {
    // extend by adding more input types ie) text area etc
    switch (type) {
      case 'Text':
      case 'Password':
      case 'Reset':
      case 'Number':
      case 'Email':
      case 'Tel':
        return (
          <InputField
            type={type}
            id={id}
            inputType={inputType}
            touched={touched}
            errors={errors}
            {...rest}
          />
        )
      default:
        return null
    }
  }
  return (
    <Container width={[1]}>
      {type && createInputField(type)}
      {touched[id] && !errors[id] && successMessage && (
        <StatusMessage success>{successMessage}</StatusMessage>
      )}
      <ErrorMessage name={id}>
        {msg => <StatusMessage id={id}>{msg}</StatusMessage>}
      </ErrorMessage>
    </Container>
  )
}

FormController.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  touched: PropTypes.object,
  errors: PropTypes.object,
}

export default FormController
