import styled from 'styled-components'
import { Field, Form } from 'formik'
import {
  space,
  color,
  layout,
  typography,
  border,
  flexbox,
} from 'styled-system'

export const StyledField = styled(Field)`
  border-style: none none solid none;
  box-sizing: border-box;
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}

`

export const StyledMessage = styled.div`
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
`

export const StyledButton = styled.button`
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  cursor: pointer;
  white-space: nowrap;
   text-align: center;
`

export const Container = styled.div`
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  box-sizing: border-box;
`

export const H3 = styled.h3`
  ${color}
  ${layout}
  ${typography}
  ${space}
`

export const StyledForm = styled(Form)`
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  input:-webkit-autofill {
    background-color: black !important;
  }
`

export const ButtonContainer = styled(Container)``
