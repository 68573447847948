import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import BackgroundImage from 'gatsby-background-image'
import { StyledSection, StyledContainer } from '../styles'
import VideoScrollAnimation from '@common/components/VideoScrollAnimation'
import Video from '@common/components/Video'
import { useMediaQuery, useMobile } from '@common/hooks'

const BackgroundGradientImage = ({
  children,
  backgroundGradient,
  backgroundImage,
  backgroundSize = 'Default',
  fullWidth,
}) => {
  let xl = useMediaQuery(`(min-width: 1608px)`)
  const [isMobile] = useMobile()
  const getBackgroundSize = () => {
    switch (backgroundSize) {
      case 'Cover':
        return 'cover'
      case 'Contain':
        return 'contain'
      case 'Always Cover on Desktop':
        return isMobile || fullWidth ? 'contain' : 'cover'
      case 'Default':
      default:
        return xl || fullWidth ? 'cover' : 'contain'
    }
  }
  return (
    <BackgroundImage
      Tag="section"
      minHeight="100%"
      fluid={
        backgroundGradient && backgroundImage
          ? [backgroundGradient, backgroundImage]
          : backgroundGradient
          ? [backgroundGradient]
          : backgroundImage
      }
      style={{
        backgroundSize: getBackgroundSize(),
        backgroundPosition: 'center top',
      }}
      alt={backgroundImage?.alt}
    >
      {children}
    </BackgroundImage>
  )
}

const BackgroundVideo = ({ children, videoFit, videoFitMobile, ...rest }) => {
  const [isMobile] = useMobile()
  const [height, setHeight] = useState('400')

  const [ref, setRef] = useState(null)

  const getRef = useCallback(node => {
    if (node) {
      setRef(node)
    }
  }, [])

  useEffect(() => {
    setHeight(ref?.clientHeight)
  }, [ref])

  useEffect(() => {
    const updateHeight = () => {
      if (ref?.clientHeight !== height) setHeight(ref?.clientHeight)
    }
    window.addEventListener('resize', updateHeight)

    return () => {
      window.removeEventListener('resize', updateHeight)
    }
  }, [height, ref])

  return (
    <Video
      {...rest}
      minHeight={height}
      videoFit={isMobile ? videoFitMobile : videoFit}
      showBackgroundBtn
    >
      {React.cloneElement(children, { ref: getRef })}
    </Video>
  )
}

const renderVideoBasedOnBehavior = ({
  behavior,
  children,
  paddingBottom,
  paddingTop,
  ...rest
}) => {
  switch (behavior) {
    case 'Animate on Scroll':
      return (
        <VideoScrollAnimation
          {...rest}
          paddingBottom={paddingBottom}
          paddingTop={paddingTop}
        >
          {children}
        </VideoScrollAnimation>
      )
    case 'Continuous Loop':
      return (
        <BackgroundVideo {...rest} controls={false} loop autoplay>
          {children}
        </BackgroundVideo>
      )
    case 'Autoplay Once':
    default:
      return (
        <BackgroundVideo
          {...rest}
          controls={false}
          loop={false}
          autoplay
          renderImageAfterSinglePlay
        >
          {children}
        </BackgroundVideo>
      )
  }
}

const BackgroundWrapper = ({
  backgroundColor,
  backgroundImage,
  backgroundGradient,
  backgroundVideo,
  backgroundVideoBehavior,
  backgroundSize,
  children,
  minHeight,
  backgroundType,
  fullWidth,
  contentHeight,
  paddingTop,
  paddingBottom,
}) => {
  switch (backgroundType) {
    case 'ContentfulColor':
      return <StyledSection bg={backgroundColor}>{children}</StyledSection>
    case 'ContentfulGradient':
    case 'ContentfulImage':
      return (
        <BackgroundGradientImage
          backgroundImage={backgroundImage}
          backgroundGradient={backgroundGradient}
          backgroundSize={backgroundSize}
          fullWidth={fullWidth}
        >
          {children}
        </BackgroundGradientImage>
      )
    case 'ContentfulMediaVideo':
      return renderVideoBasedOnBehavior({
        behavior: backgroundVideoBehavior,
        children,
        contentHeight,
        ...backgroundVideo,
        minHeight,
        paddingTop,
        paddingBottom,
      })
    default:
      return null
  }
}

BackgroundWrapper.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.object,
  backgroundGradient: PropTypes.string,
}

export default BackgroundWrapper
