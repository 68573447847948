import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledText,
  StyledDropdownItem,
  StyledIcon,
  StyledListItem,
  LocaleContainer,
  Placeholder,
} from '../styles'
import check from '../../../assets/check.svg'

const LocaleItem = ({ id, currentLocale, code, name }) => {
  return (
    <>
      <StyledListItem role="menuitem">
        <LocaleContainer display="flex" alignItems="center">
          {currentLocale.id === id ? (
            <StyledIcon px={[1]} src={check} />
          ) : (
            <Placeholder width={14} height={21} px={[1]} />
          )}

          <StyledDropdownItem
            to={code === 'en-US' ? '/' : code}
            px={2}
            py={2}
            display="block"
          >
            <StyledText
              fontSize={18}
              color={currentLocale.id === id ? 'white' : 'dark.text.secondary'}
            >
              {name}
            </StyledText>
          </StyledDropdownItem>
        </LocaleContainer>
      </StyledListItem>
    </>
  )
}

LocaleItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string,
  currentLocale: PropTypes.object,
}

export default LocaleItem
