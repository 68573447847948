import React from 'react'
import Video from '../Video'
import Image from '../Image/Image'
import { ModalCloseButton, Container } from './style'
import { useLockScroll } from '../../hooks'
import closeBtn from './assets/close-btn.png'

const Modal = ({ theme, type, content, modal }) => {
  useLockScroll()
  return (
    <Container
      role="dialog"
      aria-modal="true"
      tabIndex="-1"
      bg={`${theme}.bg.primary`}
      aria-label={`${content?.title} modal`}
      maxWidth="1080px"
      height="auto"
    >
      <ModalCloseButton
        aria-label="Close Modal"
        role="button"
        onClick={modal?.closePortal}
        bg={`black`}
        border={0}
        borderRadius="50%"
        m={0}
        p={0}
        aria-expanded
      >
        <img src={closeBtn} height="auto" width="100%" alt="close modal" />
      </ModalCloseButton>
      {type === 'mediaVideo' ? (
        <Video
          src={content?.src}
          theme={theme}
          title={content?.title}
          captions={content?.captions}
          thumbnail={content?.thumbnail}
          modal={modal}
        />
      ) : (
        <Image src={content.src} alt={content.title} width="100%" />
      )}
    </Container>
  )
}
export default Modal
