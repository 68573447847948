import React, { useEffect, useState, useCallback } from 'react'
import ProgressStepper from '@common/components/ProgressStepper'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
} from '@common/hooks/usePreviewQuery'
import { parseQueryString } from '@common/utils'
import { useLocale } from '@common/hooks'

const ProgressStepperApolloCPAContainer = props => {
  const { locale } = useLocale()
  const {
    loading: loadingNavItems,
    error: errorNavItems,
    data: dataNavItems,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'navigation',
    locale,
  })

  const [navItems, setNavItems] = useState([])

  const getLinkedEntry = (arr, targetId) => {
    return arr.find(({ sys: { id } }) => id === targetId)
  }

  const getMappedSubnavLink = useCallback((subnavLink, entryIncludes) => {
    const linkedSubnav =
      subnavLink && getLinkedEntry(entryIncludes, subnavLink?.sys?.id)
    return (
      linkedSubnav && {
        id: linkedSubnav?.sys?.id,
        ...linkedSubnav?.fields,
        slug: `preview/?t=${parseQueryString('t')}&id=${linkedSubnav?.sys?.id}`,
      }
    )
  }, [])

  const getMappedSubnavItems = useCallback(
    (subnavItems, entryIncludes) => {
      const items = subnavItems
        ?.map(({ sys: { id } }) => getLinkedEntry(entryIncludes, id))
        ?.map(({ fields, sys }) => ({ ...fields, id: sys.id }))
        ?.map(({ anchor, navigationLink, ...rest }) => ({
          navigationLink: getMappedSubnavLink(navigationLink, entryIncludes),
          ...rest,
        }))
      return items
    },
    [getMappedSubnavLink]
  )

  const getMappedNavItems = useCallback(
    (data, entryIncludes) => {
      const navItemIds = data?.map(({ sys: { id } }) => id)

      const navItems = navItemIds
        ?.map(id => getLinkedEntry(entryIncludes, id))
        ?.map(({ fields, sys }) => ({ ...fields, id: sys.id }))

      const mappedNavItems = navItems.map(
        ({
          navigationLink: {
            sys: { id },
          },
          subnavigationItems,
          ...rest
        }) => {
          const linkedEntry = getLinkedEntry(entryIncludes, id)?.fields
          const mappedSubnavigationItems = getMappedSubnavItems(
            subnavigationItems,
            entryIncludes
          )

          return {
            navigationLink: {
              id,
              slug: `preview/?t=${parseQueryString('t')}&id=${id}`,
              url: linkedEntry?.url,
              displayText: linkedEntry?.title,
              __typename: linkedEntry?.slug
                ? 'ContentfulPage'
                : 'ContentfulExternalLink',
            },
            subnavigationItems: mappedSubnavigationItems,
            ...rest,
          }
        }
      )

      return mappedNavItems
    },
    [getMappedSubnavItems]
  )

  useEffect(() => {
    if (!loadingNavItems && !errorNavItems && dataNavItems) {
      const {
        entries: {
          includes: { Entry },
          items,
        },
      } = dataNavItems

      const filteredNavData = items.filter(
        ({ fields: { placement } }) => placement === 'Global Header'
      )?.[0]?.fields

      const filteredNavItems = filteredNavData?.navigationItems
      const backgroundColor = filteredNavData?.backgroundColor

      let mappedNavItems = getMappedNavItems(filteredNavItems, Entry)
      setNavItems({ mappedNavItems, backgroundColor })
    }
  }, [errorNavItems, loadingNavItems, dataNavItems, getMappedNavItems])

  const homeSlug = '/preview/?t=xbox&id=4c4o2bt9cRrhdKVqGZlnlO'

  return (
    <ProgressStepper
      steps={navItems?.mappedNavItems}
      homeSlug={homeSlug}
      {...props}
    />
  )
}

export default ProgressStepperApolloCPAContainer
