import React from 'react'
import {
  NavigationGatsbyCDAContainer,
  NavigationApolloCPAContainer,
} from '../../containers/NavigationContainer'

const Navigation = ({ preview, ...rest }) => {
  const NavigationContainer = preview
    ? NavigationApolloCPAContainer
    : NavigationGatsbyCDAContainer

  return <NavigationContainer type="simple" {...rest} />
}

export default Navigation
