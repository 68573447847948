import React from 'react'
import PropTypes from 'prop-types'
import { StyledStatContainer, StyledStat, StyledDescription } from './styles.js'

const StatItem = ({ statistic, description, length, isMobile }) => {
  return (
    <StyledStatContainer
      display="flex"
      flexDirection="column"
      width="10%"
      mr={length <= 2 && !isMobile ? '8vw' : '0'}
      mb={isMobile && '8vh'}
    >
      <StyledStat fontSize="40px" m="0" width="100%">
        {statistic}
      </StyledStat>
      <StyledDescription fontSize="15px" color="#474747" width="100%">
        {description?.description}
      </StyledDescription>
    </StyledStatContainer>
  )
}

StatItem.propTypes = {
  statistic: PropTypes.string,
  description: PropTypes.shape({
    description: PropTypes.string,
  }),
  length: PropTypes.number,
  isMobile: PropTypes.bool,
}

export default StatItem
