import React from 'react'
import ModalWrapper from '../../components/Modal/ModalWrapper'

const ModalGatsbyCDAContainer = ({ theme, modal, ctaText, icon }) => {
  let { captions, cloudinaryVideoSrc, thumbnail, type, source, title } =
    modal || {}

  type = type === 'ContentfulMediaVideo' ? 'mediaVideo' : 'image'

  const videoContent = {
    title,
    captions: captions?.file?.url,
    src: cloudinaryVideoSrc?.[0]?.original_secure_url,
    thumbnail: thumbnail?.file?.url,
  }
  const imageContent = {
    title,
    src: source?.file?.url,
  }

  const content = type === 'mediaVideo' ? videoContent : imageContent

  return (
    <ModalWrapper
      theme={theme}
      type={type}
      ctaText={ctaText}
      content={content}
      icon={icon}
    />
  )
}
export default ModalGatsbyCDAContainer
