import React from 'react'
import { BaseButton, BaseA } from './styles'
import { useButton } from '@react-aria/button'
import { useLocale } from '@common/hooks/index.js'
import openBtn from '../../assets/green-playbutton.png'
import { Link } from 'gatsby'

const LocalizedLink = ({ link, children }) => {
  const { localeSlug, getLocalizedSlug } = useLocale()
  let slug = link?.slug

  if (!slug) {
    const location = window.location.pathname
    const anchorId = link?.anchorTagId || (link?.id && `section-${link?.id}`)
    slug = `${anchorId ? `${location}#${anchorId}` : ''}`
  } else {
    slug = getLocalizedSlug(slug)
  }

  let sanitizedSlug = slug[0] !== '/' ? `/${slug}` : slug
  sanitizedSlug = sanitizedSlug.replace('/home', '/')
  if (sanitizedSlug === `/${localeSlug}/`) sanitizedSlug = `/${localeSlug}`

  const handleAnchor = () => {
    const [, anchor] = sanitizedSlug?.split('#')
    if (anchor) {
      const blade = document.querySelector(`#${anchor}`)
      if (blade) {
        blade.scrollIntoView({ behavior: 'smooth' })
        blade.focus({ preventScroll: true })
      }
    }
  }

  return (
    <Link
      data-testid="internal-btn"
      role="link"
      to={sanitizedSlug}
      onClick={handleAnchor}
    >
      {children}
    </Link>
  )
}

const handleButtonOrLinkWrapping = (Component, props) => {
  const { link, target, children, disabled, isLoading, ...rest } = props

  const ui = (
    <Component disabled={disabled || isLoading} {...rest}>
      {children}
    </Component>
  )

  if (!props.children) return null

  if (
    link?.slug ||
    link?.anchorTagId ||
    link?.__typename === 'ContentfulBlade'
  ) {
    return <LocalizedLink link={link}>{ui}</LocalizedLink>
  }

  if (link?.url) {
    return (
      <BaseA
        href={link.url}
        target={target || '_blank'}
        rel={!target ? 'noopener noreferrer' : undefined}
        data-testid="external-link"
        role="link"
        color="transparent"
        fontWeight={400}
        fontFamily="Segoe UI"
        fontSize="15px"
        letterSpacing=".05rem"
        padding="12px 0px"
      >
        {ui}
      </BaseA>
    )
  }

  return ui
}

const ButtonAccessible = props => {
  let ref = React.useRef()
  let { buttonProps } = useButton(props, ref)
  let { children } = props

  return (
    <BaseButton
      {...buttonProps}
      {...props}
      ref={ref}
      bg="transparent"
      color="white"
      fontWeight={700}
      border="1px solid #ffffff80"
      fontFamily="headings"
      fontSize="15px"
      letterSpacing=".05rem"
      padding="12px 20px"
    >
      {children}
    </BaseButton>
  )
}

const IconButtonAccessible = ({
  children,
  link,
  icon,
  openPortal = () => {},
  ...rest
}) => {
  const handleKeyDown = e => {
    if (e.key === 'Enter') openPortal()
  }
  return (
    <ButtonAccessible onClick={openPortal} onKeyDown={handleKeyDown} {...rest}>
      <img aria-hidden="true" src={openBtn} height="auto" alt="play video" />
      {children}
    </ButtonAccessible>
  )
}

const NakedA = props => {
  return (
    <>
      {props.children}
      <b style={{ paddingLeft: '5px' }}>></b>
    </>
  )
}

export const A = props => handleButtonOrLinkWrapping(NakedA, props)
export const Button = props =>
  handleButtonOrLinkWrapping(ButtonAccessible, props)
export const IconButton = props =>
  handleButtonOrLinkWrapping(IconButtonAccessible, props)
