import React from 'react'
import Nav from '@common/components/Navigation'
import { useStaticQuery, graphql } from 'gatsby'
import { hexToRgb } from '@common/utils'
import { useLocale } from '@common/hooks'

const NavigationGatsbyCDAContainer = props => {
  const { header, siteInfo } = useStaticQuery(graphql`
    query HeaderQuery {
      header: allContentfulNavigation(
        filter: { placement: { eq: "Global Header" } }
      ) {
        nodes {
          ...navigationFields
        }
      }
      siteInfo: allContentfulSiteInformationAndBranding {
        nodes {
          ...siteInfoFields
        }
      }
    }
  `)

  const { getLocalizedFields } = useLocale()
  const localizedHeader = getLocalizedFields(header?.nodes)

  const navigationItems = !props.loginPage && localizedHeader?.navigationItems
  const backgroundColor = hexToRgb(
    header?.nodes?.[0]?.background?.hex,
    header?.nodes?.[0]?.background?.opacity
  )
  const logo = siteInfo.nodes?.[0]?.logo

  return (
    <Nav
      navItems={navigationItems}
      backgroundColor={backgroundColor}
      logo={logo}
      {...props}
    />
  )
}

export default NavigationGatsbyCDAContainer
