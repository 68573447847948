import React from 'react'
import PropTypes from 'prop-types'
import { StyledStatBlock } from './styles.js'
import StatItem from './StatItem'
import { useMobile } from '@common/hooks/index.js'

const StatsBlock = ({ stats }) => {
  const length = stats?.length
  const [isMobile] = useMobile()
  return (
    <StyledStatBlock
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent={length <= 2 ? 'start' : 'space-between'}
      alignItems={isMobile ? 'center' : 'stretch'}
      mt={4}
      mb={4}
    >
      {stats?.map((stat, i) => (
        <StatItem
          key={`${stat.id}${i}`}
          {...stat}
          length={length}
          isMobile={isMobile}
        />
      ))}
    </StyledStatBlock>
  )
}

StatsBlock.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.shape({
        description: PropTypes.string,
      }),
      id: PropTypes.string,
      name: PropTypes.string,
      statistic: PropTypes.string,
    })
  ),
}

export default StatsBlock
