import React from 'react'
import { PropTypes } from 'prop-types'
import { useComponentTheme } from '../../hooks'
import { Formik } from 'formik'
import { H3, Container, StyledForm, ButtonContainer } from './style'
import FormButton from './components/FormButton'
import FormController from './components/FormController.js'

const FormikContainer = ({
  theme: themeBool = true,
  formButton = [],
  formFields = [],
  title,
  formValues = {},
  onSubmit,
  validate,
}) => {
  const { theme, invertedTheme } = useComponentTheme(themeBool)
  const { initValues } = formValues
  return (
    <Container width={[1]} bg={`${theme}.bg.primary`}>
      <H3 fontSize={[6]} m={0} color={`${theme}.text.primary`}>
        {title}
      </H3>
      <Formik
        initialValues={initValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, ...rest }) => (
          <StyledForm width={[1]} role="form" aria-labelledby={title}>
            {formFields.map((field, i) => (
              <FormController key={`${i} + ${field.id}`} {...field} {...rest} />
            ))}
            <ButtonContainer display="flex" justifyContent="flex-end" mt={3}>
              {formButton.map(
                ({ displayText, navigationLink, buttonType }, i) => (
                  <FormButton
                    key={`${i} + ${displayText}`}
                    theme={invertedTheme}
                    displayText={displayText}
                    isSubmitting={isSubmitting}
                    slug={navigationLink}
                    type={buttonType}
                  />
                )
              )}
            </ButtonContainer>
          </StyledForm>
        )}
      </Formik>
    </Container>
  )
}

FormikContainer.propTypes = {
  theme: PropTypes.bool,
  title: PropTypes.string,
  formButton: PropTypes.arrayOf(
    PropTypes.shape({
      buttonType: PropTypes.string,
      displayText: PropTypes.string,
      navigationLink: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      placeholder: PropTypes.string,
      title: PropTypes.string,
      successMessage: PropTypes.string,
      type: PropTypes.string,
      validation: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  formValues: PropTypes.shape({
    initValues: PropTypes.object,
    validSchema: PropTypes.object,
  }),
  validate: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default FormikContainer
