import React from 'react'
import PropTypes from 'prop-types'
import Table from './Table'
import { Container, TitleContainer, Eyebrow, H3, IconOverlay } from './style'
import { Image } from '../Image'

const TableBlock = ({ tables, eyebrow, title, icon }) => {
  return (
    <Container width={[1]} my={[5]} fontFamily="headings">
      <TitleContainer mb={[5]}>
        {icon && (
          <Image
            src={
              icon?.[0]?.source?.fluid
                ? icon?.[0]?.source?.fluid.src
                : icon?.[0]?.source?.file?.url
            }
            alt={icon?.[0]?.title}
            width="80px"
          />
        )}
        <Eyebrow m={0} pt={[4]} color="text.secondary">
          {eyebrow}
        </Eyebrow>
        <H3 m={0} fontWeight="regular" fontSize={[3]}>
          {title}
        </H3>
      </TitleContainer>
      {tables?.map((t, i) => (
        <Table key={`${t?.id} + ${i}`} {...t} />
      ))}
    </Container>
  )
}

TableBlock.propTypes = {
  tables: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      tableEntries: PropTypes.shape({
        tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      }),
    })
  ),
  eyebrow: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      scale: PropTypes.string,
      source: PropTypes.object,
      title: PropTypes.string,
      __typename: PropTypes.string,
    })
  ),
}

export default TableBlock
