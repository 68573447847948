import React from 'react'
import PropTypes from 'prop-types'
import GatsbyImage from 'gatsby-image'
import { StyledGatsbyImage, StyledImg } from './styles'

const Image = ({ alt, src, width, height, opacity, ...rest }) => {
  const props = {
    alt,
    ...rest,
  }

  if (!src) {
    return null
  } // TODO: could be a little more robust here

  // if src is a string ? vanilla <img /> : is Gatsby obj
  const isGatsby = typeof src !== 'string'

  // determine gatsby fixed vs gatsby fluid vs vanilla src string. only fixed as width/height
  const keyForSrc =
    (isGatsby && src.width && src.height && 'fixed') ||
    (isGatsby && 'fluid') ||
    'src'

  // Now set either src, fixed or fluid to the src prop
  props[keyForSrc] = src

  // soften gatsby's default blur-up by adding CSS blur to the image
  // with styled-components. don't add extra CSS blur to tracedSVG
  const Component = src.tracedSVG ? GatsbyImage : StyledGatsbyImage

  // return GatsbyImage component or a regular img tag with our spread props
  return isGatsby ? (
    <Component {...props} width={keyForSrc === 'fluid' && width} />
  ) : (
    <StyledImg
      width={width}
      alt={alt}
      style={opacity && { opacity: '50%' }}
      {...props}
    />
  )
}

Image.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.string,
}

export default Image
