import React, { useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { StyledVideo, Container, FadeInText } from './style'
import { useBrowser, useMobile } from '@common/hooks'
import usePlatform from '@common/hooks/usePlatform'

const VideoScrollAnimation = ({ src, paddingTop, paddingBottom, children }) => {
  const { browser } = useBrowser()
  const { os } = usePlatform()
  const [isMobile] = useMobile()
  const minPadding = isMobile ? 50 : 100

  const ACCELERATION = browser === 'Firefox' ? 0.05 : 0.08
  const FPS = 10
  const PIXELS_PER_FRAME = 35
  const EASING = 0.85

  const containerRef = useRef()
  const videoRef = useRef()
  const textRef = useRef()
  const requestId = useRef()
  const targetScrollPos = useRef(0)
  const scrollPos = useRef(0)
  const scrollTimer = useRef()
  const lastScrollFireTime = useRef(0)

  // FF algorithm
  const handleScrollFF = () => {
    if (containerRef.current && videoRef.current) {
      const containerStart =
        containerRef.current.offsetTop - window.innerHeight * EASING
      const height = containerRef.current.clientHeight - window.innerHeight

      const videoPercentage = (window.pageYOffset - containerStart) / height
      const trimmedVideoPercentage = Math.min(Math.max(videoPercentage, 0), 1)

      const duration = videoRef.current.duration || 1000
      const frames = duration * FPS
      const currentFrame = Math.round(frames * trimmedVideoPercentage)
      const currentTime = currentFrame / FPS
      videoRef.current.currentTime = currentTime
    }
  }

  const throttle = useCallback(() => {
    const minScrollTime = 30
    const now = new Date().getTime()
    if (!scrollTimer.current) {
      const elapsedTime = now - lastScrollFireTime.current
      const threshold = elapsedTime > minScrollTime
      if (threshold) {
        handleScrollFF() // fire immediately on first scroll
        lastScrollFireTime.current = now
      }

      scrollTimer.current = setTimeout(() => {
        scrollTimer.current = null
        lastScrollFireTime.current = new Date().getTime()
        handleScrollFF()
      }, minScrollTime)
    }
  }, [])

  // Default Algorithm
  const scrollHandler = useCallback(() => {
    targetScrollPos.current = window.pageYOffset
    start()
  }, [])

  const scrollControl = useCallback(() => {
    if (containerRef.current && videoRef.current) {
      scrollPos.current +=
        (targetScrollPos.current - scrollPos.current) * ACCELERATION

      const containerStart =
        containerRef.current.offsetTop - window.innerHeight * EASING
      const height = containerRef.current.clientHeight

      const videoPercentage = (scrollPos.current - containerStart) / height
      const trimmedVideoPercentage = Math.min(Math.max(videoPercentage, 0), 1)

      const duration = videoRef.current.duration || 1000
      const frames = duration * FPS
      const currentFrame = Math.round(frames * trimmedVideoPercentage)
      const currentTime = currentFrame / FPS
      videoRef.current.currentTime = currentTime

      return Math.round(scrollPos.current) !== targetScrollPos.current
    }
  }, [ACCELERATION, isMobile])

  const loop = useCallback(() => {
    requestId.current = undefined

    if (scrollControl()) {
      start()
    } else {
      stop()
    }
  }, [scrollControl])

  const start = useCallback(() => {
    if (!requestId.current) {
      requestId.current = window.requestAnimationFrame(loop)
    }
  }, [loop])

  const stop = () => {
    if (requestId.current) {
      window.cancelAnimFrame(requestId.current)
      requestId.current = undefined
    }
  }

  useEffect(() => {
    scrollPos.current = window.pageYOffset
    targetScrollPos.current = window.pageYOffset

    if (videoRef.current) {
      videoRef.current.pause()
      videoRef.current.addEventListener('loadedmetadata', () => {
        // height of the video container
        if (containerRef.current) {
          containerRef.current.style.height =
            Math.max(
              Math.floor(videoRef.current.duration) * FPS * PIXELS_PER_FRAME,
              2000
            ) + 'px'
        }
      })
    }

    window.requestAnimFrame =
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.oRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function(callback) {
        window.setTimeout(callback, 1000 / 60)
      }
    window.cancelAnimFrame =
      window.cancelAnimationFrame || window.mozCancelAnimationFrame

    window.addEventListener(
      'scroll',
      browser === 'FireFox' || os === 'Windows' ? throttle : scrollHandler,
      false
    )
    return () => {
      window.removeEventListener(
        'scroll',
        browser === 'FireFox' || os === 'Windows' ? throttle : scrollHandler
      )
    }
  }, [browser, os, scrollControl, scrollHandler, throttle])

  return (
    <>
      <Container ref={containerRef} width="100%" margin="0px auto">
        <Container position="sticky" top={['0', '0', '61px']} height="100vh">
          <StyledVideo
            ref={videoRef}
            preload="metadata"
            muted
            playsinline
            zIndex="-10"
            height="100vh"
          >
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </StyledVideo>
        </Container>
      </Container>
      <FadeInText
        className="fadeInText"
        ref={textRef}
        position={isMobile ? 'static' : 'static'}
        bottom="0"
        bladePaddingTop={`calc(${paddingTop || 0}%)`}
        bladePaddingBottom={`calc(${paddingBottom || 0}% + ${minPadding}px)`}
      >
        {children}
      </FadeInText>
    </>
  )
}
export default VideoScrollAnimation

VideoScrollAnimation.propTypes = {
  id: PropTypes.string,
  src: PropTypes.string,
}
