import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Container } from './style'
import playBtn from '@common/assets/icon_play.svg'
import pauseBtn from '@common/assets/icon_pause.svg'

const BackgroundButton = ({ player, setPauseOverride }) => {
  const [isPlaying, setIsPlaying] = useState(true)

  const toggle = action => {
    if (action === 'play') {
      player.play()
      setPauseOverride(false)
    } else {
      player.pause()
      setPauseOverride(true)
    }
    setIsPlaying(isPlaying => !isPlaying)
  }

  const handleKeyDown = (e, action) => {
    if (e.key === 'Enter') {
      toggle(action)
    }
  }

  return (
    <Container
      position="relative"
      zIndex={100}
      cursor="pointer"
      width="fit-content"
    >
      {isPlaying ? (
        <img
          src={pauseBtn}
          alt="pause-button"
          aria-label="pause-button"
          role="button"
          tabIndex={0}
          onClick={() => toggle('pause')}
          onKeyDown={e => handleKeyDown(e, 'pause')}
        />
      ) : (
        <img
          src={playBtn}
          alt="play-button"
          aria-label="play-button"
          role="button"
          tabIndex={0}
          onClick={() => toggle('play')}
          onKeyDown={e => handleKeyDown(e, 'play')}
        />
      )}
    </Container>
  )
}

BackgroundButton.propTypes = {}

export default BackgroundButton
