import React from 'react'
import Step from './Step'
import PropTypes from 'prop-types'
import { StyledStepper } from './styles'

const ProgressStepperDesktop = ({ steps, shouldConnectNext, ...rest }) => {
  return (
    <StyledStepper
      position="relative"
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
      width="100vw"
      maxWidth="113%"
      overflow="hidden"
      height="50px"
      my={5}
    >
      {steps?.map(({ id, displayText, navigationLink }, i, arr) => (
        <Step
          key={`${id}` + 'step'}
          step={displayText}
          slug={navigationLink?.slug}
          connectNext={shouldConnectNext(steps, i)}
          last={arr.length === i + 1}
          {...rest}
        />
      ))}
    </StyledStepper>
  )
}

ProgressStepperDesktop.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      displayText: PropTypes.string,
      id: PropTypes.string,
      navigationLink: PropTypes.shape({
        displayTextAlt: PropTypes.string,
        __typename: PropTypes.string,
        slug: PropTypes.string,
      }),
    })
  ),
}

export default ProgressStepperDesktop
