import React, { useEffect } from 'react'
// import PropTypes from 'prop-types'
import { StyledContainer } from '../Navigation/styles'
import { useScrollPosition, useNProgress, useMobile } from '../../hooks'
import { StyledProgressBar } from './styles'

const NProgress = ({ theme = 'dark', id, scrollToCachedPosition = false }) => {
  const { nProgress, update, addKey } = useNProgress()
  const [isMobile] = useMobile()

  // const cachedScrollPosition = nProgress?.[id]?.furthestScrollPosition || 0
  const {
    scrollPosition,
    percentOfPageHeight,
    furthestScrollPosition,
  } = useScrollPosition()

  useEffect(() => {
    addKey(id)
  }, [addKey, id, nProgress])

  // useEffect(() => {
  //   if (scrollToCachedPosition && !window?.location?.hash) {
  //     setTimeout(() => {
  //       window.scrollTo({
  //         top: cachedScrollPosition,
  //         behavior: 'smooth',
  //       })
  //     }, 200)
  //   }
  // }, [])

  useEffect(() => {
    if (furthestScrollPosition > nProgress?.[id]?.furthestScrollPosition) {
      update(id, {
        furthestScrollPosition,
        furthestScrollPercentage: percentOfPageHeight,
      })
    }
  }, [
    id,
    furthestScrollPosition,
    nProgress,
    scrollPosition,
    update,
    percentOfPageHeight,
  ])

  return (
    <StyledContainer
      width="100%"
      height={['3px', '3px', '2px']}
      bg={`${theme}.border.primary`}
      my={1}
    >
      <StyledProgressBar
        width={`${percentOfPageHeight * 100}%`}
        height={isMobile ? '3px' : '2px'}
        bg={`${theme}.border.secondary`}
      />
    </StyledContainer>
  )
}

NProgress.propTypes = {}

export default NProgress
