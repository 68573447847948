import React from 'react'
import ProgressStepperDesktop from './ProgressStepperDesktop'
import ProgressStepperMobile from './ProgressStepperMobile'
import PropTypes from 'prop-types'
import { useMobile, useLocale, useNProgress } from '@common/hooks'

const ProgressStepper = ({ ...rest }) => {
  const [isMobile] = useMobile()
  const { nProgress } = useNProgress()
  const { getLocalizedSlug } = useLocale()

  const shouldConnectNext = (steps, index) => {
    if (steps?.length === index + 1) {
      return false
    }
    const currSlug = getLocalizedSlug(steps[index].navigationLink.slug)
    const nextSlug = getLocalizedSlug(steps[index + 1].navigationLink.slug)

    return (
      nProgress[currSlug]?.furthestScrollPercentage >= 0.9 &&
      nProgress[nextSlug]?.furthestScrollPercentage >= 0.9
    )
  }

  switch (isMobile) {
    case false:
      return (
        <ProgressStepperDesktop
          shouldConnectNext={shouldConnectNext}
          {...rest}
        />
      )
    default:
      return (
        <ProgressStepperMobile
          shouldConnectNext={shouldConnectNext}
          {...rest}
        />
      )
  }
}

ProgressStepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      displayText: PropTypes.string,
      id: PropTypes.string,
      navigationLink: PropTypes.shape({
        displayTextAlt: PropTypes.string,
        __typename: PropTypes.string,
        slug: PropTypes.string,
      }),
    })
  ),
}

export default ProgressStepper
