import React from 'react'
import Footer from '@common/components/Footer'
import { useStaticQuery, graphql } from 'gatsby'
import { hexToRgb } from '@common/utils'
import { useLocale } from '@common/hooks'

const NavigationGatsbyCDAContainer = props => {
  const { footer, siteInfo, locales } = useStaticQuery(graphql`
    query FooterQuery {
      footer: allContentfulNavigation(filter: { placement: { eq: "Footer" } }) {
        nodes {
          ...navigationFields
        }
      }
      siteInfo: allContentfulSiteInformationAndBranding {
        nodes {
          ...siteInfoFields
        }
      }
      locales: allContentfulLocale {
        nodes {
          ...localeFields
        }
      }
    }
  `)

  const { getLocalizedFields } = useLocale()
  const localizedFooter = getLocalizedFields(footer?.nodes)

  const navigationItems = !props?.loginPage && localizedFooter?.navigationItems
  const backgroundColor = hexToRgb(
    footer?.nodes?.[0]?.background?.hex,
    footer?.nodes?.[0]?.background?.opacity
  )
  const logo = siteInfo.nodes?.[0]?.logo
  const secondaryLogo = siteInfo.nodes?.[0]?.secondaryLogo
  const copyright = siteInfo.nodes?.[0]?.copyright

  return (
    <Footer
      navItems={navigationItems}
      backgroundColor={backgroundColor}
      logo={logo}
      secondaryLogo={secondaryLogo}
      copyright={copyright}
      locales={locales?.nodes}
      {...props}
    />
  )
}

export default NavigationGatsbyCDAContainer
