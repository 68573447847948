import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer } from '../styles'

const NavbarWrapper = ({ backgroundColor, children, ...rest }) => {
  return (
    <StyledContainer bg={backgroundColor} {...rest}>
      <StyledContainer
        display="flex"
        position="relative"
        justifyContent="space-between"
        alignItems="center"
        maxWidth={[
          'maxWidth.xsmall',
          'maxWidth.small',
          'maxWidth.medium',
          'maxWidth.large',
          'maxWidth.xlarge',
          'maxWidth.xxlarge',
        ]}
        margin="0 auto"
      >
        {children}
      </StyledContainer>
    </StyledContainer>
  )
}

NavbarWrapper.propTypes = {
  backgroundColor: PropTypes.string,
}

export default NavbarWrapper
