import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledDownloadListContainer,
  StyledHeader,
  StyledDivider,
  StyledDownloadItemContainer,
} from './styles'
import DownloadListItems from './DownloadListItems'
import { useMobile } from '@common/hooks'

const DownloadList = ({ header, downloadItems, buttonText }) => {
  const [isMobile] = useMobile()
  return (
    <StyledDownloadListContainer width="100%">
      <StyledHeader>{header}</StyledHeader>
      <StyledDivider bg="border.primary" width="100%" height="20px" />
      <StyledDownloadItemContainer
        display="flex"
        justifyContent="space-between"
        flexDirection={isMobile ? 'column' : 'row'}
        flexWrap="wrap"
        width="100%"
      >
        {downloadItems?.map(({ id, ...rest }, i) => (
          <DownloadListItems
            key={`${id} + ${i}`}
            buttonText={buttonText}
            {...rest}
          />
        ))}
      </StyledDownloadItemContainer>
    </StyledDownloadListContainer>
  )
}

DownloadList.propTypes = {
  header: PropTypes.string,
  downloadItems: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      id: PropTypes.string,
      itemUrl: PropTypes.shape({
        url: PropTypes.string,
      }),
      thumbnailSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      title: PropTypes.string,
    })
  ),
}

export default DownloadList
