import React, { useEffect, useState } from 'react'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
} from '@common/hooks/usePreviewQuery'
import { useLocale } from '@common/hooks'
import { getLinkedEntry } from '@common/utils'
import TableBlockGatsbyCDAContainer from './TableBlockGatsbyCDAContainer'

const TableBlockApolloCPAContainer = ({ id }) => {
  const { locale } = useLocale()
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'tableBlock',
    locale,
  })

  const [tableItems, setTable] = useState([])
  useEffect(() => {
    if (!loading && !error && data) {
      const { includes, items } = data.entries
      const mappedTables = items.map(({ fields }) => {
        const iconEntry = getLinkedEntry(
          includes.Entry,
          fields?.icon?.[0]?.sys?.id
        )
        const iconAsset = getLinkedEntry(
          includes.Asset,
          iconEntry?.fields?.source?.sys?.id
        )
        const getTableData = fields.tables.map(({ sys }) => {
          const table = getLinkedEntry(includes.Entry, sys.id)
          return table.fields
        })
        return {
          ...fields,
          tables: getTableData,
          icon: [{ source: iconAsset.fields }],
        }
      })
      setTable(mappedTables)
    }
  }, [data, loading, error])

  return (
    <>
      {tableItems?.map((t, i) => (
        <TableBlockGatsbyCDAContainer key={i} {...t} />
      ))}
    </>
  )
}

export default TableBlockApolloCPAContainer
