import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledStep,
  StyledTitle,
  StyledLine,
  StyledTimeline,
  StyledDot,
  StyledInnerDot,
} from './styles'
import { useNProgress, useLocale, useComponentTheme } from '@common/hooks'
import useWindowReady from '../../hooks/useWindowReady.js'

const Step = ({
  homeSlug,
  connectNext,
  step,
  slug,
  theme: themeBool = false,
  last = false,
}) => {
  const { nProgress } = useNProgress()
  const { locale, getLocalizedSlug } = useLocale()
  const localizedSlug = getLocalizedSlug(slug)
  const { theme } = useComponentTheme(themeBool)

  const completed =
    nProgress && nProgress[localizedSlug]
      ? nProgress[localizedSlug].furthestScrollPercentage >= 0.9
      : false

  const visited = nProgress && nProgress.hasOwnProperty(localizedSlug)

  const windowReady = useWindowReady()
  if (!windowReady) return null

  var isCurrent = false
  //  some browsers add a / on end when refreshed
  if (slug === homeSlug) {
    isCurrent =
      window?.location?.pathname === `/${locale}` ||
      `${window?.location?.pathname}${locale}` === `/${locale}` ||
      // for preview
      `${window?.location?.pathname}${window?.location?.search}` ===
        `/${locale}/${homeSlug}` ||
      `${window?.location?.pathname}${window?.location?.search}` ===
        `/${homeSlug}` ||
      `${window?.location?.pathname}${window?.location?.search}` ===
        `${homeSlug}`
  } else {
    isCurrent =
      `/${localizedSlug}` === window?.location?.pathname ||
      `/${localizedSlug}/` === window?.location?.pathname ||
      //  for preview
      `/${localizedSlug}` ===
        `${window?.location?.pathname}${window?.location?.search}` ||
      `/${localizedSlug}/` ===
        `${window?.location?.pathname}${window?.location?.search}`
  }

  return (
    <StyledStep width="100%">
      <StyledTitle
        color={isCurrent ? `text.primary` : `text.secondary`}
        width="100%"
        textAlign="center"
        fontSize={['10px', '10px', '15px']}
        fontWeight={3}
      >
        {step}
      </StyledTitle>

      <StyledTimeline width="100%" position="relative" mt={2}>
        <StyledLine
          borderTop="2px solid"
          zIndex={1}
          style={{ visibility: last ? 'hidden' : 'visible' }}
          borderTopColor={connectNext ? 'steps.active' : 'steps.inactive'}
          minWidth="20px"
        />

        <StyledDot
          zIndex={completed ? 1 : 0}
          isCurrent={isCurrent}
          bg={completed || visited ? 'steps.active' : 'steps.inactive'}
        />
        <StyledInnerDot
          completed={completed}
          isCurrentCompleted={isCurrent && completed}
          zIndex={completed ? -1 : 1}
          bg="black"
        />
      </StyledTimeline>
    </StyledStep>
  )
}

Step.propTypes = {
  connectNext: PropTypes.bool,
  step: PropTypes.string,
  slug: PropTypes.string,
  theme: PropTypes.bool,
  last: PropTypes.bool,
}

export default Step
