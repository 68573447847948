import React from 'react'
import PropTypes from 'prop-types'
import { BladeDefault } from '@common/components/Blade'
import { hexToRgb } from '@common/utils'
import { ImageGatsbyCDAContainer } from '../ImageContainer'
import { StatsBlockGatsbyCDAContainer } from '../StatsBlockContainer'
import { TextBlockGatsbyCDAContainer } from '../TextBlockContainer'
import { ColumnBlockGatsbyCDAContainer } from '../ColumnBlockContainer'
import { CustomComponentGatsbyCDAContainer } from '../CustomComponentContainer'
import { DownloadListGatsbyCDAContainer } from '../DownloadListContainer'
import { CarouselGatsbyCDAContainer } from '../CarouselContainer'
import { FormGatsbyCDAContainer } from '../FormContainer'
import { VideoGatsbyCDAContainer } from '../VideoContainer'
import TableBlockGatsbyCDAContainer from '../TableContainer'
import { useMobile } from '@common/hooks'

const renderBodyBasedOnType = ({ type, id, contentful_id, ...rest }) => {
  switch (type) {
    case 'ContentfulTextBlock':
      return <TextBlockGatsbyCDAContainer key={id} id={id} {...rest} />
    case 'ContentfulStatsBlock':
      return <StatsBlockGatsbyCDAContainer key={id} id={id} {...rest} />
    case 'ContentfulImage':
      return <ImageGatsbyCDAContainer key={id} id={id} {...rest} />
    case 'ContentfulMediaVideo':
      return (
        <VideoGatsbyCDAContainer
          key={id || contentful_id}
          id={id || contentful_id}
          {...rest}
        />
      )
    case 'ContentfulColumnBlock':
      return <ColumnBlockGatsbyCDAContainer key={id} id={id} {...rest} />
    case 'ContentfulCustomComponent':
      return <CustomComponentGatsbyCDAContainer key={id} id={id} {...rest} />
    case 'ContentfulGallerySet':
      return <CarouselGatsbyCDAContainer key={id} id={id} {...rest} />
    case 'ContentfulFormBlock':
      return <FormGatsbyCDAContainer key={id} id={id} {...rest} />
    case 'ContentfulDownloadList':
      return <DownloadListGatsbyCDAContainer key={id} id={id} {...rest} />
    case 'ContentfulTableBlock':
      return <TableBlockGatsbyCDAContainer key={id} id={id} {...rest} />
    default:
      return null
  }
}

export const parseBackground = (
  background,
  isMobile = false,
  isLandscape = false
) => {
  const viewportImageSource =
    isMobile &&
    !isLandscape &&
    (background?.imageSource?.sourceMobile?.id || background?.sourceMobile?.id)
      ? 'sourceMobile'
      : 'source'

  const backgroundContain = !!background?.backgroundContain

  let imageSource =
    background?.image?.[viewportImageSource]?.localFile?.childImageSharp
      ?.fluid ||
    background?.[viewportImageSource]?.localFile?.childImageSharp?.fluid ||
    background?.[viewportImageSource]?.fluid

  imageSource = {
    ...imageSource,
    alt:
      background?.image?.[viewportImageSource]?.description ||
      background?.[viewportImageSource]?.description ||
      background?.[viewportImageSource]?.title,
  }

  const backgroundGradientColors = background?.colors?.map(({ hex, opacity }) =>
    hexToRgb(hex, opacity)
  )

  const backgroundGradientDirection = background?.direction
  const backgroundGradient =
    backgroundGradientDirection &&
    backgroundGradientColors &&
    `linear-gradient(${backgroundGradientDirection}, ${backgroundGradientColors?.join(
      ','
    )})`

  const viewportVideoSource =
    isMobile && background?.cloudinaryVideoSrcMobile
      ? 'cloudinaryVideoSrcMobile'
      : 'cloudinaryVideoSrc'

  const viewportThumbnailSource =
    isMobile && background?.thumbnailMobile ? 'thumbnailMobile' : 'thumbnail'

  const videoSource = {
    src:
      background?.[viewportVideoSource]?.[0]?.original_secure_url ||
      background?.srcContentful?.file?.url,
    id: background?.id,
    thumbnail:
      background?.[viewportThumbnailSource]?.localFile?.childImageSharp
        ?.fluid || background?.[viewportThumbnailSource]?.fluid,
    videoFit: background?.videoFit,
    videoFitMobile: background?.videoFitMobile,
    marginTop: background?.marginTop,
    gradient: background?.contentful_id === '5W1K1r3DnO49cJCetXCELU',
    alt:
      background?.[viewportThumbnailSource]?.description ||
      background?.title ||
      'Xbox Series X|S Background Image',
  }

  const backgroundType = background?.__typename

  return {
    backgroundColor: hexToRgb(background?.hex, background?.opacity),
    backgroundImage: imageSource,
    backgroundGradient,
    backgroundVideo: videoSource,
    backgroundType,
    backgroundContain,
    backgroundVideoAspectRatioPortrait: background?.portrait,
    backgroundSize: background?.backgroundSize,
  }
}

const BladeDefaultGatsbyCDAContainer = ({
  id,
  anchorTagId,
  background,
  body,
  paddingTop,
  paddingTopMobile,
  paddingBottom,
  paddingBottomMobile,
  hideOnLocale,
  ...rest
}) => {
  const [isMobile, isLandscape] = useMobile()
  const parsedBackground = parseBackground(background, isMobile, isLandscape)
  hideOnLocale = !hideOnLocale ? [] : hideOnLocale
  return (
    <BladeDefault
      id={anchorTagId || `section-${id}`}
      {...parsedBackground}
      hideOnLocale={hideOnLocale}
      paddingTop={isMobile ? paddingTopMobile : paddingTop}
      paddingBottom={isMobile ? paddingBottomMobile : paddingBottom}
      {...rest}
    >
      {body?.map(({ __typename, ...rest }) =>
        renderBodyBasedOnType({ type: __typename, ...rest })
      )}
    </BladeDefault>
  )
}

BladeDefaultGatsbyCDAContainer.propTypes = {
  id: PropTypes.string,
  anchorTagId: PropTypes.string,
  background: PropTypes.object,
  body: PropTypes.arrayOf(PropTypes.object),
}
export default BladeDefaultGatsbyCDAContainer
