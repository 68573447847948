import React from 'react'
import PropTypes from 'prop-types'
import { TableBlock } from '@common/components/TableBlock'

const flattenTableData = array => {
  if (!array) return null
  const headers = array?.[0]
  const cellData = array.length > 0 && array.slice(1)
  const arrayToObj = {}
  if (headers) {
    headers.forEach((h, i) => {
      arrayToObj[h] = []
      cellData.forEach(c => {
        arrayToObj[h].push(c[i])
      })
    })
  }
  return arrayToObj
}

const TableBlockGatsbyCDAContainer = ({ tables = [], ...rest }) => {
  const tableGroup = tables.map(t => ({
    ...t,
    tableEntries: flattenTableData(t?.tableEntries?.tableData),
  }))
  return <TableBlock tables={tableGroup} {...rest} />
}

TableBlockGatsbyCDAContainer.propTypes = {
  tables: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      tableEntries: PropTypes.shape({
        tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      }),
    })
  ),
}

export default TableBlockGatsbyCDAContainer
