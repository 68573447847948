import React from 'react'
import PropTypes from 'prop-types'
import ModalGatsbyCDAContainer from '@common/containers/ModalContainer/ModalGatsbyCDAContainer'
import {
  StyledContainer,
  StyledDiv,
  StyledP,
  StyledTitle,
  StyledCTAContainer,
} from './styles'
import { Button, A } from '../ButtonAccessible'
import { useMobile, useLocale, useBrowser } from '@common/hooks'
import usePlatform from '@common/hooks/usePlatform'
const applyMarks = (string, mark, matcher) => {
  return string
    .replace(matcher, `<${mark}>`)
    .split(`<${mark}>`)
    .reduce((a, b, i) =>
      i % 2 === 0 ? a + `</${mark}>` + b : a + `<${mark}>` + b
    )
}

const applyLinks = mdText => {
  let mdLinkArray = mdText.match(
    /\[((?:\[.*\])?|(?:[^\]\[]*))\]\(([^\s]*)(?: ?\"(.*)\")?\)/gm
  )
  if (mdLinkArray)
    mdLinkArray.forEach((string, i) => {
      let linkInfo = string?.match(
        /^\[((?:\[.*\])?|(?:[^\]\[]*))\]\(([^\s]*)(?: ?\"(.*)\")?\)$/
      )

      mdText = mdText.replace(
        mdLinkArray[i],
        `<a ${linkInfo?.[2]?.[0] !== '/' && 'target="_blank"'} href=${
          linkInfo?.[2]
        } alt=${linkInfo?.[3]}>${
          linkInfo?.[1] ? linkInfo?.[1] : linkInfo?.[2]
        }</a>`
      )
    })

  let simpleLinkArray = mdText.match(/\<(https:\/\/[^>]*)\>/g)
  if (simpleLinkArray)
    simpleLinkArray.forEach((string, i) => {
      let simpleLinkInfo = string?.match(/^\<(https:\/\/[^>]*)\>$/)
      mdText = mdText.replace(
        simpleLinkArray[i],
        `<a target="_blank" href=${simpleLinkInfo?.[1]}>${simpleLinkInfo?.[1]}</a>`
      )
    })

  let emailArray = mdText.match(/\<((mailto:)([^>]*))\>/g)
  if (emailArray)
    emailArray.forEach((string, i) => {
      let simpleEmailInfo = string?.match(/^\<((mailto:)([^>]*))\>$/)

      mdText = mdText.replace(
        emailArray[i],
        `<a href=${simpleEmailInfo?.[1]}>${simpleEmailInfo?.[3]}</a>`
      )
    })

  return mdText
}

const applyTitleDecorations = (
  text,
  optional = false,
  largeHeader = false,
  title = false,
  useBreak = false
) => {
  if (!text) return null

  if (title) {
    text = text
      .split(' ')
      .map(word => {
        return word?.length >= (largeHeader ? 8 : 14)
          ? `<p class=${useBreak ? 'break-word' : 'hyphenate'}>${word}</p>`
          : word
      })
      .join(' ')
  }

  if (largeHeader) {
    // adds a longer | for large page headers
    text = text
      .split(' | ')
      .join(`<div class="line-container"><div class="line"></div></div>`)
  }

  if (optional) {
    text = text.concat(`<p class="optional"> ${optional}</p>`)
  }

  return text
}

const renderMarkdown = text => {
  if (!text) return null
  text = applyLinks(text)

  let italicArray = text.match(/\*([^* \n]+|[^* \n]+[^*]*[^* \n]+)\*/gm)
  if (italicArray)
    italicArray.forEach((string, i) => {
      let italicInfo = string?.match(/^\*([^* \n]+|[^* \n]+[^*]*[^* \n]+)\*$/)
      text = text.replace(italicArray[i], `<em>${italicInfo?.[1]}</em>`)
    })

  text = text
    .split('\n')
    .map(words => applyMarks(words, 'strong', /(__|\*\*)/gm))
    .join('<br />')

  return text
}

const Eyebrow = ({ children, ...rest }) => {
  if (!children) {
    return null
  }
  return <StyledDiv {...rest}>{children}</StyledDiv>
}

const CTA = ({ ctaText, ctaLink, ctaAppearance, ...rest }) => {
  if (!ctaAppearance || ctaAppearance === 'No CTA') {
    return null
  }
  if (ctaLink?.isVideo) {
    return <ModalGatsbyCDAContainer modal={ctaLink} ctaText={ctaText} />
  }

  if (ctaAppearance === 'Button') {
    return (
      <Button tabIndex="-1" onClick={undefined} link={ctaLink}>
        {ctaText}
      </Button>
    )
  }
  if (ctaAppearance === 'Plain Text') {
    return <StyledP>{ctaText}</StyledP>
  }

  return <A link={ctaLink}>{ctaText}</A>
}

const TextBlock = ({
  displayTitle,
  eyebrow,
  ctaText,
  ctaLink,
  ctaAppearance,
  body,
  bodySize = 16,
  themes = 'theme2',
  titleSize = 32,
  opaqueTextColor = 'default',
  eyebrowLayout = 'Grey',
  hideBodyOnLocale,
  hideOnLocale,
}) => {
  const { locale } = useLocale()
  const [isMobile] = useMobile()
  const { browser } = useBrowser()
  const { os } = usePlatform()

  const useBreak =
    (os === 'Windows' && browser === 'Chrome') ||
    os === 'Android' ||
    browser === 'FireFox'

  const mobileFontMapping = {
    90: themes === 'theme1' ? 55 : 67,
    65: 45,
    45: 45,
    32: themes === 'theme1' || themes === 'theme5' ? 24 : 32,
    22: 22,
    18: 18,
  }

  const eyebrowColor =
    eyebrowLayout === 'Lime'
      ? 'text.tertiary'
      : opaqueTextColor === 'black'
      ? 'text.inverted'
      : 'text.secondary'

  const pColor =
    opaqueTextColor === 'black'
      ? 'text.inverted'
      : themes === 'theme4'
      ? 'text.primary'
      : opaqueTextColor === 'white'
      ? 'text.primary'
      : 'text.secondary'

  const titleColor =
    opaqueTextColor === 'black' ? 'text.inverted' : 'text.primary'

  const titleWeightBasedOnSize =
    titleSize === 45 ? 700 : titleSize < 45 ? 400 : 600

  if (hideOnLocale?.includes(locale)) return null
  return (
    <StyledContainer
      width="100%"
      textAlign={
        themes === 'theme3' || themes === 'theme4' ? 'center' : undefined
      }
    >
      {eyebrow && eyebrowLayout !== 'Grey Inline' && (
        <Eyebrow
          fontSize={
            themes === 'theme1' || themes === 'theme5' || themes === 'theme3'
              ? 7
              : 3
          }
          fontFamily="headings"
          lineHeight={1.25}
          fontWeight={eyebrowLayout === 'Lime' ? 600 : undefined}
          letterSpacing={themes === 'theme2' ? '0rem' : '0.2rem'}
          color={eyebrowColor}
        >
          {eyebrow}
        </Eyebrow>
      )}
      {displayTitle && (
        <StyledTitle
          fontSize={
            isMobile ? `${mobileFontMapping[titleSize]}px` : `${titleSize}px`
          }
          fontFamily="headings"
          lineHeight={1.2}
          fontWeight={
            themes === 'theme1' || themes === 'theme5'
              ? 900
              : themes === 'theme3' || themes === 'theme4'
              ? 700
              : titleWeightBasedOnSize
          }
          color={titleColor}
          dangerouslySetInnerHTML={{
            __html: applyTitleDecorations(
              displayTitle,
              eyebrowLayout === 'Grey Inline' && eyebrow,
              themes === 'theme1' || themes === 'theme5',
              true,
              useBreak
            ),
          }}
          marginBottom={
            (titleSize === 45 || titleSize === 65) && !isMobile
              ? '24px'
              : body
              ? 3
              : 0
          }
          marginTop={titleSize === 45 && !isMobile && '16px'}
        />
      )}
      {body && !hideBodyOnLocale?.includes(locale) && (
        <StyledP
          fontSize={
            isMobile ? `${mobileFontMapping[bodySize]}px` : `${bodySize}px`
          }
          lineHeight={!titleSize ? 3 : bodySize <= 16 ? 3 : '1.5'}
          color={pColor}
          letterSpacing={
            themes === 'theme1' || themes === 'theme5' ? '.15em' : '0em'
          }
          dangerouslySetInnerHTML={{
            __html: renderMarkdown(body),
          }}
          fontWeight={themes === 'theme2' ? 400 : undefined}
          fontFamily={themes === 'theme2' ? 'headings' : undefined}
          marginBottom={themes === 'theme2' && !isMobile && 2}
        />
      )}
      {ctaText && (
        <StyledCTAContainer mt={4}>
          <CTA
            ctaText={ctaText}
            ctaLink={ctaLink}
            ctaAppearance={ctaAppearance}
          />
        </StyledCTAContainer>
      )}
    </StyledContainer>
  )
}

TextBlock.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  displayTitle: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.shape({
    anchorTagID: PropTypes.string,
  }),
  ctaAppearance: PropTypes.string,
  titleSize: PropTypes.number,
  bodySize: PropTypes.number,
  theme: PropTypes.string,
  eyebrow: PropTypes.string,
  eyebrowLayout: PropTypes.string,
}

export default TextBlock
