import React from 'react'
import Portal from './components/Portal'
import Modal from '.'
import { usePortal } from './../../hooks'
import { IconButton } from '../ButtonAccessible'

const ModalWrapper = ({ theme, type, content, ctaText }) => {
  const { isOpen, openPortal, closePortal, modalRef } = usePortal({
    closeOnOutsideClick: true,
    closeOnEsc: true,
  })
  return (
    <>
      <IconButton openPortal={openPortal}>{ctaText}</IconButton>
      {isOpen && (
        <Portal ref={modalRef}>
          <Modal
            theme={theme}
            type={type}
            content={content}
            modal={{ modalRef, closePortal }}
          />
        </Portal>
      )}
    </>
  )
}
export default ModalWrapper
