import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledNumberedColumnContainer,
  StyledNumberedTextContainer,
  StyledTextContainer,
} from '../styles'
import { TextBlock } from '../../../TextBlock'
import { useMobile } from '@common/hooks'

const renderBasedOnType = ({
  __typename,
  currentIndex,
  body,
  id,
  themes,
  title,
  isMobile,
  heightPartition,
  ...rest
}) => {
  switch (__typename) {
    case 'ContentfulTextBlock':
      return (
        <StyledNumberedTextContainer
          key={`${id}`}
          height={heightPartition}
          mb={3}
          display="flex"
          flexDirection="row"
          justifycontent="flex-start"
        >
          <StyledTextContainer
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            mr={4}
            marginTop={isMobile ? 1 : 2}
            marginBottom={isMobile ? 1 : 2}
          >
            <StyledTextContainer
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexShrink="0"
              height="68px"
              width="68px"
              paddingBottom="2px"
              fontSize="35px"
              border="solid"
              borderWidth="2px"
              borderColor="border.secondary"
              borderRadius="35px"
              fontFamily="headings"
              fontWeight={800}
            >
              {currentIndex + 1}
            </StyledTextContainer>
          </StyledTextContainer>
          <StyledTextContainer display="flex" alignItems="center">
            <TextBlock
              key={id}
              body={body}
              id={id}
              themes={themes}
              title={title}
              {...rest}
            />
          </StyledTextContainer>
        </StyledNumberedTextContainer>
      )
    default:
      return null
  }
}

const ColumnNumbered = ({ contents, numberedStartIndex }) => {
  const [isMobile] = useMobile()
  const contentsLength = contents.length
  return (
    <StyledNumberedColumnContainer height="100%" mb={!isMobile && 3}>
      {contents?.map((content, i) =>
        renderBasedOnType({
          currentIndex: numberedStartIndex + i,
          heightPartition: `${100 / contentsLength}%`,
          isMobile,
          ...content,
        })
      )}
    </StyledNumberedColumnContainer>
  )
}

ColumnNumbered.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.shape({
        body: PropTypes.string,
      }),
      id: PropTypes.string,
      themes: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  numberedStartIndex: PropTypes.number,
}

export default ColumnNumbered
