import React, { useEffect, useState, useCallback } from 'react'
import { ColumnBlock } from '@common/components/ColumnBlock'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
} from '@common/hooks/usePreviewQuery'
import { useLocale } from '@common/hooks'
import { getLinkedEntry, parseQueryString } from '@common/utils'

const ColumnBlockApolloCPAContainer = ({ id }) => {
  const { locale } = useLocale()
  const {
    loading: loadingColumnBlockItems,
    error: errorColumnBlockItems,
    data: dataColumnBlockItems,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'columnBlock',
    locale,
  })

  const [columnBlockItems, setCBItems] = useState([])

  const getMappedSource = useCallback((includes, id) => {
    let mappedSource = getLinkedEntry(includes.Asset, id)

    mappedSource = {
      id: mappedSource?.sys?.id,
      ...mappedSource?.fields,
    }
    return mappedSource
  }, [])

  const getMappedCTA = useCallback(
    (includes, id) => {
      let mappedCTA = getLinkedEntry(includes.Entry, id)
      const type =
        mappedCTA?.sys?.contentType?.sys?.id === 'image'
          ? 'ContentfulImage'
          : mappedCTA?.sys?.contentType?.sys?.id === 'mediaVideo'
          ? 'ContentfulMediaVideo'
          : 'ContentfulTextBlock'

      mappedCTA = {
        id: mappedCTA?.sys?.id,
        ...mappedCTA?.fields,
        thumbnail: getMappedSource(
          includes,
          mappedCTA?.fields?.thumbnail?.sys?.id
        ),
        type: type,
        slug: `preview/?t=${parseQueryString('t')}&id=${id}`,
      }
      return mappedCTA
    },
    [getMappedSource]
  )

  const getMappedContent = useCallback(
    (includes, id) => {
      let mappedContent = getLinkedEntry(includes.Entry, id)

      const typename =
        mappedContent?.sys?.contentType?.sys?.id === 'image'
          ? 'ContentfulImage'
          : mappedContent?.sys?.contentType?.sys?.id === 'mediaVideo'
          ? 'ContentfulMediaVideo'
          : 'ContentfulTextBlock'
      mappedContent = {
        id: mappedContent?.sys?.id,
        ...mappedContent?.fields,
        __typename: typename,
        source: getMappedSource(
          includes,
          mappedContent?.fields?.source?.sys?.id
        ),
        thumbnail: getMappedSource(
          includes,
          mappedContent?.fields?.thumbnail?.sys?.id
        ),
        body: mappedContent?.fields?.body,
        ctaLink: mappedContent?.fields?.ctaLink?.map(link =>
          getMappedCTA(includes, link?.sys?.id)
        ),
        displayTitle: mappedContent?.fields?.displayTitle,
      }

      return mappedContent
    },
    [getMappedCTA, getMappedSource]
  )

  const getMappedHeroImage = useCallback(
    (includes, id) => {
      let mappedHeroImage = getLinkedEntry(includes.Entry, id)
      mappedHeroImage = {
        id: mappedHeroImage?.sys?.id,
        ...mappedHeroImage?.fields,
        source: getMappedSource(
          includes,
          mappedHeroImage?.fields?.source?.sys?.id
        ),
      }
      return mappedHeroImage
    },
    [getMappedSource]
  )

  const getMappedColumns = useCallback(
    (includes, id) => {
      let mappedColumn = getLinkedEntry(includes?.Entry, id)
      mappedColumn = {
        id: mappedColumn?.sys?.id,
        ...mappedColumn?.fields,
        heroImage:
          mappedColumn?.fields?.heroImage &&
          getMappedHeroImage(
            includes,
            mappedColumn?.fields?.heroImage?.sys?.id
          ),
        mainContent: mappedColumn?.fields?.mainContent?.map(content =>
          getMappedContent(includes, content?.sys?.id)
        ),
      }
      return mappedColumn
    },
    [getMappedContent, getMappedHeroImage]
  )

  useEffect(() => {
    if (
      !loadingColumnBlockItems &&
      !errorColumnBlockItems &&
      dataColumnBlockItems
    ) {
      const {
        entries: { includes, items },
      } = dataColumnBlockItems

      let filteredCBItems = getLinkedEntry(items, id)
      filteredCBItems = {
        id: filteredCBItems?.sys?.id,
        ...filteredCBItems?.fields,
        columns: filteredCBItems?.fields?.columns?.map(column =>
          getMappedColumns(includes, column?.sys?.id)
        ),
      }
      let horizontalAlignment =
        filteredCBItems?.horizontalAlignment === 'center'
          ? 'center'
          : filteredCBItems?.horizontalAlignment === 'spaced apart'
          ? 'space-between'
          : filteredCBItems?.horizontalAlignment === 'right'
          ? 'flex-end'
          : 'flex-start'

      let gutter = filteredCBItems?.gutter
        ? `${filteredCBItems?.gutter / 2}px`
        : '0px'

      let bottomMargin =
        filteredCBItems?.bottomMargin || filteredCBItems?.bottomMargin === 0
          ? `${filteredCBItems?.bottomMargin}px`
          : '32px'

      let maxWidth = filteredCBItems?.maxWidth
        ? `${filteredCBItems?.maxWidth}%`
        : '70%'

      let mobileLayout = filteredCBItems?.mobileLayout || 'column'

      let filteredCBColumns = filteredCBItems?.columns?.map(
        ({ heroImage, mainContent, verticalAlignment, ...rest }) => ({
          ...rest,
          verticalAlignment:
            verticalAlignment === 'top'
              ? 'flex-start'
              : verticalAlignment === 'bottom'
              ? 'flex-end'
              : 'center',
          heroImage: heroImage && {
            alt: heroImage?.description || heroImage?.title,
            src:
              heroImage?.source?.localFile?.childImageSharp?.fluid ||
              heroImage?.source?.file?.url ||
              heroImage?.source?.fluid,
          },
          mainContent: mainContent?.map(
            ({
              source,
              cloudinaryVideoSrc,
              thumbnail,
              ctaLink,
              hideOnLocale,
              hideBodyOnLocale,
              captions,
              ...rest
            }) => ({
              ...rest,
              ctaLink: {
                ...ctaLink?.[0],
                url: ctaLink?.[0]?.slug
                  ? `/${ctaLink?.[0]?.slug}`
                  : ctaLink?.[0]?.anchorTagId
                  ? `/${ctaLink?.[0]?.anchorTagId}`
                  : ctaLink?.[0]?.url,
                isVideo: ctaLink?.[0]?.type === 'ContentfulMediaVideo',
              },
              captions: captions?.file?.url,
              alt: source?.description,
              src:
                source?.localFile?.childImageSharp?.fluid ||
                source?.file?.url ||
                source?.fluid ||
                cloudinaryVideoSrc?.[0]?.secure_url,
              thumbnail:
                thumbnail?.localFile?.childImageSharp?.fluid?.src ||
                thumbnail?.fluid?.src ||
                thumbnail?.file?.url,
              hideBodyOnLocale: !hideBodyOnLocale ? [] : hideBodyOnLocale,
              hideOnLocale: !hideOnLocale ? [] : hideOnLocale,
            })
          ),
        })
      )

      filteredCBItems = {
        ...filteredCBItems,
        columns: filteredCBColumns,
        horizontalAlignment: horizontalAlignment,
        gutter: gutter,
        bottomMargin: bottomMargin,
        maxWidth: maxWidth,
        mobileLayout: mobileLayout,
      }

      setCBItems(filteredCBItems)
    }
  }, [
    dataColumnBlockItems,
    errorColumnBlockItems,
    getMappedColumns,
    id,
    loadingColumnBlockItems,
  ])
  return <ColumnBlock {...columnBlockItems} />
}

export default ColumnBlockApolloCPAContainer
