import React from 'react'
import PropTypes from 'prop-types'
import { CarouselXbox as Carousel } from '../../../../common/components/CarouselXbox'

const CarouselGatsbyCDAContainer = ({ galleries }) => {
  return <Carousel gallerySet={galleries}></Carousel>
}

CarouselGatsbyCDAContainer.propTypes = {
  galleries: PropTypes.array,
}

export default CarouselGatsbyCDAContainer
