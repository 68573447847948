import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
} from 'styled-system'

export const StyledContainer = styled.div`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledSvg = styled.img`
  ${space}
`

export const StyledList = styled.ul`
  ${space}
  ${flexbox}
  ${layout}
`

export const StyledListItem = styled.li`
  ${space}
  ${flexbox}
`

export const StyledText = styled.p`
  padding: 0;
  margin: 0;
  ${space} 
  ${typography}
  ${color}
`

export const StyledProgressBar = styled.div.attrs(({ width, height }) => ({
  style: {
    width,
    height,
  },
}))`
  ${color}
`
