import React, { useState } from 'react'
import {
  StyledTabsContainer,
  StyledTab,
  StyledGallerySet,
  StyledLine,
  StyledUnderline,
} from './styles'
import CarouselXboxGallery from './CarouselXboxGallery'

const CarouselXbox = ({ gallerySet }) => {
  const [activeGallery, setActiveGallery] = useState(0)

  const galleryOnClick = activeIndex => {
    setActiveGallery(activeIndex)
  }

  return (
    <StyledGallerySet my="80px">
      <StyledTabsContainer
        display="flex"
        flexWrap="nowrap"
        justifyContent="space-around"
        alignItems="center"
        mb="20px"
        position="relative"
      >
        <StyledLine
          position="absolute"
          borderBottom="1px solid #262626"
          width="88%"
          bottom="0"
        />

        {gallerySet?.map((gallery, i) => (
          <StyledTab
            onClick={() => galleryOnClick(i)}
            key={gallery.id}
            pb="20px"
            position="relative"
          >
            {gallery.displayTitle}
            <StyledUnderline
              position="absolute"
              bottom="0"
              width="100%"
              opacity={activeGallery === i ? '1' : '0'}
              borderBottomColor="lime"
              borderBottomWidth="1px"
              borderBottomStyle="solid"
            />
          </StyledTab>
        ))}
      </StyledTabsContainer>
      <CarouselXboxGallery
        gallery={gallerySet && gallerySet[activeGallery]?.galleryItems}
      />
    </StyledGallerySet>
  )
}

export default CarouselXbox
