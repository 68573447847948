import React from 'react'
import PropTypes from 'prop-types'
import NavigationDesktopSimple from './NavigationDesktopSimple'

const NavigationDesktop = ({ type, ...props }) => {
  switch (type) {
    case 'simple':
    default:
      return <NavigationDesktopSimple {...props} />
  }
}

NavigationDesktop.propTypes = {
  type: PropTypes.string,
}

export default NavigationDesktop
