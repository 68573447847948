import React from 'react'
import { PropTypes } from 'prop-types'
import DefaultForm from '../../../../common/components/Form/DefaultForm'
import { validateInput } from '../../../../common/utils'
import { useLocale } from '@common/hooks'

const FormGatsbyCDAContainer = ({
  layout,
  formFields,
  formButton,
  ...rest
}) => {
  const { getLocalizedSlug } = useLocale()

  const formValues = {
    initValues: {},
    validSchema: {},
  }

  if (formFields) {
    formFields.forEach(input => {
      formValues.initValues[input.id] = ''
      formValues.validSchema[input.id] = input.validation
    })
  }

  const mappedFormButtons =
    formButton &&
    formButton.map(b => {
      const localizedSlug = getLocalizedSlug(b?.navigationLink?.slug)
      return {
        ...b,
        navigationLink: localizedSlug,
      }
    })

  const validate = values => {
    const valueKeys = Object.keys(formValues.initValues)
    const errors = {}
    valueKeys.forEach(v => {
      if (!values[v]) {
        // validating field has been touched but no input
        errors[v] = 'Required'
      } else if (validateInput(values[v], formValues.validSchema[v])) {
        errors[v] = validateInput(values[v], formValues.validSchema[v])
      }
    })
    return errors
  }

  switch (layout) {
    default:
      return (
        <DefaultForm
          validate={validate}
          formValues={formValues}
          formFields={formFields}
          formButton={mappedFormButtons}
          {...rest}
        />
      )
  }
}

FormGatsbyCDAContainer.propTypes = {
  layout: PropTypes.string,
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      placeholder: PropTypes.string,
      title: PropTypes.string,
      successMessage: PropTypes.string,
      type: PropTypes.string,
      validation: PropTypes.arrayOf(PropTypes.string),
    })
  ),
}

export default FormGatsbyCDAContainer
