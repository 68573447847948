import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
} from 'styled-system'

export const StyledContainer = styled.div`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledColumnBlockContainer = styled(StyledContainer)``

export const StyledOpaqueImageContainer = styled(StyledContainer)``

export const StyledImageContainer = styled(StyledContainer)``

export const StyledHeroImageContainer = styled(StyledContainer)``

export const StyledTextContainer = styled(StyledContainer)`
  &:last-child {
    margin-bottom: 0;
  }
`

export const StyledTransparentBGContainer = styled(StyledContainer)``

export const StyledOpaqueBGContainer = styled(StyledContainer)``

export const StyledHybridBGContainer = styled(StyledContainer)``

export const StyledVideoContainer = styled(StyledContainer)``

export const StyledNumberedTextContainer = styled(StyledContainer)`
  // &:last-child {
  //   margin-bottom: 0;
  // }
`

export const StyledContentContainer = styled(StyledContainer)``

export const StyledWidthContainer = styled(StyledContainer)`
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  &:first-child ${StyledImageContainer} {
    padding-left: 0;
  }

  &:last-child ${StyledImageContainer} {
    padding-right: 0;
  }

  &:last-child ${StyledNumberedTextContainer}:last-child {
    margin-bottom: 0;
  }

  &:last-child ${StyledContentContainer}:last-child {
    margin-bottom: 0;
  }

  &:first-child ${StyledHybridBGContainer} {
    ${({ isMobile }) => !isMobile && 'align-items: flex-start;'}
  }

  &:last-child ${StyledHybridBGContainer} {
    ${({ isMobile }) => !isMobile && 'align-items: flex-end;'}
  }
`

export const StyledHeroImageHeightContainer = styled(StyledContainer)``

export const StyledNumberedColumnContainer = styled(StyledContainer)``

export const StyledNumberedCircle = styled(StyledContainer)``

export const StyledOverlapHeroContainer = styled(StyledContainer)`
  transform: translate(-50%, -50%);
`
