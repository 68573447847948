import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer, StyledText, StyledNavLink } from '../styles'
import { useLocale } from '@common/hooks'

const NavigationItem = ({
  displayText,
  navigationLink: { url, slug },
  theme = 'dark',
}) => {
  const { localeSlug, getLocalizedSlug } = useLocale()
  const localizedSlug = getLocalizedSlug(slug)

  const currentSlug = window?.location?.pathname.slice(1)
  const currentQuery = window?.location?.search
  const currentPath = `${currentSlug}${currentQuery}`

  const isActive =
    currentPath === localizedSlug ||
    (currentPath === localeSlug && slug === 'home')

  return (
    <StyledNavLink
      opacity={isActive ? 1 : 0.7}
      variant={`${theme}.primary`}
      to={localizedSlug}
      href={url}
      p={3}
      display="block"
      aria-current={isActive ? 'page' : null}
    >
      <StyledContainer
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <StyledText fontSize={12}>{displayText}</StyledText>
      </StyledContainer>
    </StyledNavLink>
  )
}

NavigationItem.propTypes = {
  displayText: PropTypes.string,
  navigationLink: PropTypes.shape({
    url: PropTypes.string,
    slug: PropTypes.string,
  }),
  theme: PropTypes.string,
}

export default NavigationItem
