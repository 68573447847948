import styled from 'styled-components'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import BackgroundImage from 'gatsby-background-image'
import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  border,
} from 'styled-system'

export const Container = styled.div`
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${border}
`

export const StyledGallerySet = styled(Container)`
  box-sizing: border-box;
`

export const StyledCarouselContainer = styled(Container)``

export const StyledLine = styled(Container)``

export const StyledUnderline = styled(Container)`
  box-sizing: border-box;
  transition: all 0.2s ease-in;
`

export const StyledCarousel = styled(Slider)`
  //Remove Default Buttons
  .slick-prev:before {
    content: none;
  }
  .slick-next:before {
    content: none;
  }

  //Gets rid of the black seam between blades
  .slick-slide > div {
    display: flex;
  }
`

export const StyledTabsContainer = styled(Container)`
  box-sizing: border-box;
`

export const StyledTab = styled(Container)`
  box-sizing: border-box;
  cursor: pointer;
`

export const StyledTextContainer = styled(Container)``

export const StyledText = styled.h1``

export const StyledCarouselContentContainer = styled(Container)``

export const StyledSvgContainer = styled.div`
  cursor: pointer;
  ${position}
  ${layout}
  ${color}
  ${space}
  ${({ transform }) => transform && `transform: ${transform};`}
`
export const StyledArrowContainer = styled(Container)``

export const StyledButtonWidth = styled(Container)``

export const StyledButtonContainer = styled(Container)``

export const StyledButton = styled(Container)``

export const StyledImageContainer = styled(Container)``

export const StyledBackgroundImage = styled(BackgroundImage)`
${space}
${color}
${flexbox}
${layout}
${typography}
${position}
${border}
`
