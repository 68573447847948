import React from 'react'
import { PropTypes } from 'prop-types'
import Form from '.'

const DefaultForm = ({ ...rest }) => {
  const handleSubmit = (value, actions) => {
    // add onsubmit logic
    alert(JSON.stringify(value, null, 2))
  }
  return <Form onSubmit={handleSubmit} {...rest} />
}

DefaultForm.propTypes = {}

export default DefaultForm
