import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
  variant,
} from 'styled-system'
import { A } from '../Button'

export const StyledContainer = styled.div`
  box-sizing: border-box;
  transition: all 0.25s ease-in-out;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  ${({ visibility }) => visibility && `visibility: ${visibility};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}

  a {
    text-decoration: none;
  }
`

export const StyledDropdownContainer = styled(StyledContainer)`
${space}
${position}
${color}
${typography}
${border}
${layout}
${flexbox}
  transform: translate(-50%, -50%);
  z-index: 2000;
  position: fixed;
  left: 50%;
  top: 50%;
`

export const StyledFooterContainer = styled.footer`
  box-sizing: border-box;
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
`

export const StyledSvg = styled.img`
  ${space}
`

export const StyledList = styled.ul`
  ${space}
  ${flexbox}
  ${layout}
`

export const StyledListItem = styled.li`
  ${space}
  ${flexbox}

  a {
    text-decoration: none;
  }
`

export const StyledText = styled.p`
  padding: 0;
  margin: 0;
  ${space} 
  ${typography}
  ${color}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
`

export const BaseButton = styled(A)(variant({ scale: 'buttons' }))

export const StyledNavLink = styled(BaseButton)`
  cursor: pointer;
  ${space}
  ${color}
  ${typography}
`

export const StyledActiveLink = styled(A)`
  cursor: pointer;
  ${space}
  ${color}
  ${typography}
`

export const StyledDropdownItem = styled(StyledNavLink)`
  &:hover {
    background-color: #333333;
  }
`

export const StyledIcon = styled.img.attrs({
  'aria-hidden': 'true',
})`
  transition: all 0.25s ease;
  ${space}
  ${color}
  ${position}
  ${layout}
  ${({ rotate }) => rotate && `transform: rotate(${rotate});`}
  cursor: pointer;
`

export const Overlay = styled.div`
  ${space}
  ${layout}
  ${border}
  ${typography}
  ${color}
  ${flexbox}
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  opacity: .5;
  background: black;
`

export const LocaleContainer = styled.div`  
${space}
${layout}
${border}
${typography}
${color}
${flexbox}
`

export const Placeholder = styled.span`
${space}
${layout}
${border}
${typography}
${color}
${flexbox}

`
