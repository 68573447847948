import React, { useState, useEffect, useContext, useCallback } from 'react'
import usePreviewQuery, { GET_ENTRY } from '@common/hooks/usePreviewQuery'
import { useLocale } from '@common/hooks'
import { PreviewAssetsContext } from '@common/context'
import Video from '@common/components/Video'

const VideoApolloCPAContainer = ({ id, ...rest }) => {
  const { assets } = useContext(PreviewAssetsContext)
  const { locale } = useLocale()
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRY,
    id,
    skipBool: !id,
    locale,
  })

  const [videoFields, setVideoFields] = useState()

  const getMappedVideoFields = useCallback(
    entry =>
      entry && {
        id: entry?.id,
        src: entry?.fields?.cloudinaryVideoSrc?.[0]?.original_secure_url,
        thumbnail: assets?.find(
          ({ id }) => id === entry?.fields?.thumbnail?.sys?.id
        )?.file?.url,
        title: entry?.fields?.title,
        captions: assets?.find(
          ({ id }) => id === entry?.fields?.captions?.sys?.id
        )?.file?.url,
        loop: entry?.fields?.loop,
        portrait: entry?.fields?.portrait
      },
    [assets]
  )

  useEffect(() => {
    if (!loading && !error && data) {
      const { entry } = data
      const mappedEntry = getMappedVideoFields(entry)

      setVideoFields(mappedEntry)
    }
  }, [loading, data, error, assets, getMappedVideoFields])
  return videoFields ? <Video {...videoFields} /> : null
}

export default VideoApolloCPAContainer
