import React from 'react'
import { TextBlock } from '@common/components/TextBlock'

const TextBlockGatsbyCDAContainer = ({
  body,
  displayTitle,
  ctaLink,
  hideBodyOnLocale,
  hideOnLocale,
  ...fields
}) => {
  ctaLink = ctaLink?.[0] ? { ...ctaLink[0], type: ctaLink[0].__typename } : null
  hideBodyOnLocale = !hideBodyOnLocale ? [] : hideBodyOnLocale
  hideOnLocale = !hideOnLocale ? [] : hideOnLocale
  return (
    <TextBlock
      body={body?.body}
      displayTitle={displayTitle?.displayTitle}
      ctaLink={ctaLink}
      hideBodyOnLocale={hideBodyOnLocale}
      hideOnLocale={hideOnLocale}
      {...fields}
    />
  )
}

export default TextBlockGatsbyCDAContainer
