import React, { useEffect, useState, useCallback } from 'react'
import { useLocale, usePreviewQuery } from '@common/hooks'
import { GET_ENTRIES_BY_CONTENT_TYPE_ID } from '@common/hooks/usePreviewQuery'
import { getLinkedEntry } from '@common/utils'
import DownloadList from '@common/components/DownloadList'

const DownloadListApolloCPAContainer = ({ id }) => {
  const { locale } = useLocale()
  const {
    loading: loadingDLItems,
    error: errorDLItems,
    data: dataDLItems,
  } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'downloadList',
    locale,
  })

  const [downloadListItems, setDLItems] = useState([])

  const getMappedSource = useCallback((includes, id) => {
    let mappedSource = getLinkedEntry(includes?.Asset, id)
    mappedSource = {
      id: mappedSource?.sys?.id,
      ...mappedSource?.fields,
    }
    return mappedSource
  }, [])

  const getMappedThumbnail = useCallback(
    (includes, id) => {
      let mappedThumbnail = getLinkedEntry(includes?.Entry, id)
      mappedThumbnail = {
        id: mappedThumbnail?.sys?.id,
        ...mappedThumbnail?.fields,
        source: getMappedSource(
          includes,
          mappedThumbnail?.fields?.source?.sys?.id
        ),
      }
      return mappedThumbnail
    },
    [getMappedSource]
  )

  const getMappedDLItem = useCallback(
    (includes, id) => {
      let mappedDLItem = getLinkedEntry(includes?.Entry, id)

      mappedDLItem = {
        id: mappedDLItem?.sys?.id,
        ...mappedDLItem?.fields,
        item: getMappedSource(includes, mappedDLItem?.fields?.item?.sys?.id),
        thumbnail: getMappedThumbnail(
          includes,
          mappedDLItem?.fields?.thumbnail?.sys?.id
        ),
      }
      return mappedDLItem
    },
    [getMappedSource, getMappedThumbnail]
  )

  useEffect(() => {
    if (!loadingDLItems && !errorDLItems && dataDLItems) {
      const {
        entries: { includes, items },
      } = dataDLItems
      let filteredDLItems = getLinkedEntry(items, id)

      filteredDLItems = {
        id: filteredDLItems?.sys?.id,
        ...filteredDLItems?.fields,
        downloadItems: filteredDLItems?.fields?.downloadItems?.map(item =>
          getMappedDLItem(includes, item?.sys?.id)
        ),
      }

      filteredDLItems = {
        ...filteredDLItems,
        downloadItems: filteredDLItems?.downloadItems?.map(
          ({ id, title, thumbnail, item }) => ({
            id,
            title,
            thumbnailSrc:
              thumbnail?.source?.localFile?.childImageSharp?.fluid ||
              thumbnail?.source?.fluid ||
              thumbnail?.source?.file?.url,
            alt: thumbnail?.title,
            itemUrl: item?.file,
          })
        ),
      }

      setDLItems(filteredDLItems)
    }
  }, [dataDLItems, errorDLItems, getMappedDLItem, id, loadingDLItems])
  return <DownloadList {...downloadListItems} />
}

export default DownloadListApolloCPAContainer
