import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer, StyledText, StyledNavLink } from '../styles'
import { useLocale } from '@common/hooks'

const SubNavigationItem = ({
  displayText,
  navigationLink,
  anchorGroup,
  theme = 'dark',
  activeAnchor,
  hideActiveBorder = false,
  tabIndex,
  handleKeyPress = () => {},
}) => {
  const { getLocalizedSlug } = useLocale()
  const localizedSlug = getLocalizedSlug(navigationLink?.slug)

  const anchorId =
    anchorGroup?.blades?.[0]?.anchorTagId ||
    `section-${anchorGroup?.blades?.[0]?.id}`
  const anchorPath = `${localizedSlug}${anchorId ? `#${anchorId}` : ''}`

  const currentSlug = window?.location?.pathname.slice(1)
  const currentQuery = window?.location?.search
  const currentPath = `${currentSlug}${currentQuery}`

  const isActive =
    localizedSlug === currentPath &&
    anchorGroup?.blades?.find(
      ({ id, anchorTagId }) =>
        anchorTagId === activeAnchor?.id || `section-${id}` === activeAnchor?.id
    )

  return (
    <StyledNavLink
      opacity={isActive ? 1 : 0.7}
      variant={`${theme}.primary`}
      to={anchorPath}
      href={navigationLink?.url}
      display="block"
      tabIndex={tabIndex}
      onKeyDown={handleKeyPress}
    >
      <StyledContainer
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={3}
        borderBottom={!hideActiveBorder && isActive && `1px solid`}
        borderBottomColor={!hideActiveBorder && `${theme}.border.secondary`}
      >
        <StyledText fontFamily="nav" fontSize="15px" lineHeight="20px">
          {displayText}
        </StyledText>
      </StyledContainer>
    </StyledNavLink>
  )
}

SubNavigationItem.propTypes = {
  displayText: PropTypes.string,
  navigationLink: PropTypes.shape({
    url: PropTypes.string,
    slug: PropTypes.string,
  }),
  anchorGroup: PropTypes.shape({
    id: PropTypes.string,
    blades: PropTypes.arrayOf(
      PropTypes.shape({
        anchorTagId: PropTypes.string,
        id: PropTypes.string,
      })
    ),
  }),
  theme: PropTypes.string,
  activeAnchor: PropTypes.object,
}

export default SubNavigationItem
