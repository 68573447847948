import React from 'react'
import { Image } from '@common/components/Image'

const ImageGatsbyCDAContainer = ({ title, scale, source, ...rest }) => {
  const imageSource = source?.localFile?.childImageSharp?.fluid || source?.fluid

  return (
    <Image
      src={imageSource}
      alt={title}
      width={scale ? `${scale}%` : '100%'}
      {...rest}
    />
  )
}

export default ImageGatsbyCDAContainer
